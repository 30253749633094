import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-electrified-fencing',
  templateUrl: './electrified-fencing.component.html',
  styleUrls: ['./electrified-fencing.component.css']
})
export class ElectrifiedFencingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
