import { Component, Input, OnInit } from '@angular/core';
import { BackgroundStyle } from '../../models/background-style.model';
import { FAQ } from '../../models/faq.model';
import { ScreenService } from '../../services/screen.service';

@Component({
  selector: 'app-faq-panel',
  templateUrl: './faq-panel.component.html',
  styleUrls: ['./faq-panel.component.css']
})
export class FAQPanelComponent implements OnInit {


  @Input() alignment: 'start'|'end';
  @Input() faqs: FAQ[];
  @Input() about: string;

  image: string;


  /**
   * Image and overlay alignment
   */
  @Input() get style(){
    return this.style;
  }
  set style(style: BackgroundStyle){
    this.panelStyle = style;
    this.parseStyle(this.panelStyle);
  }
  panelStyle: BackgroundStyle;
  mobileStyle: BackgroundStyle;

  selected = -1;
  constructor(public screenService: ScreenService) { }

  ngOnInit(): void {
  }

  onQuestionClick(index: number){
    this.selected = this.selected === index ? -1 : index;
  }

  /**
   * Set this.image  from the background image property specified in the provided style
   * and create the style for mobile screens
   *
   * @param style the background style
   */
  parseStyle(style: BackgroundStyle){
    const bg = style['background-image'];
    const urlIndex = bg.indexOf('url');
    const imageUrl = bg.substring(urlIndex + 4, bg.indexOf(')', urlIndex));
    this.image = imageUrl;
    this.mobileStyle = this.createMobileStyle();
  }

  /**
   * Creates the background style to be used on mobile screens
   *
   * @returns the mobile background style for this panel
   */
  createMobileStyle(){
    const style: BackgroundStyle = {
      'background-image': 'var(--bg-color)',
      'background-color': 'var(--bg-color)',
      'background-size': 'cover',
     };
    return style;
  }

}
