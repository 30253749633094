<div class="row page-row panel" [ngClass]="alignItems" [class.justify-content-end]="alignment=== 'end'"
  [ngStyle]="screenService.onMobile ? mobileStyle : panelStyle">

  <div [ngClass]="col">
    <div class="d-flex flex-column container-fluid text-light" [ngClass]="{'left-border-panel p-lg-4': !screenService.onMobile}">
      <ng-container [ngTemplateOutlet]="main"></ng-container>
      <img [src]='image' class="img-fluid" *ngIf="screenService.onMobile">
      <ng-container [ngTemplateOutlet]="actionButtons"></ng-container>
    </div>
  </div>
</div>


<ng-template #main>
  <h1 class="text-uppercase text-white" [ngClass]="landing? 'landing-header':'panel-header'">{{titleStart}}
    <ng-container *ngIf="titleBreak"><br></ng-container>
    <span class="{{accent}}">{{titleEnd}}</span>
  </h1>
  <h2 class="text-uppercase subtitle" *ngIf="subtitle">{{subtitle}}</h2>

  <div class="body" *ngIf="!readingMore" [innerHTML]="introText"></div>
  <div class="body" *ngIf="body && (readingMore || !hideDetails)" [innerHTML]='(body|sanitizeHTML)' (click)="onClick($event)"></div>

  <small *ngIf="body && hideDetails">
    <span class="link my-3" (click)="readingMore=!readingMore">{{readingMore? '« Read Less':'Read More »'}}</span>
  </small>

  <div *ngIf="readingMore && checkBoxPanel" class="row my-2" [ngClass]="screenService.onMobile? 'align-items-center': ''" >
    <div class="col-md-2">
      <img src="../../../../assets/residental_services/checked.png" class="checked-icon pb-2">
    </div>
    <div class="col-md-9">
      <div class="font-weight-bold" *ngIf="checkBoxText" [innerHTML]='(checkBoxText|sanitizeHTML)'></div>
    </div>
  </div>
</ng-template>

<ng-template #actionButtons>
  <ng-container *ngIf="showButtons">
    <button role="button" class="button button-primary w-lg-50 text-uppercase my-2" [ngClass]="{'small-text': !landing}" [routerLink]='button.link' >{{button.text}}
    </button>

    <button role="button" *ngIf="showBooking" class="button button-secondary w-lg-50 text-uppercase my-2" [ngClass]="{'small-text': !landing}" routerLink="/forms/technical-booking" >Make A Technical Booking
    </button>

    <button role="button" *ngIf="paymentButton" class="button button-secondary w-lg-50 text-uppercase my-2" [routerLink]='paymentButton.link' >{{paymentButton.text}}
    </button>
  </ng-container>

</ng-template>
