<div class="container-fluid">
  <app-panel [titleStart]='"Policies and procedure"' [titleEnd]="'best practice controls'"
  body="Our unique service offering is led by our comprehensive policies and procedures, matched with our internal controls. These policies and procedures guide our business processes and systems. We ensure that all our staff across all departments are thoroughly trained on these standard operating procedures and that competency is constantly assessed. In doing so, we reduce the probability of errors and inefficient service provision."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fabout%2Fpolicy%20.jpg?alt=media&token=f201e256-78ed-4a01-9736-7f933349d536'|
    panelStyle)"
  >
  </app-panel>

</div>

