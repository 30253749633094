import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { DatabaseService } from 'src/app/shared/services/database.service';

@Component({
  selector: 'app-rate-our-service',
  templateUrl: './rate-our-service.component.html',
  styleUrls: ['./rate-our-service.component.css']
})
export class RateOurServiceComponent implements OnInit {

  form: UntypedFormGroup;
  loading = false;
  compliment = true;
  constructor(public fb: UntypedFormBuilder, public db: DatabaseService,
    public modal: NgbModal, public router: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        name: ['',Validators.required],
        surname: ['', Validators.required],
        address: ['', Validators.required],
        email: new UntypedFormControl('',[Validators.required, Validators.email]),
        contactNumber: new UntypedFormControl('',[Validators.required, Validators.minLength(6)]),
        comments: ['', Validators.required],
      }
    );

  }

  async submit(){
    this.loading = true;
    const formValue = this.form.value;
    formValue.compliment = this.compliment ? 'Compliment' : 'Complaint';

    this.db.saveRatingForm(formValue).then( () => {
      this.showDialog('Submission Complete',
      '<p>Your form has been submitted successfully. </p>\
      <p>Thank you for rating our service.\
      Your constructive feedback is highly appreciated.</p>').result.then(() => this.router.navigate(['home']));
    }
    ).catch((error)=> {
      console.log(error);
      this.showDialog('Submission Error',
      'There was an error submitting your form. Please try again later or email <a class="link accent-text"\
      href="mailto:info@snipersecurity.co.za">info@snipersecurity.co.za</a>')
      .result.then(() => this.router.navigate(['home']));
    }).finally(()=> {this.loading = false; this.form.reset();});

  }

  toggleCompliment(compliment: boolean){
    if(compliment === this.compliment){return;}
    this.compliment = !this.compliment;
  }

  showDialog(title?: string, body?: string){
    this.modal.dismissAll();
    const message = {
      title,
      body,
    };
    const modalRef = this.modal.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.buttons = {ok: 'Continue'};

    modalRef.componentInstance.actionRequired = false;

    return modalRef;
  }

}
