import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Angular Material Modules
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatStepperModule } from "@angular/material/stepper";
import { MatIconModule } from "@angular/material/icon";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { SharedRoutingModule } from "./shared-routing.module";
import { HoverDirective } from "./directives/hover.directive";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { PanelComponent } from "./components/panel/panel.component";
import { BackgroundPipe } from "./pipes/panel-config/panel-config.pipe";
import { SanitizeHTMLPipe } from "./pipes/sanitize-html.pipe";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

import { FAQPanelComponent } from "./components/faq-panel/faq-panel.component";
import { ActionPanelComponent } from "./components/action-panel/action-panel.component";
import { StandardFormComponent } from "../forms/standard-form/standard-form.component";
import { AlertModalComponent } from "./components/alert-modal/alert-modal.component";
import { FormQuestionComponent } from "./components/form-question/form-question.component";
import { MenuPanelComponent } from "./components/menu-panel/menu-panel.component";
import { OfferedServicesPanelComponent } from "./components/offered-services-panel/offered-services-panel.component";
import { HttpClientModule } from "@angular/common/http";
import { BGImagePipe } from "./pipes/bg-image.pipe";
import { FileUploaderComponent } from "./components/file-uploader/file-uploader.component";
import { ReviewBoxComponent } from "./components/review-box/review-box.component";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import { CheckboxSelectorComponent } from "./components/checkbox-selector/checkbox-selector.component";
import { FormAnswerPipe } from "./pipes/form-answer.pipe";
import { ContractTermsComponent } from "../contract-terms/contract-terms.component";
import { MultipleSelectorComponent } from "./components/multiple-selector/multiple-selector.component";
import { PrintQuestionComponent } from "./components/print-question/print-question.component";
import { MatChipsModule } from "@angular/material/chips";
import { NgxMultipleDatesModule } from 'ngx-multiple-dates';
import { DateClassPipe } from './pipes/date-class.pipe';


// eslint-disable-next-line @typescript-eslint/naming-convention
const MaterialModules = [
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatRadioModule,
  MatExpansionModule,
  MatStepperModule,
  MatIconModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatCheckboxModule,
  MatRippleModule,
  MatAutocompleteModule,
  MatRadioModule,
  MatChipsModule,
];

@NgModule({
  declarations: [
    HoverDirective,
    PanelComponent,
    BackgroundPipe,
    SanitizeHTMLPipe,
    BGImagePipe,
    FAQPanelComponent,
    ActionPanelComponent,
    StandardFormComponent,
    AlertModalComponent,
    FormQuestionComponent,
    MenuPanelComponent,
    OfferedServicesPanelComponent,
    FileUploaderComponent,
    ReviewBoxComponent,
    DatePickerComponent,
    CheckboxSelectorComponent,
    FormAnswerPipe,
    ContractTermsComponent,
    MultipleSelectorComponent,
    PrintQuestionComponent,
    DateClassPipe,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModules,
    LayoutModule,
    HttpClientModule,
    NgxMultipleDatesModule

  ],
  exports: [
    CommonModule,
    HoverDirective,
    NgbModule,
    MenuPanelComponent,
    PanelComponent,
    BackgroundPipe,
    BGImagePipe,
    FormAnswerPipe,
    ReactiveFormsModule,
    FAQPanelComponent,
    MaterialModules,
    ActionPanelComponent,
    LayoutModule,
    FormQuestionComponent,
    OfferedServicesPanelComponent,
    HttpClientModule,
    FileUploaderComponent,
    ReviewBoxComponent,
    HttpClientModule,
    FormsModule,
    ContractTermsComponent,
    MultipleSelectorComponent,
    PrintQuestionComponent,
    DateClassPipe

  ],
})
export class SharedModule {}
