<div class="container-fluid py-2">
  <div class="row">
    <div class="col-12 text-center">
      <h1 class="text-white text-uppercase my-2 display-5">{{serviceType}} security <span class="accent-text">services include</span></h1>
    </div>
    <div class="col-12 parent">
        <div class="child" *ngFor="let service of services">
          <div class="card app-bg-lighter shadow text-center service-card zoom">
            <img [src]="service.image" class="card-img-top card-image" alt="..." >
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">{{service.title}}</h5>
              <p class="card-text">{{service.message}}</p>
              <a [routerLink]="service.link" class="button button-outline danger text-capitalize mt-auto" role="button">Learn more</a>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>

