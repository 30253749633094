<div class="container-fluid">
  <!-- 0 -->
  <app-panel
  titleStart="Electrified"
  titleEnd="Fencing."
  subtitle="Your first line of defence."
  body="Sniper is known for its high-quality workmanship. Whether it is an emergency, repair or a new installation, our team of trained professionals are always available to provide you with sound advice. With a large array of services, we are proficient in commercial electric fence repairs, installations and routine maintenance."
  [landing]='true'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FelectricFence.jpg?alt=media&token=7c4d9b54-f6bc-4b29-9a21-a9b12f85fcd8'|panelStyle:'start': {'background-position':'bottom 50px', 'background-size': 'cover'})">
  </app-panel></div>


