/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tactical-response',
  templateUrl: './tactical-response.component.html',
  styleUrls: ['./tactical-response.component.css']
})
export class TacticalResponseComponent implements OnInit {

  slides1 = [
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2FDSC01275_web.jpg?alt=media&token=68de1d6f-73f6-473b-b2d2-a763cc4367c2',
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2Fcommitment_web.jpg?alt=media&token=5b9266fb-4e5b-4b65-be1d-1013a5e4c25b',
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2Fsniper_golf_web.jpg?alt=media&token=bad76211-143e-4373-a4bc-031ea9dd3237'
  ];


  constructor() { }



  ngOnInit(): void {
  }

}
