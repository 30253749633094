<div class="container-fluid">
  <app-panel
  [titleStart]="'Areas we'"
  [titleEnd]="'Cover'"
  [titleBreak]='false'
  [accent]="'primary-text'"
  body="<p>Below is a list of some of the areas that we offer our professional support services.<br>
    We cover the residential and business sector. Please call us on 021 697 0573 we would love to hear from you.</p>
  <ul style='list-style-type:none;'></ul>
  <li>Alphen Cape Town</li>

  <li>Atlantic Seaboard Cape Town</li>

  <li>Bakoven Cape Town</li>

  <li>Bantry Bay Cape Town</li>

  <li>Bel Ombre Cape Town</li>

  <li>Belvedere Cape Town</li>

  <li>Bergvliet Cape Town</li>

  <li>Bishops Court Cape Town</li>

  <li>Camps Bay Cape Town</li>

  <li>City Bowl Cape Town</li>

  <li>Claremont Cape Town</li>

  <li>Constantia</li>

  <li>Crawford</li>

  <li>Clifton Cape Town</li>

  <li>Devils Peak Estate Cape Town</li>

  <li>De Waterkant Cape Town</li>

  <li>Firgrove Cape Town</li>

  <li>Fresnaye Cape Town</li>

  <li>Gardens Cape Town</li>

  <li>Green Point Cape Town</li>

  <li>Houtbay</li>
  <li>Kirstenbosch</li>

  <li>Kenwyn</li>

  <li>Lansdowne</li>

  <li>Llandudno Cape Town</li>

  <li>Meadow Ridge Cape Town</li>

  <li>Muizenberg</li>

  <li>Newlands</li>

  <li>Oranjezicht Cape Town</li>

  <li>Ottery</li>

  <li>Penlyn Estate</li>

  <li>Rondebosch Cape Town</li>

  <li>Rondebosch East</li>

  <li>Schotsche Kloof Cape Town</li>

  <li>Sea Point Cape Town</li>

  <li>Silverhurst Cape Town</li>
  <li>Steenburg Estate Cape Town</li>

  <li>Stonehurst Cape Town</li>

  <li>Sybrand Park</li>

  <li>Tamboerskloof Cape Town</li>

  <li>Three Anchor Bay Cape Town</li>

  <li>University Estate</li>

  <li>Vredehoek Cape Town</li>

  <li>Walmer Estate</li>

  <li>Wynberg</li>

  "
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2FAreas%20We%20Cover.jpg?alt=media&token=b7d577c3-02de-4d1e-8e3a-f1cb8884a6c5'|panelStyle: 'start')"
  >

  </app-panel>
</div>

