import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BackgroundStyle } from "../../models/background-style.model";

import { ScreenService } from "../../services/screen.service";

@Component({
  selector: "app-panel",
  templateUrl: "./panel.component.html",
  styleUrls: ["./panel.component.css"],
})
export class PanelComponent implements OnInit, OnDestroy {
  @Input() background: {
    img: string;
    linearGradient?: string;
    position?: string;
    size?: string;
  };

  @Input() col = "col-lg-6";
  @Input() titleStart: string;
  @Input() carousel: string[];
  @Input() titleEnd: string;
  @Input() titleBreak = true;
  @Input() subtitle: string;
  @Input() landing: boolean;
  @Input() body: string;
  @Input() accent = "accent-text";
  @Input() checkBoxPanel: boolean;
  @Input() checkBoxText: string;
  @Input() button: { text: string; link: string } = {
    text: "Request a quote",
    link: "/forms/quote",
  };
  @Input() paymentButton: { text: string; link: string };
  @Input() showButtons = true;
  @Input() hideDetails = true;
  @Input() alignItems = "align-items-center";
  @Input() introText: string = "";
  @Input() showBooking = true;

  /**
   * background image for mobile screens
   */
  @Input() mobileImage: string;

  /**
   * Image and overlay alignment
   */
  @Input() alignment: "start" | "end" = "start";
  carouselTimeOut: any;

  @Input() get style() {
    return this.style;
  }
  set style(style: BackgroundStyle) {
    this.panelStyle = style;
    this.parseStyle(this.panelStyle);
  }
  panelStyle: BackgroundStyle;
  mobileStyle: BackgroundStyle;
  readingMore = false;
  image: string;

  constructor(private router: Router, public screenService: ScreenService) {}

  ngOnInit(): void {
    // this.readingMore = this.screenService.onMobile;

    if (
      this.titleEnd &&
      this.titleEnd.charAt(this.titleEnd.length - 1) === "."
    ) {
      this.titleEnd = this.titleEnd.substring(0, this.titleEnd.length - 1);
    }

    if (this.carousel) {
      this.setCarousel();
    }
  }

  ngOnDestroy() {
    clearTimeout(this.carouselTimeOut);
  }

  /**
   * Navigates to the path in the link specified in the anchor tag <a>
   *
   * @param event click event
   */
  onClick(event) {
    // If we don't have an anchor tag, we don't need to do anything.
    if (event.target instanceof HTMLAnchorElement === false) {
      return;
    }
    // Prevent page from reloading
    event.preventDefault();
    const target = event.target as HTMLAnchorElement;

    /**
     * For links that aren't on this site.
     * Make sure the anchor tags have the target='_blank' attribute for this to work.
     *
     */
    //TODO look into more clear way to make the distinction
    if (target?.target.length > 0) {
      window.open(target.href, "_blank");
      return;
    }
    // Navigate to the path in the link
    this.router.navigate([target.pathname]);
  }

  /**
   *  Set this.image  from the background image property specified in the provided style
   * and create the style for mobile screens
   *
   * @param style the background style
   */
  parseStyle(style: BackgroundStyle) {
    const bg = style["background-image"];
    const urlIndex = bg.indexOf("url");
    const imageUrl = bg.substring(urlIndex + 4, bg.indexOf(")", urlIndex));
    this.image = imageUrl;
    this.mobileStyle = this.createMobileStyle();
  }

  /**
   * Creates the background style to be used on mobile screens
   *
   * @returns the mobile background style for this panel
   */
  createMobileStyle() {
    if (!this.mobileImage) {
      this.mobileImage = this.image;
    }
    const style: BackgroundStyle = {
      "background-image": ``, // url(${this.mobileImage}),
      "background-size": "cover",
      "background-position": "center",
      "background-color": " var(--bg-color)",
    };
    return style;
  }

  /**
   *  Default styling for this panel
   *
   * @param image the image to be used
   * @param alignment start or end
   */
  defaultStyle(image: string, alignment: "start" | "end") {
    const style = {
      "background-image":
        (alignment === "start"
          ? `linear-gradient(90deg,  var(--bg-color), transparent),`
          : `linear-gradient(90deg, transparent,  var(--bg-color))`) +
        `, url(${image})`,
      "background-size": "cover",
      "background-position": "center",
      "background-color": " var(--bg-color)",
    };
    return style;
  }

  setCarousel(index: number = 0) {
    this.carouselTimeOut = setTimeout(() => {
      const bg = this.panelStyle["background-image"];
      const urlIndex = bg.indexOf("url");
      const newBgImage = bg.replace(
        bg.substring(urlIndex + 4, bg.indexOf(")", urlIndex)),
        this.carousel[index]
      );
      this.panelStyle["background-image"] = newBgImage;
      this.image = this.carousel[index++];
      if (index >= this.carousel.length) {
        index = 0;
      }
      this.setCarousel(index);
    }, 3000);
  }
}
