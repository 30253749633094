<div class="container-fluid">

  <!-- 1 -->
  <app-panel
    [titleStart]="'cyber guard'"
    [titleEnd]="'Virtual guarding solution'"
    [landing]="true"
    body="<p>We know that a physical guarding solution is a costly monthly expense. Our advanced analytical surveillance technology plays an important role in your safety, as it enables our control room to engage audibly with suspects on your premises and this drastically reduces your guarding costs.</p>
    <p>Virtual guarding is an innovative and proactive solution to manage your business security. This is achieved by deploying <b>Deep Learning Surveillance Cameras</b> on your business premises. In the event of a security breach or intrusion, the relevant tactical response back up is dispatched to your premises to investigate.
     <br>Our highly trained personnel are ready act 24/7.</p>"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcyberguard_man.png?alt=media&token=c35c26b3-c0d3-4d80-8245-8801e17dbe60'|
    panelStyle:'start': {'background-position': 'top right 200px', 'background-size': 'contain'})"
    >
  </app-panel>

  <!-- 2 -->
  <app-panel
    [titleStart]="'LIVE'"
    [titleEnd]="'Event Based Cyber Guard Camera Surveillance'"
    [titleBreak]='false'
    body="We provide our clients with <b>LIVE, real-time, event-based Cyber Guard CCTV surveillance
    monitoring.</b> Our surveillance solution is based on advanced video analytics software and sensor-equipped cameras that trigger intrusion and human detection. Our solution can be customised to meet your business needs."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FHikvision%2010.jpg?alt=media&token=f6942180-3323-4b05-b56c-d2ecf53de9d7'|panelStyle)"
    >
  </app-panel>

  <app-panel
  [titleStart]="'Real Time'"
  [titleEnd]="'Intrusion Detection'"
  body="The Cyber Guard solution will detect human motion on your premises and is able to identify and illuminate animal, vegetation, moving trees or other interferences."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FIntrusion%20111.jpg?alt=media&token=556fdad1-ae17-49d0-ab1c-b09f9aa8305f'|panelStyle:'end')"
  [alignment]="'end'"
  >
  </app-panel>

  <app-panel
  [titleStart]="'Real Time'"
  [titleEnd]="'Video Notification'"
  body="Our software, is able to analyse your site behaviour and notify you and our control room of all kinds of behaviour such as loitering,
   fence breaching, intrusion and line crossing detection."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FCyber%20Guard%20Line%20Crossing%202.jpg?alt=media&token=2a640f22-08c7-4a7d-aa56-2aff5a3a5c38'|panelStyle)"
  >
  </app-panel>

  <app-panel
  [titleStart]="'Real Time'"
  [titleEnd]="'Audio Enagement'"
  [alignment]="'end'"
  body="The Cyber Guard system enables our control room to engage audibly with intruders or trespassers."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FHikvision%20CCTV%20Surveillance%201.jpg?alt=media&token=259f5b55-502c-40c2-af36-803946ba5d3d'|panelStyle:'end')"
  >
  </app-panel>

  <div class="row page-row justify-content-center video-row app-bg">
    <div class="col-md-10 text-center mt-5">
      <h1 class="text-center text-uppercase text-white">CHECK OUT HOW
        <span class="accent-text">CYBER GUARD</span> CAN PROTECT Your business</h1>
    </div>
    <div class="w-100"></div>
    <div class="col-md-7">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FbusinessCCTV2.jpg?alt=media&token=9650f401-35ca-4721-b2d7-134ca536b218">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FAcuSense%20products%20for%20Warehouses.mp4?alt=media&token=2296bb31-6f0f-4a9a-9d1e-e86aaf83b504" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>

  </div>

  <!-- 3 -->
  <div class="row page-row justify-content-center app-bg">
    <div class="col-10 text-center mt-5">
      <h1 class="text-center text-uppercase text-white mb-4">Cyber Guard<br><span class="accent-text">in Action</span></h1>
    </div>
    <div class="w-100"></div>
    <div class="col-md-8 mb-2" *ngFor="let video of videos">
      <h3 class="text-center text-uppercase text-white">{{video.title}}</h3>
      <video class="page-video mb-4 p-2" controls type="video/mp4" [src]="video.url" [poster]='video.poster'>
        Your browser does not support HTML5 video.
      </video>
    </div>

  </div>

   <!-- 6 -->
   <app-action-panel
   titleStart="Contact"
   titleEnd="Us"
   [titleBreak]="false"
   body="We are committed to offering a professional, responsive, value-added video surveillance service with a Sniper touch. We are locally owned and based in the Southern Suburbs. We work with clients, owners, managers and chief security officers to develop proactive and robust video security solutions."
  [btnLink]="'/forms/quote'"
  >
 </app-action-panel>





</div>
