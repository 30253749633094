<div class="container-fluid">
  <!-- 1 -->
  <app-panel
    [titleStart]="'Emergency'"
    [titleEnd]="'Guarding'"
    [landing]="true"
    body="Sniper offers emergency and temporary guarding solutions at short notice if there is an unexpected situation or a planned event that requires additional protection.<br><br>
    Our guarding solution periods range from 1 to 30 days only and is risk dependent. Please complete the guarding specifications form and a consultant will contact you to discuss the matter.
    "
    [button]='{text: "Request a Quote",link: "/forms/emergency"}'
    [paymentButton]='{text: "Make Payment",link: "/forms/emergency/pay"}'
    [showBooking]="false"

    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2Femergency-guarding.jpg?alt=media&token=10fbc23c-9089-4f53-9020-f6f02b780062'|panelStyle:'start' : {'background-position': 'bottom'})"
    >
  </app-panel>
</div>
