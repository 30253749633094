<div class="container-fluid">
  <!-- 0 -->
  <app-panel
  titleStart="Access"
  titleEnd="Control."
  [titleBreak]='false'
  subtitle="We install specialist access control systems tailor made to your security needs."
  [landing]='true'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FAccess%20control%20787878.jpg?alt=media&token=82a9fa03-91ba-4de4-8272-ba17082e011d'
  |panelStyle: 'start': { 'background-color': 'white', 'background-position': 'right top 100px', 'background-size':'contain'})">
  </app-panel>

   <!-- 1 -->
   <div class="row align-items-center justify-content-center">
    <div class="col-md-10">
      <video class="w-100 p-2" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fimage011.jpg?alt=media&token=7cc6413d-089f-446c-8f86-4c263950dbaa">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fhard-wired.mp4?alt=media&token=726c79b9-7577-453c-8360-b6c4c1f625aa" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>


  <!-- 4 -->

  <app-action-panel
  titleStart="Contact"
  titleEnd="Us"
  [titleBreak]="false"
  body=" Our security products are built with you-and your family-in mind. Sniper's expert security technicians will consult with you and then design and install a system that allows you to realize the benefits of our access control services."
  [btnLink]="'/forms/quote'"
 >

</app-action-panel>



</div>
