import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  navigation: Observable<NavigationStart>;
  onMobile: boolean;
  size$: Observable<'s' | 'm' | 'l'>;
  fullScreeen$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router) {
    this.navigation = this.navChange();
    this.setMobileObserver();
  }

  private setMobileObserver() {
    this.breakpointObserver
      .observe(['(max-width: 768px)']).pipe(map((state: BreakpointState) => {
        this.onMobile = state.matches;
        return state.matches;
      })).subscribe(val => { this.onMobile = val; });

    this.size$ = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(map(result => {
        if (result.breakpoints[Breakpoints.Medium]) { return 'l'; }
        if (result.breakpoints[Breakpoints.Small]) { return 'm'; }
        if (result.breakpoints[Breakpoints.XSmall]) { return 's'; }
        return 's';
      }));

    this.fullScreeen$ = this.breakpointObserver
      .observe(['(min-width: 1350px)']).pipe(map((state: BreakpointState) => {
        this.onMobile = state.matches;
        return state.matches;
      }));

  }

  private navChange() {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map(event => (event as NavigationStart)));

  }


}
