<div class="d-flex flex-column align-items-center justify-content-center"  *ngIf="!loading; else spinner">
  <form [formGroup]='enquiryForm' *ngIf="enquiryForm" class="my-2 d-flex flex-column w-75 align-items-center">
  <h1 class="m-1">General Enquiries</h1>
  <p>
    This form is for general enquiries only. Should you require specific support services, kindly check out the <a class="link underline" (click)="assist()">“How Can We Assist You”</a> tab.
</p>
  <p><small>Fields marked with  '*' are required</small></p>

    <mat-form-field [appearance]="'outline'" class="w-100">
      <mat-label>Name</mat-label>
      <input matInput [formControlName]="'name'" type="text" [required]='true'>
      <mat-error *ngIf="enquiryForm.controls.name.errors">Name required</mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="'outline'" class="w-100">
      <mat-label>Surname</mat-label>
      <input matInput [formControlName]="'surname'" type="text"  [required]='true'>
      <mat-error *ngIf="enquiryForm.controls.surname.errors">Surname required</mat-error>

    </mat-form-field>

    <mat-form-field [appearance]="'outline'" class="w-100">
      <mat-label>Business Name</mat-label>
      <input matInput [formControlName]="'businessName'" type="text"  >
      <mat-error *ngIf="enquiryForm.controls.businessName.errors">Business Name required</mat-error>

    </mat-form-field>
    <mat-form-field [appearance]="'outline'" class="w-100">
      <mat-label>Email Address</mat-label>
      <input matInput [formControlName]="'email'" type="text" [required]='true'>
      <mat-error *ngIf="enquiryForm.controls.email.errors">Valid email required</mat-error>
    </mat-form-field>
    <mat-form-field [appearance]="'outline'" class="w-100">
      <mat-label>Contact Number</mat-label>
      <input matInput [formControlName]="'contactNumber'" type="tel" [required]='true'>
      <mat-error *ngIf="enquiryForm.controls.contactNumber.errors">Valid contact number required required</mat-error>
    </mat-form-field>

    <mat-form-field [appearance]="'outline'" class="w-100">
      <mat-label>Message Subject</mat-label>
      <input matInput [formControlName]="'subject'" type="text" [required]='true'>
      <mat-error *ngIf="enquiryForm.controls.subject.errors">A subject is required</mat-error>
    </mat-form-field>

    <mat-form-field [appearance]="'outline'" class="w-100">
      <mat-label>Enquiry</mat-label>
      <textarea matInput [formControlName]="'message'" type="text" [required]='true'></textarea>
      <mat-error *ngIf="enquiryForm.controls.message.errors">A subject is required</mat-error>
    </mat-form-field>

    <mat-form-field [appearance]="'outline'" class="w-100">
      <mat-label>Department</mat-label>
      <mat-select  formControlName="type" panelClass="app-bg-darker" [required]="true">
        <mat-option *ngFor="let type of enquiryTypes" [value]="type">{{type}}</mat-option>
      </mat-select>
    </mat-form-field>


    <button class="btn app-bg-danger w-50 m-2"(click)="submit()" [disabled]="!enquiryForm.valid">Submit</button>

  </form>
</div>



<ng-template #spinner>
  <div class="d-flex flex-column align-items-center justify-content-center full-height">
    <mat-spinner></mat-spinner>
    <h1 class="accent-text">Processing...</h1>
  </div>
</ng-template>
