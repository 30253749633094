import { Component, OnInit, ViewChild } from '@angular/core';
import { OffersService } from '../shared/services/offered-services.service';
import { OfferedService } from '../shared/models/offered-service.model';
import { ScreenService } from '../shared/services/screen.service';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @ViewChild('acc') accordion: MatAccordion;
  residential: OfferedService[] = [];
  commercial: OfferedService[] = [];
  assist: OfferedService[] = [];
  about: OfferedService[] = [];

  date = new Date().getFullYear();


  constructor(private offersService: OffersService, public screenService: ScreenService) { }

  ngOnInit(): void {
    this.residential = this.offersService.residential;
    this.commercial = this.offersService.commercial;
    this.assist = this.offersService.assist;
    this.about = this.offersService.about;
  }


  toggleAccordion(open: boolean){
    if (!this.screenService.onMobile){
      if (open) { this.accordion.openAll(); }
      else { this.accordion.closeAll(); }
    }
  }







}
