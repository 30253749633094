import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cctv',
  templateUrl: './security-cameras.component.html',
  styleUrls: ['./security-cameras.component.css']
})
export class SecurityCamerasComponent implements OnInit {

  faq: {answer: string; question: string}[] = [];
  constructor() { }

  ngOnInit(): void {
    this.faq = this.getFAQ();
  }

  getFAQ() {
    return [
      {
        question: 'Can I get your service with my existing camera system?',
        answer: `It depends on the CCTV brand you have installed.
        Simply call our office with the information and a consultant will assist you.`
      },
      {
        question: 'Can I view live video footage from my phone?',
        answer: `Yes. We will install the Sniper CCTV mobile application on your phone which will enable you to view,
         manage, and save video camera footage from your smartphone.`
      },
      {
        question: 'What resolution do Sniper\'s cameras record?',
        answer: 'Our home security cameras record at 1080p resolution'
      },
      {
        question: 'Can I add multiple users to a single account?',
        answer: `It is possible to delegate security camera access to additional users. \
        Any person with your login credentials can access your camera video feed on the Sniper camera app.`
      },
      {
        question: 'Can I record video footage on my phone?',
        answer: `Yes. Our technicians will show you how. It's very easy and extremely user friendly.`
      },
      {
        question: 'Does this system require an internet connection for viewing footage offsite?',
        answer: `Yes. The system requires an internet connection.`
      },
      {
        question: 'Do the cameras work in the event of an internet outage?',
        answer: `The cameras will continue to record as per normal, but you will not have access to the camera system remotely via the app`
      },
      {
        question: 'Will you provide training on how to use the system?',
        answer: `Once we complete the installation process, our technicians will get you up and running\
         with the Sniper CCTV app and show you how to use it so that you can take advantage \
         of every benefit of your complete home security camera system. \
         Our consultants are always ready to answer any questions you have about the system.`
      },


    ];
  }
}
