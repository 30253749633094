import { Pipe, PipeTransform } from '@angular/core';
import { SecurityPackage } from 'src/app/models/security-packages.model';

@Pipe({
  name: 'bgImage'
})
export class BGImagePipe implements PipeTransform {

  transform(pkg: SecurityPackage, font?: string): {[key: string]: string} {
    return {
      'background-image': `url(${pkg.background})`,
      color: pkg.title === 'Black' || pkg.title === 'Pro Black' ? 'white' : 'black',
      'background-color': pkg.color
    };
  }

}

