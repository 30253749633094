<div class="container-fluid">
  <div class="row page-row contact justify-content-center align-items-center">
    <div class="col-md-12 justify-content-center align-items-center d-flex flex-column min-vh-50 ">
      <h1 class="accent-text text-uppercase display-3 pt-lg-4 pt-2">Contact <span class="text-white">Us</span></h1>
      <div class="text-white text-center">
        <p class="display-7 header-font">
          <a class="link" href="tel:+27216970573" style="font-size: inherit;">
            <span class="bi bi-telephone-fill p-2"></span>
            021 697 0573
          </a>
        </p>
        <p class="display-7 header-font">
          <a class="link" href="mailto:info@snipersecurity.co.za" style="font-size: inherit;">
            <i class="bi bi-envelope-fill p-2"></i>
            info@snipersecurity.co.za
          </a>
        </p>
        <p class="display-7 header-font primary-text">
          <a class="link" href="http://maps.google.com/?q=72 Kromboom Rd, Rondebosch East, Cape Town, 7780" target="_blank" rel="noopener" style="font-size: inherit;">
            <i class="bi bi-geo-alt-fill p-2"></i>
            72 Kroomboom Road, Rondebosch East, Cape Town.
          </a>
        </p>



      </div>

    </div>

    <div class="col-md-6">
      <button class="btn app-bg-danger m-2 w-100" routerLink="/forms/quote">I Need A Quote</button>
    </div>
    <div class="w-100"></div>
    <div class="col-md-6">
      <button class="button button-secondary m-2 w-100" routerLink="/forms/technical-booking" >
        Technical Booking
      </button>
    </div>
    <div class="col-md-12 enquiry-form py-3">
      <app-general-enquiry></app-general-enquiry>
    </div>
    <div class="col-12">
      <img src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2Fcontact-us.jpg?alt=media&token=5ebe0686-2029-49ce-abfd-823b12cca0b6" class="image">
    </div>
  </div>
</div>
