<div class="container-fluid">
  <!-- 0 -->
  <app-panel
  titleStart="Electric"
  titleEnd="Fencing."
  subtitle="YOUR FIRST LINE OF DEFENCE TO KEEP INTRUDERS OUT AND YOUR FAMILY SAFE."
  body="Installation, repairs and general maintenance."
  [landing]='true'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Felectric-fence.jpg?alt=media&token=035cad1f-a2dc-4ec5-8ef9-32b839a249a4'|panelStyle)">
  </app-panel>

  <div class="row align-items-center justify-content-center">
    <div class="col-12 p-lg-2">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/videos%2Fefencing_thumb.png?alt=media&token=e34f792d-cece-4e79-a015-e2f94b1434cb">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/videos%2Felectric_fencing.mp4?alt=media&token=391c7974-f88e-4110-98ef-590948c8efaf" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>

  <!-- 1 -->
  <div class="row page-row align-items-center first">

  </div>



</div>

