<div class="modal-header" *ngIf="message.header">
  <h4
    class="modal-title accent-text"
    id="modal-basic-title"
    style="text-transform: capitalize"
  >
    {{ message.header }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="close(false)"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <h1 class="accent-text">{{ message.title }}</h1>
    <div class="text-dark" [innerHTML]="message.body"></div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    *ngIf="actionRequired && buttons.cancel"
    (click)="close(false)"
  >
    {{ buttons.cancel }}
  </button>
  <button
    type="button"
    class="btn app-bg-danger"
    ngbAutofocus
    (click)="close(true)"
  >
    {{ buttons.ok }}
  </button>
</div>
