<div class="w-100 app-bg">
  <div class="d-flex flex-column align-items-center justify-content-center"  *ngIf="!loading; else spinner">
    <form [formGroup]='form' *ngIf="form" class="my-2 d-flex flex-column w-75 align-items-center">
    <h1 class="m-1">Compliment or Complaint</h1>
    <p>We at Sniper are constantly striving to improve our services. Kindly provide us with feedback below that best describes your experience. Your constructive feedback is highly appreciated.  </p>

    <div class="flex-parent flex-row">
      <div class="rating-items cursor-pointer" (click)="toggleCompliment(true)">
        <div class="flex-parent flex-column">
          <img src='https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2Fcompliment.png?alt=media&token=20dd028f-9e11-4378-bc6d-4361f3c1a2ee'class="zoom">
          <p class="header-font large-text">
            <mat-checkbox [color]='"primary"' [checked]="compliment" [class.mat-checkbox-disabled]="false"
            disabled>Compliment</mat-checkbox>
          </p>
        </div>
      </div>
      <div class="rating-items cursor-pointer" (click)="toggleCompliment(false)">
        <div class="flex-parent flex-column">
          <img src='https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2Fcomplaint.png?alt=media&token=5a9023ef-cb4d-4ff2-9edf-302d7ac7eeb1'class="zoom">
          <p class="header-font large-text">
            <mat-checkbox [color]='"primary"' [checked]="!compliment" [class.mat-checkbox-disabled]="false"
            disabled>Complaint</mat-checkbox>
          </p>
        </div>
      </div>
    </div>

      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Name</mat-label>
        <input matInput [formControlName]="'name'" type="text" [required]='true'>
        <mat-error *ngIf="form.controls.name.errors">Name required</mat-error>
      </mat-form-field>
      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Surname</mat-label>
        <input matInput [formControlName]="'surname'" type="text"  [required]='true'>
        <mat-error *ngIf="form.controls.surname.errors">Surname required</mat-error>
      </mat-form-field>
      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Address</mat-label>
        <textarea matInput [formControlName]="'address'" type="address"  [required]='true'></textarea>
        <mat-error *ngIf="form.controls.address.errors">Address required</mat-error>
      </mat-form-field>
      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Email Address</mat-label>
        <input matInput [formControlName]="'email'" type="text" [required]='true'>
        <mat-error *ngIf="form.controls.email.errors">Valid email required</mat-error>
      </mat-form-field>
      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Contact Number</mat-label>
        <input matInput [formControlName]="'contactNumber'" type="tel" [required]='true'>
        <mat-error *ngIf="form.controls.contactNumber.errors">Valid contact number required required</mat-error>
      </mat-form-field>
      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Comments/Feedback</mat-label>
        <textarea matInput [formControlName]="'comments'" type="text"  [required]='true'></textarea>
        <mat-error *ngIf="form.controls.address.errors">Comment required</mat-error>
      </mat-form-field>


      <button class="button button-primary w-lg-50 m-2"(click)="submit()" [disabled]="!form.valid">Submit</button>

    </form>
  </div>

</div>

<ng-template #spinner>
  <div class="d-flex flex-column align-items-center justify-content-center full-height">
    <mat-spinner></mat-spinner>
    <h1 class="accent-text">Processing...</h1>
  </div>
</ng-template>
