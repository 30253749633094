import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CommercialServicesRoutingModule } from "./commercial-services-routing.module";
import { CommercialServicesComponent } from "./commercial-services.component";
import { AllCommercialServicesComponent } from "./all-commercial-services/all-commercial-services.component";
import { BusinessAlarmSystemsComponent } from "./burglar-alarm-systems/business-alarm-systems.component";
import { BusinessAccessControlComponent } from "./business-access-control/business-access-control.component";
import { BusinessAlarmCctvIntegrationComponent } from "./business-alarm-cctv-integration/business-alarm-cctv-integration.component";
import { BusinessAlarmMonitoringComponent } from "./business-alarm-monitoring/business-alarm-monitoring.component";
import { BusinessTacticalResponseComponent } from "./business-tactical-response/business-tactical-response.component";
import { CctvSystemsComponent } from "./cctv-systems/cctv-systems.component";
import { ElectrifiedFencingComponent } from "./electrified-fencing/electrified-fencing.component";
import { LiveCctvComponent } from "./live-cctv/live-cctv.component";
import { LprComponent } from "./lpr/lpr.component";
import { VideoIntercomComponent } from "./video-intercom/video-intercom.component";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  declarations: [
    CommercialServicesComponent,
    BusinessAlarmSystemsComponent,
    BusinessTacticalResponseComponent,
    BusinessAlarmMonitoringComponent,
    BusinessAlarmCctvIntegrationComponent,
    CctvSystemsComponent,
    LiveCctvComponent,
    BusinessAccessControlComponent,
    VideoIntercomComponent,
    LprComponent,
    ElectrifiedFencingComponent,
    AllCommercialServicesComponent,
  ],
  imports: [CommonModule, CommercialServicesRoutingModule, SharedModule],
})
export class CommercialServicesModule {}
