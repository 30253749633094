import { Component, OnInit } from '@angular/core';
import { OffersService } from 'src/app/shared/services/offered-services.service';
import { OfferedService } from 'src/app/shared/models/offered-service.model';

@Component({
  selector: 'app-all-commercial-services',
  templateUrl: './all-commercial-services.component.html',
  styleUrls: ['./all-commercial-services.component.css']
})
export class AllCommercialServicesComponent implements OnInit {

  customerReviews: {message: string; reviewer: string}[] = [];
  reviewIndex = 0;

  services: OfferedService[] = [];


  constructor(private offersService: OffersService) { }

  ngOnInit(): void {
    this.getCustomerReviews();
    this.setUpCards();
  }

  //#region  Reviews
  getCustomerReviews(){
    this.customerReviews.push(
      {
        message: 'I was extremely pleased by the level of professionalism \
        from Sniper from day one to the completion of my access control system',
        reviewer: 'Derreck, Building Manager - Montague Gardens'
      },
      {
        message: 'I was very impressed with the technical department as they kept me updated \
        with my installation progress every step of the way',
        reviewer: 'Cathleen, Medical Operations Manager - Northern Suburbs'
      },

      {
        message: 'It is nice to work with a company that returns your calls.',
        reviewer: 'Rashied, Forecourt Manager – Kenilworth',
      },
      {
        message: 'I am very service orientated and not easily pleased. Sniper really impressed me and exceeded all my expectations.',
        reviewer: 'Bobby, Owner Managed Business – Mowbray'
      },

      {
        reviewer: 'Angie, Body Corporate Trustee – Rosmead',
        message: 'We had several intrusions and trespassers at our complex before Sniper \
        installed a surveillance camera solution. Nowadays I sleep in peace.',
      },

    );
  }

  /**
   * changes the displayed review
   *
   * @param forward true if the 'forward' direction was selected
   *  false if the 'backward' direction selected
   */
  nextReview(forward: boolean){
    if (forward){
      this.reviewIndex = (this.reviewIndex + 1) >= this.customerReviews.length ? 0 : (this.reviewIndex + 1);
    }
    else{
      this.reviewIndex = (this.reviewIndex - 1) < 0 ? (this.customerReviews.length - 1) : (this.reviewIndex - 1);
    }


  }
  //#endregion


  //#region Service Cards
  private setUpCards(){

    this.services = this.offersService.commercial;
    this.services = this.services.filter(service => service.link !== '/commercial');
  }

  //#endregion
}
