import { Component, Input, OnInit } from '@angular/core';
import { OfferedService } from '../../models/offered-service.model';

@Component({
  selector: 'app-offered-services',
  templateUrl: './offered-services-panel.component.html',
  styleUrls: ['./offered-services-panel.component.css']
})
export class OfferedServicesPanelComponent implements OnInit {

  @Input() serviceType: string;
  @Input() get offered(){
    return this.services;
  }
  set offered(services: OfferedService[]){
    this.services = services.filter(service => service.image ? true : false);
  }
  services: OfferedService[];
  constructor() { }

  ngOnInit(): void {
  }

}
