import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-police-partnership',
  templateUrl: './police-partnership.component.html',
  styleUrls: ['./police-partnership.component.css']
})
export class PolicePartnershipComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
