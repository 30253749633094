<div class="container-fluid">
  <!-- 1 -->
  <app-panel
    [titleStart]="'home burglar'"
    [titleEnd]="'alarm systems'"
    [landing]="true"
    body="When it comes to the safety of your family, compromising on equipment is not an option."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Ffamily.jpg?alt=media&token=3d7f3802-3af9-4665-84ed-a57b4772f563'|panelStyle)"
    >
  </app-panel>


  <app-panel
    [titleStart]="'custom security solutions'"
    [titleEnd]="'designed with your home in mind'"
    [alignment]="'end'"
    body="We know that no two homes are the same, some require unique security solutions. Our expert security consultants will review the layout of your home to determine its specific security demands, prior to installing your alarm system."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Farchitect_5.jpg?alt=media&token=083f2dda-da9b-4ff7-8454-2ad1733108a5'|panelStyle:'end')"
    >
  </app-panel>

  <app-panel
  [titleStart]="'WE ARE KNOWN FOR OUR AMAZING CUSTOMER AND TECHNICAL SUPPORT SERVICE'"
  [titleEnd]="'BACKED BY AN INTERACTIVE MANAGEMENT STYLE'"
  body="It does not matter how great your product is, if your customer service is poor. We pride ourselves on amazing customer support services."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcustomer-service-cloud.jpg?alt=media&token=da989e1f-b230-4b96-92fe-7c0afde14296'|panelStyle)"
  >

</app-panel>
</div>


