import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TechnicalSupportComponent } from "../residential-services/technical-support/technical-support.component";
import { AllCommercialServicesComponent } from "./all-commercial-services/all-commercial-services.component";
import { BusinessAlarmSystemsComponent } from "./burglar-alarm-systems/business-alarm-systems.component";
import { BusinessAccessControlComponent } from "./business-access-control/business-access-control.component";
import { BusinessAlarmCctvIntegrationComponent } from "./business-alarm-cctv-integration/business-alarm-cctv-integration.component";
import { BusinessAlarmMonitoringComponent } from "./business-alarm-monitoring/business-alarm-monitoring.component";
import { BusinessTacticalResponseComponent } from "./business-tactical-response/business-tactical-response.component";
import { CctvSystemsComponent } from "./cctv-systems/cctv-systems.component";
import { ElectrifiedFencingComponent } from "./electrified-fencing/electrified-fencing.component";
import { LiveCctvComponent } from "./live-cctv/live-cctv.component";
import { LprComponent } from "./lpr/lpr.component";
import { VideoIntercomComponent } from "./video-intercom/video-intercom.component";

const routes: Routes = [
  {
    path: "commercial",
    children: [
      {
        path: "burglar-alarm-systems",
        component: BusinessAlarmSystemsComponent,
      },
      {
        path: "tactical-response",
        component: BusinessTacticalResponseComponent,
      },
      { path: "alarm-monitoring", component: BusinessAlarmMonitoringComponent },
      {
        path: "alarm-cctv-integration",
        component: BusinessAlarmCctvIntegrationComponent,
      },
      { path: "cctv-systems", component: CctvSystemsComponent },
      { path: "live-cctv-mobile", component: LiveCctvComponent },
      { path: "access-control", component: BusinessAccessControlComponent },
      { path: "video-intercom", component: VideoIntercomComponent },
      { path: "lpr", component: LprComponent },
      { path: "technical-support", component: TechnicalSupportComponent },
      { path: "electrified-fencing", component: ElectrifiedFencingComponent },
      { path: "", component: AllCommercialServicesComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommercialServicesRoutingModule {}
