<div class="container-fluid p-4">
  <div class="row align-items-center container-fluid">
    <div class="col-md">
      <h2 class="accent-text text-uppercase">{{title}}</h2>
      <p>{{lead}}</p>
      <div class="row">
        <div class="col-md-4">
          <ul class="list-group app-bg" style="list-style-type: none;">
            <li *ngFor="let service of services1" class="p-1">
                <a class="link text-capitalize" routerLink="{{service.link}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  {{service.title}}
                </a>
            </li>
           </ul>
        </div>
        <div class="col-md-4">
          <ul class="list-group app-bg" style="list-style-type: none;">
            <li *ngFor="let service of services2" class="p-1">
            <a class="link text-capitalize" routerLink="{{service.link}}" [ngClass]="service.linkClass" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >{{service.title}}</a>
            </li>
           </ul>
        </div>
        <div class="col-md-4">
          <ul class="list-group app-bg" style="list-style-type: none;">
            <li *ngFor="let service of services3" class="p-1">
              <a class="text-capitalize" routerLink="{{service.link}}" [ngClass]="type === 'assist'?'special-link': 'link'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{service.title}}</a>
            </li>
           </ul>
        </div>
      </div>


    </div>
    <div class="col-md-6 app-bg-lighter" *ngIf="box">
      <div class="d-flex flex-column p-2 align-items-center">
        <h4 class="accent-text text-uppercase text-white">{{box.title}}</h4>
        <div class="row align-items-center">
          <div class="col-md-5">
            <img [src]='box.image'>

          </div>
          <div class="col-md-7">
            <p>{{box.body}}
            </p>
          </div>
        </div>
        <button role="button" class="button button-primary button-block text-uppercase my-2" [routerLink]='box.btnLink'>
          {{box.btnText}}
        </button>
      </div>

    </div>
  </div>
</div>
