<div class="container-fluid app-bg">

  <app-panel
  [landing]="true"
  titleStart="Careeer"
  titleEnd="Opportunites"
  body="Sniper Security is proud to be one of South Africa's leading security companies; employing a staff complement that is dedicated, vigilant and professional. Our service delivery standards is regarded as one of the highest in the Industry. We are committed to the well-being and safety of our customers and there properties. To join our dynamic security company, simply complete the form below and attach your CV. Exciting challenges and excellent career opportunities await."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2Fjob-application-form.jpg?alt=media&token=5cbf9581-8043-4c0d-86ae-649a315f4e58'|panelStyle)"
  [showButtons]='false'
  >

  </app-panel>
  <div class="d-flex flex-column align-items-center justify-content-center"  *ngIf="!loading; else spinner">
    <form [formGroup]='careerForm' *ngIf="careerForm" class="my-2 d-flex flex-column w-75 align-items-center">
    <h1 class="m-1">Please complete the form below and attach your CV</h1>
    <p><small>All fields are required.</small></p>

      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Name</mat-label>
        <input matInput [formControlName]="'name'" type="text" [required]='true'>
        <mat-error *ngIf="careerForm.controls.name.errors">Name required</mat-error>
      </mat-form-field>
      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Surname</mat-label>
        <input matInput [formControlName]="'surname'" type="text"  [required]='true'>
        <mat-error *ngIf="careerForm.controls.surname.errors">Surname required</mat-error>

      </mat-form-field>
      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Email Address</mat-label>
        <input matInput [formControlName]="'email'" type="text" [required]='true'>
        <mat-error *ngIf="careerForm.controls.email.errors">Valid email required</mat-error>
      </mat-form-field>
      <mat-form-field [appearance]="'outline'" class="w-100">
        <mat-label>Contact Number</mat-label>
        <input matInput [formControlName]="'contactNumber'" type="tel" [required]='true'>
        <mat-error *ngIf="careerForm.controls.contactNumber.errors">Valid contact number required required</mat-error>
      </mat-form-field>

      <mat-form-field [appearance]="'outline'" class="w-100 cursor-pointer" (click)="cvInput.click()" style="cursor: pointer;">
        <mat-label>Please attach your CV</mat-label>
        <input readonly matInput [value]="cv?.name || ''" class="cursor-pointer" required>
        <mat-icon matSuffix >attach_file</mat-icon>
        <mat-hint>CV must be a PDF</mat-hint>
      </mat-form-field>
      <input #cvInput class="d-none" type="file" accept='application/pdf' (change)="fileSelect($event,'cv')">


      <mat-form-field [appearance]="'outline'" class="w-100 cursor-pointer" (click)="idInput.click()" style="cursor: pointer;">
        <mat-label>Please attach a copy of your ID</mat-label>
        <input readonly matInput [value]="id?.name || ''" class="cursor-pointer" required>
        <mat-icon matSuffix >attach_file</mat-icon>
        <mat-hint>ID must be a PDF or Image</mat-hint>
      </mat-form-field>
      <input #idInput class="d-none" type="file" accept='application/pdf, image/*' (change)="fileSelect($event,'id')">


      <button class="btn app-bg-danger w-50 m-2"(click)="submit()" [disabled]="!careerForm.valid">Submit</button>

    </form>
  </div>

</div>

<ng-template #spinner>
  <div class="d-flex flex-column align-items-center justify-content-center full-height">
    <mat-spinner></mat-spinner>
    <h1 class="accent-text">Processing...</h1>
  </div>
</ng-template>
