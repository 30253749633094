<div class="container-fluid">
  <app-panel [titleStart]="'Internship'" [landing]='true' [titleBreak]='false' [titleEnd]="'Programme'" introText="The aim of the Sniper internship programme is to get the young learners job ready and to equip them with the necessary foundation and work ethic culture."
    [showButtons]="false" [body]="programDetails" [alignItems]="'align-items-start'" subtitle="SOUTH AFRICA’S YOUNG LEADERS IN TRAINING  " [style]="('assets/interns/interns.jpg'|
        panelStyle: null: { 'background-position':'top', 'background-size':'contain', 'background-image': 'url(assets/interns/interns.jpg)'})" >
  </app-panel>

  <ng-container *ngFor="let intern of interns">
    <app-panel [titleStart]="''" [titleBreak]='false' [titleEnd]="intern.name" [subtitle]="intern.title" [showButtons]="false"
      [body]="intern.comment" [alignment]="intern.alignment" [style]="(intern.picture|
          panelStyle: intern.alignment)" [introText]="intern.intro">
    </app-panel>
  </ng-container>
  <app-panel [alignItems]="'align-items-start'" [titleStart]="'Life-Skills'"  [landing]='true' [titleBreak]='false' [titleEnd]="'Coaching'"
    [showButtons]="false" [body]="coaching" [introText]="'We like to ensure that our interns grow in both their professional and personal lives.'"  [style]="('assets/interns/life-skills-training.jpg'|
        panelStyle)">
  </app-panel>

</div>
