<ng-container *ngIf="!loading; else spinner">
  <ng-container *ngIf="!error; else errorTemplate">
    <div class="container-fluid app-bg-white">
      <div class="row header-row">
        <div class="col-md-4">
        </div>

        <div class="col-md-4">
          <div class="d-flex flex-column justify-content-center align-items-center text-center small-text m-2">
            <img src="../../../assets/new-logo.png" width="250px">
            <a href="tel:021 697 0573" class="text-dark">021 697 0573</a>
            <a class="text-dark" href="mailto:info@snipersecurity.co.za">info@snipersecurity.co.za</a>
            <a class="text-dark" href="https://snipersecurity.co.za">www.snipersecurity.co.za</a>
          </div>
        </div>

        <div class="col-md-4">
          <div class="d-flex flex-column justify-content-center align-items-center m-2">
            <img src="../../../assets/psira.jpg" width="100px">
            <span style="text-align: center; font-size: x-small;">
            Registered as a security service provider<br>by the Private Security Industry Regulatory Authority
            </span>
          </div>

        </div>

      </div>
      <div class="row">
        <div class="col-12">
          <h3 class="text-center primary-text" id="title">{{sniperForm.formName}}</h3>
          <div class="page">
            <div class="app-row" *ngFor="let group of answerGroups">
              <div class="column" *ngFor="let res of group.answers">
                <div class="app-row" *ngIf='res.key !== "contractTerms"'>
                  <div class="column">
                    <span [innerHTML]="res.question" class="question"></span>
                  </div>
                  <div class="column">
                    <span *ngIf='res.key !== sniperForm.attachment?.key; else attachment' class="answer" [innerHTML]="res.value | formAnswer"></span>
                    <ng-template #attachment>
                      <span class="answer"><a target="_blank" [href]='sniperForm.attachment.url' >{{res.value | formAnswer}}</a></span>
                    </ng-template>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-12" *ngFor="let form of sniperForm.relatedResponses">
          <h4 class="text-center mt-3 primary-text">{{form.formName}}</h4>
          <div class="page">
            <div class="app-row">
              <div class="column" *ngFor="let answer of form.answers">
                <div class="app-row">
                  <div class="column">
                    <span [innerHTML]="answer.question" class="question"></span>
                  </div>
                  <div class="column">
                    <span class="answer" [innerHTML]="answer.value | formAnswer"></span>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div class="page-break mt-3">
          <div *ngIf="terms" class="col-12 page-break">
            <app-contract-terms></app-contract-terms>
          </div>
        </div>

      </div>
    <button class="button button-secondary m-2" (click)="print()" role="button">Download</button>
    <!-- <a class="float" target="_blank" rel="noopener" *ngIf="downloadLink" href="{{downloadLink}}" role="link">Download</a> -->
    </div>

  </ng-container>
  <ng-template #errorTemplate>
    <div class="container-fluid center-parent full-height">
      <p class="text-light">
        <span class="primary-text display-5 header-font">{{error}}</span><br>
        Please contact us at <a href="mailto:info@snipersecurity.co.za" class="link">info@snipersecurity.co.za</a>
      </p>

    </div>
  </ng-template>

</ng-container>


<ng-template #spinner>
  <div class="container-fluid center-parent full-height">
    <div>
      <mat-spinner></mat-spinner>
      <h1 class="accent-text">Loading..</h1>
    </div>

  </div>
</ng-template>
