import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OffersService } from '../shared/services/offered-services.service';
import { ScreenService } from '../shared/services/screen.service';
import { NavService } from './nav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {


  @Output() menuPressed = new EventEmitter<boolean>();

  /**
   * the index of the active tab/menu item
   * Indexes start from 1.
   */
  public active = 0;

  /**
   * true if the cursor is hovering over the navbar area
   */
  isHovering = true;

  otherNavClass = '';


  public navTypes = [];


  constructor(public screen: ScreenService, offersService: OffersService,private  nav: NavService) {

    // deselect the active menu when navigating to another page
    screen.navigation.subscribe(() => {
      this.active = 0;
    });


    this.navTypes = offersService.sections;


  }

  ngOnInit(): void {
    this.nav.sub.subscribe(val => {
      if(val){
        this.active = 3;
      }
    });
  }

  public onHoverChange(isHovering: boolean){
    this.isHovering = isHovering;
    if (!isHovering){
      this.active = 0;
    }
  }

  navButtonPressed(){
    this.menuPressed.emit(true);
  }


}
