<div class="container-fluid">

  <!-- 0 -->
  <app-panel
    titleStart="Home Security"
    titleEnd="Camera Systems"
    body="Cutting edge CCTV systems with video recording and smartphone integration to protect your loved ones."
    [landing]="true"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fres%2Ffamily%2Bdog.jpg?alt=media&token=a6feea4a-d3b0-48f4-bd88-0ec150735b14'|panelStyle)">
  </app-panel>

  <!-- 1 -->
  <app-panel
    titleStart="View, save, and send video footage"
    titleEnd="from your smartphone."
    [titleBreak]='false'
    body="Sniper is committed to providing connected security solutions throughout your home. When using Sniper's proprietary home security app, you can view live video footage, save footage to a local hard drive and share important video files, all from your smartphone. It is easier and more convenient than ever before to monitor your home even when you are away at work or on vacation."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcctv-phone-view-2.jpg?alt=media&token=12880ce2-7b2d-486e-912e-6dc02f2c3933'|panelStyle)">
  </app-panel>

  <!-- 2 -->
  <app-panel
  titleStart="Security Experts study the layout of your home, "
  titleEnd="and install security cameras where it matters most."
  [titleBreak]='false'
  [alignment]="'end'"
  body="<p>Our security experts review the layout of your home before strategically installing security cameras at vulnerable locations ensuring that you can record and capture emergency situations when they occur. Once installed, technicians will demonstrate how to use the CCTV mobile app so that you can get the most out of your CCTV security system.
    We are proud to offer cutting edge equipment, backed by more than 25 years of customer experience that you can count on.
  </p>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcctv-layout.jpg?alt=media&token=7f3c3f59-6021-4044-a262-720b08a02508'|panelStyle:'end')">
  </app-panel>

  <!-- 3 -->
  <app-panel
  titleStart="A flexible, scalable security camera system"
  titleEnd="that is ideal for every home."
  [titleBreak]='false'
  body="<p>We know that no two homes are the same and that is why we take the time to understand your needs when installing a security system. if your home has a relatively small footprint or if you only require a simple camera set up, we can meet your needs with an appropriately designated system. Whatever your needs, Sniper can assist you with advanced camera technology and quality customer service.
  </p>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fres%2Fcctv-devices-view.jpg?alt=media&token=21e1b81a-c15f-4838-a4da-e510d4f35b71'|panelStyle)">
  </app-panel>

  <app-panel
  titleStart="CCTV SURVEILLANCE"
  titleEnd="PRO"
  [titleBreak]='false'
  body="<p>Sniper CCTV Pro makes use of analytical cameras in this package, to offer clients the most advanced and responsive CCTV solution. The analytical cameras allow for “virtual fences” to be set on the perimeter of your premises with the added benefit of allowing for true motion detection and differentiation between human and other movements in the designated area. The system can be set to ensure that when a signal is triggered, in all or only specific areas, an alert is sent to our control room. The control room will then immediately dispatch our tactical support services.</p>
    <p>Snipers analytical camera option is best suited for commercial use and residential estates with large perimeter fences that require monitoring. However, it is also beneficial for individual household use as well.</p>

    <p>Parameters and Time Zones can be set on these cameras to only trigger at specific times of the day and for specific types of motion, be they human or otherwise. This allows you complete control and management over your alarm system and its triggers.</p>

    <p>For our premier product offering, it is recommended that your specific needs be discussed with a Risk Consultant to assess the number of cameras that are needed and the best solution for your monitoring requirements.</p>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2FAccusense%20Camera%201.png?alt=media&token=03c86937-cabd-4e42-90c7-e1884a4afff2'|panelStyle: 'start': {'background-size':'contain'})">
  </app-panel>



  <div class="row page-row justify-content-center app-bg">
    <div class="col-lg-10">
      <!-- <h1 class="text-center display-5 my-lg-2 text-uppercase text-white">Artifical Intelligence
        <span class="accent-text">in action</span> to protect your home.
      </h1> -->
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fvlcsnap-2020-12-01-17h37m44s481.png?alt=media&token=78842e55-655f-448f-92fa-4a2db6d8494f">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FAcuSense%20Products%20for%20Residences.mp4?alt=media&token=b447ee81-931e-477b-8790-b1bb30c9ce28" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>

  <app-panel
  titleStart="ALARM ACTIVATION"
  titleEnd="SURVEILLANCE VIEW"
  [titleBreak]='false'
  body="<p>With the Alarm Activation Surveillance View package, Sniper will link your cameras to your alarm system. This package allows our control room to only view your cameras in the event of an alarm activation associated with the triggered alarm zone linked to a specific camera. This ensures your privacy is never compromised. The cameras are only accessed to provide visual assistance when needed and when an alarm is triggered. The operator will be able to view the incident that triggered the alarm as well as view the scene – while contacting the client and dispatching the tactical support officer to the scene. The Activation Surveillance View gives our control room the unique advantage to accurately and effectively respond to a positive alarm activation and to identify false alarms activations.</p>

    <p>The service can also be tailored so that only certain cameras are accessible to our control room – to further ensure client privacy.</p>

    <p>Activation Surveillance View is suitable for commercial and residential use and is a reactive tool to assist in instances of a triggered alarm.</p>

    <p>There are a number of hardware options that can be used with the Surveillance Activation View option including a personalised option, however, the most common choices are as follows:
      <ul>
        <li>4 Channel option:  4 Bullet or Dome cameras, 4 channel DVR, 1TB Hard drive, Power Supply, up to 100m of cable</li>
        <li>8 Channel option:  8 Bullet or Dome cameras, 8 channel DVR, 1TB Hard drive, Power Supply, up to 200m of cable</li>
        <li>16 Channel option:  16 Bullet or Dome cameras, 16 channel DVR, 1TB Hard drive, Power Supply, up to 200m of cable</li
      </ul>
    </p>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1.jpg?alt=media&token=3e0acb87-cc30-4f88-85f2-71cd2dd09119'|panelStyle)">
  </app-panel>
  <!-- 4 -->
  <app-panel
  titleStart="Expert system support "
  titleEnd="is just a call away."
  [titleBreak]='false'
  [alignment]="'end'"
  body="If you require technical or equipment assistance you can reach out to our technical department. Our technical department is ready to help you. If you are experiencing issues with your security camera equipment you can log a technical support call ONLINE. Once received, we will dispatch a technician to your home. We prioritise every customer concern. When you call on us, expert consultants will be there with answers and solutions."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fexpert-support.jpg?alt=media&token=d2997feb-9321-424c-8c9f-c427712b418d'|panelStyle:'end')">
  </app-panel>

  <!-- 5-->
  <app-faq-panel
    [about]="'our security camera systems'"
    [faqs]='faq'
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fchatting-women-apartment.jpg?alt=media&token=25cffa88-d866-440a-aa09-b5a1de8ce7c4'|panelStyle)">
  >

  </app-faq-panel>

  <!-- 6 -->
  <app-action-panel
  titleStart="Get a free"
  titleEnd="quote."
  [titleBreak]="false"
  body="Our security products are tailor-made to suit the unique design of your home.
  Sniper's expert security technicians will consult with you and then design and install a system that allows you to realize the benefits of a comprehensive home security camera system."
  [btnLink]="'/forms/quote'" >
  </app-action-panel>


</div>
