import { Component, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { NavService } from "./navbar/nav.service";
import { ScreenService } from "./shared/services/screen.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  @ViewChild("sidenav") sidenav: MatDrawer;

  title = "Sniper Security Solutions";

  constructor(nav: NavService, private screen: ScreenService) {
    nav.sub.subscribe((res) => {
      if (res && this.sidenav && screen.onMobile) {
        this.sidenav.open();
      }
    });
  }
}
