import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-intercom',
  templateUrl: './video-intercom.component.html',
  styleUrls: ['./video-intercom.component.css']
})
export class VideoIntercomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
