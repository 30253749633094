/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { SecurityPackage } from "src/app/models/security-packages.model";

@Injectable({
  providedIn: "root",
})
export class SecurityPackagesService {
  constructor() {}

  packages() {
    const bronze: SecurityPackage = {
      title: "Bronze",
      cost: "R495",
      costValue: 495,
      benefits: [
        "Tactical Response + Alarm Monitoring",
        "Limited Alarm Activation Responses – Subject to a False Alarm Consultation Process",
        "1 Tactical Response Vehicle Covering All Areas",
        "Panic Button Assistance",
        "No Telephonic Technical Support",
        "Low Priority Technical Support",
        "No Telephonic Management Support",
        "Low Priority Management Support",
        "Management Support Via Email Only",
      ],
      header: "../../../assets/packages/bronze_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fbronze_bg.jpg?alt=media&token=6bef5293-face-4b87-a797-ede550e0d68e",
      color: "#8e3a09",
    };

    const silver: SecurityPackage = {
      title: "Silver",
      cost: "R552",
      costValue: 552,
      benefits: [
        "Tactical Response + Alarm Monitoring",
        "Unlimited Alarm Activation Responses",
        "x2 Panic Button Assistance",
        "x3 Meet and Greet Service Calls",
        "Low Battery Email Notifications",
        "Fail to Communicate Email Notifications",
        "AC Mains Failure Email Notifications",
        "x3 Comfort Call Assistance",
      ],
      header: "../../../assets/packages/silver_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fsilver_bg.jpg?alt=media&token=261cb515-a833-4bf0-b693-f0351cc103b9",
      color: "#c6c6d1",
    };

    const gold: SecurityPackage = {
      title: "Gold",
      cost: "R695",
      costValue: 695,
      benefits: [
        "Tactical Response + Alarm Monitoring",
        "Unlimited Alarm Activation Responses",
        "X3 Panic Button Assistance",
        "X3 Meet and Greet Service Calls",
        "Personalized Management Support",
        "Proactive Technical Support",
        "Low Battery Email Notifications",
        "Fail to Communicate Notifications",
        "AC Mains Failure Verbal & Email Notification",
        "X1 FREE Security Assessment",
        "X3 Courtesy Checks on Request",
        "X3 Comfort Call Assistance",
        "10% Discount On All Alarm Battery Replacements",
        "10% Discount On All Alarm System Servicing",
        "1x Free Technical Call-Out Per Month. Excludes repair and equipment costs",
      ],
      header: "../../../assets/packages/gold_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fgold_bg.jpg?alt=media&token=ab1022e0-8686-43b7-b52b-0647516dae36",
      color: "#dbb123",
      footer: "../../../assets/packages/5_Star_Rating.png",
      footerText: "Most popular cost saving package with our clients",
    };

    const platinum: SecurityPackage = {
      title: "Platinum",
      cost: "R995",
      costValue: 995,
      benefits: [
        "Tactical Response + Alarm Monitoring",
        "Unlimited Alarm Activation Responses",
        "x5 Panic Button Assistance",
        "6 Meet and Greet Service Calls",
        "Personalized Management Support",
        "Priority Technical Support",
        "Low Battery Email Notifications",
        "Fail to Communicate Email Notifications",
        "AC Mains Failure Verbal & Email Notifications",
        "Technical Assistance and Advice",
        "X6 Courtesy Checks on Request",
        "Same Day Technical Support Service",
        "Priority Management Service",
        "X6 Comfort Call Assistance",
        "15% discount on Battery Replacements",
        "2x Free Technical Call-Out Per Month. Excludes repair and equipment costs",
      ],
      header: "../../../assets/packages/platinum_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fplatinum_bg.jpg?alt=media&token=55a8ac2a-aad6-4e11-85fc-a515979eb782",
      color: "#b7b0f5",
    };

    const black: SecurityPackage = {
      title: "Black",
      cost: "R4950",
      costValue: 4950,
      benefits: [
        "Specialised Tactical Response + Alarm Monitoring GRADE A",
        "Unlimited Alarm Activation Responses",
        "Unlimited Panic Button Assistance",
        "12 Meet and Greet Service Calls",
        "Concierge Management Support",
        "Proactive Technical Support",
        "Low Battery Email & SMS Notifications",
        "Fail to Communicate SMS Notifications",
        "AC Mains Failure Verbal, Email & SMS Notifications",
        "Priority Technical Assistance and Advice",
        "12 Courtesy Checks on request",
        "Same Day Technical Support Service",
        "30 Minute Priority Management Support",
        "Unlimited FREE Risk Assessment",
        "Unlimited FREE Technical Call Outs only",
        "Unlimited Comfort Call Assistance",
        "1 Free Battery Replacement Per Year",
        "Free CCTV Monitoring subject to CCTV system compatibility",
      ],
      header: "../../../assets/packages/black_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fblack_bg.jpg?alt=media&token=1d126e86-479c-4e33-9cd5-82285b043d0c",
      color: "#2e2e30",
    };

    const proBlack: SecurityPackage = {
      title: "Pro Black",
      cost: "P.O.A",
      costValue: 0,
      benefits: [
        "This is a personalized security package and it is tailored specifically to the client’s unique security requirements.",
      ],
      header: "../../../assets/packages/silver_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2FproBlack_bg.jpg?alt=media&token=6370bf77-f95b-4eb1-b947-65caa8a4fcf5",
      color: "#aa2f36",
    };

    return [bronze, silver, gold, platinum, black];
  }

  specialPackages() {
    const bronze: SecurityPackage = {
      title: "Bronze",
      cost: "R750",
      costValue: 750,
      benefits: [
        "Tactical Response + Alarm Monitoring",
        "Limited to 15 Alarm Activation Responses",
        "3 Panic Button Assistance",
        "3 Comfort Call Assistance",
        "Online Technical Booking",
        "Low Battery Email Notifications",
        "Alarm Signal Fail to Communicate",
        "Email Notifications",
        "AC Mains Failure Email Notifications",
      ],
      header: "../../../assets/packages/bronze_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fbronze_bg.jpg?alt=media&token=6bef5293-face-4b87-a797-ede550e0d68e",
      color: "#8e3a09",
    };

    const silver: SecurityPackage = {
      title: "Silver",
      cost: "R995",
      costValue: 995,
      benefits: [
        "Tactical Response + Alarm Monitoring",
        "Unlimited Alarm Activation Responses",
        "4 Panic Button Assistance",
        "4 Meet and Greet Service Calls",
        "Online Technical Support",
        "Low Battery Email Notifications",
        "Alarm Signal Fail to Communicate Notifications",
        "AC Mains Failure Email Notification",
        "3 Courtesy / Holiday Checks on Request",
        "3 Comfort Call Assistance",
        "5% Discount On Alarm Battery Replacements",
        "5% Discount On Alarm System Servicing",
        "<b>1 Free Technical Call-Out Per Month.</b><br>Excludes repair and equipment costs",
      ],
      header: "../../../assets/packages/silver_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fsilver_bg.jpg?alt=media&token=261cb515-a833-4bf0-b693-f0351cc103b9",
      color: "#c6c6d1",
      footer: "../../../assets/packages/5_Star_Rating.png",
      footerText: "Most popular cost saving package with our clients",
    };

    const gold: SecurityPackage = {
      title: "Gold",
      cost: "R1495",
      costValue: 1495,
      benefits: [
        "Tactical Response + Alarm Monitoring",
        "Unlimited Alarm Activation Responses",
        "Unlimited Panic Button Assistance",
        "6 Meet and Greet Service Calls",
        "Online Technical Support",
        "Priority Technical Support",
        "Personalized Management Support",
        "Low Battery Email Notifications",
        "Alarm Signal Fail to Communicate Notifications",
        "AC Mains Failure Verbal & Email Notification",
        "1 FREE Security Assessment Per Month",
        "6 Courtesy / Holiday Checks on Request",
        "6 Comfort Call Assistance",
        "10% Discount On All Alarm Battery Replacements",
        "10% Discount On Alarm System Servicing",
        "<b>2x Free Technical Call-Out Per Month.</b><br>Excludes repair and equipment costs",
      ],
      header: "../../../assets/packages/gold_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fgold_bg.jpg?alt=media&token=ab1022e0-8686-43b7-b52b-0647516dae36",
      color: "#dbb123",
    };

    const platinum: SecurityPackage = {
      title: "Platinum",
      cost: "R1995",
      costValue: 1995,
      benefits: [
        "Tactical Response + Alarm Monitoring",
        "Unlimited Alarm Activation Responses",
        "Unlimited Panic Button Assistance",
        "9 Meet and Greet Service Calls",
        "Priority Technical Support",
        "Technical Telephonic Assistance and Advice",
        "Personalized Management Support",
        "Onsite Management Support",
        "Low Battery Email Notifications",
        "Alarm Signal Fail to Communicate Email Notifications",
        "AC Mains Failure Verbal & Email Notifications",
        "Arming & Disarming Feature",
        "1 FREE Risk Assessment Per Month",
        "9 Comfort Call Assistance",
        "9 Courtesy / Holiday Checks on Request",
        "15% discount On Battery Replacements",
        "15% Discount On All Alarm System Servicing",
        "<b>3 Free Technical Call-Out Per Month.</b><br>Excludes repair and equipment costs",
      ],
      header: "../../../assets/packages/platinum_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fplatinum_bg.jpg?alt=media&token=55a8ac2a-aad6-4e11-85fc-a515979eb782",
      color: "#b7b0f5",
    };

    const black: SecurityPackage = {
      title: "Black",
      cost: "R4950",
      costValue: 4950,
      benefits: [
        "Tactical Response + Alarm Monitoring",
        "Unlimited Alarm Activation Responses",
        "Unlimited Panic Button Assistance",
        "12 Meet and Greet Service Calls",
        "Concierge Management Support",
        "Priority Technical Support",
        "Low Battery Email & SMS Notifications",
        "Alarm Signal Fail to Communicate SMS Notifications",
        "AC Mains Failure Verbal, Email & SMS Notifications",
        "Priority Technical Assistance and Advice",
        "12 Courtesy / Holiday Checks on request",
        "30 Minute Priority Management Support",
        "Unlimited FREE Risk Assessments",
        "Unlimited FREE Technical Call Outs only",
        "Unlimited Comfort Call Assistance",
        "20% discount on Battery Replacements",
        "20% Discount On All Alarm System Servicing",
        "1 Free Emergency Guard Service Subject to Incident",
        "Free Annual alarm and CCTV system service",
        "Free Hikvision CCTV Monitoring subject to CCTV system compatibility",
      ],
      header: "../../../assets/packages/black_header.png",
      background:
        "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fsecurity-packages%2Fblack_bg.jpg?alt=media&token=1d126e86-479c-4e33-9cd5-82285b043d0c",
      color: "#2e2e30",
    };

    return [bronze, silver, gold, platinum];
  }
  /**
   *
   * @param packageName the name of the package
   * @returns the package that matches the provided name
   */
  getPackage(packageName: string, packageVersion = "1"): SecurityPackage {
    const packagesToSearch =
      packageVersion === "1" ? this.packages() : this.specialPackages();
    return packagesToSearch.find((pkg) => pkg.title === packageName);
  }
}
