<div class="terms" [ngClass]=" box? 'terms-box': 'print'">
<div class="page-break"></div>
<h1>IMPORTANT NOTICE</h1>
<ol>
  <li>PLEASE BE INFORMED THAT A PRORATA SERVICE PAYMENT IS REQUIRED AND WILL AUTOMATICALLY BE DEBITED.</li>
  <li>A R495 EX VAT WILL BE CHARGED FOR ALL RECONNECTIONS AFTER A SUSPENSION.</li>
  <li>A LINK UP FEE WILL BE CHARGED FOR LINKING UP YOUR ALARM SYSTEM [PROGRAMMING] SUBJECT TO THE DESIGN AND LAYOUT OF YOUR PREMISES.</li>
  <li>SNIPER WILL AUTOMATICALLY REDEBIT YOUR ACCOUNT WITHOUT NOTIFICATION IN THE EVENT OF A DEBIT ORDER RETURN.</li>
  <li>SNIPER SECURITY WILL CHARGE ALL CLIENTS A MINIMUM PENALTY FEE OF R195
    EX VAT FOR ALL DEBIT ORDER TRANSACTIONS WHICH ARE RETURNED UNPAID DUE TO INSUFFICIENT FUNDS ETC.</li>
  <li>SHOULD YOUR DEBIT ORDER TRANSACTIONS BE RETURNED UNPAID.
    SNIPER SECURITY WILL AUTOMATICALLY SUSPEND YOUR SECURITY SERVICES WITHOUT NOTIFICATION.</li>
  <li>A RE-INSTALLATION FEE WILL BE CHARGED FOR ALL SYSTEMS REMOVED AND
    RE-INSTALLED SHOULD YOU RELOCATE TO A PREMISES OR AREA THAT SNIPER SECURITY DOES NOT SERVICE,
    THE ARMED RESPONSE SERVICE WILL BE SUB-CONTRACTED.
    (PREMIUMS SUBJECT TO CHANGE)</li>
  <li>ALL SERVICE CHARGES OR TECHNICAL CALLS WILL AUTOMATICALLY BE DEDUCTED FROM YOUR DEBIT ORDER
    48 HOURS AFTER THE COMPLETION OF WORK WITHOUT NOTIFICATION.</li>
  <li>NO SUB.CONTRACTOR OR 3RD PARTY MAY CONDUCT REPAIRS/MAINTENANCE OR ADD ADDITIONAL EQUIPMENT
    TO YOUR ALARM SYSTEM AS THIS WOULD AFFECT YOUR GUARANTEE AND COMMUNICATION SIGNALS THEREFORE JEOPARDISING YOUR SAFETY.</li>
  <li> AFTER THE FIRST YEAR FROM THE START DATE AND THE YEARS FOLLOWING WE ARE ENTITLED TO INCREASE OUR CHARGES IN ORDER TO COVER ANY INCREASE IN THE COST OF PROVIDING THE SERVICE.
  OUR ANNUAL 10% INCREASE WILL TAKE PLACE AUTOMATICALLY WITHOUT NOTIFICATION.</li>
  <li>ANNUAL RADIO LICENCE FEES WILL AUTOMATICALLY BE DEBITED FROM YOUR ACCOUNT WITHOUT NOTIFICATION.</li>
  <li>ALL PAYMENT BALANCES OR BALANCE OF DEPOSITS WILL AUTOMATICALLY BE DEDUCTED FROM YOUR
  DEBIT ORDER 48 HOURS AFTER THE COMPLETION OF WORK WITHOUT NOTIFICATION.</li>
  <li>ANNUAL PAYING CLIENT SECURITY SERVICES WILL AUTOMATICALLY BE SUSPENDED AT THE END OF THEIR ANNUAL TERM.</li>
  <li>ALARM SYSTEMS AND RADIO TRANSMITTERS MUST BE SERVICED EVERY 6 MONTHS TO ENSURE THAT YOUR SYSTEM IS FUNCTIONING OPTIMALLY.</li>
</ol>

<h1>Definitions</h1>
In this Agreement, the following words shall have the meanings assigned to them below.
<ul>
  <li>Alarm Receiving Centre - The place to which signals from the System are transmitted and are monitored.</li>
  <li>Authorised Dealer - A company, other legal entity or individual conducting business on its own behalf and independently from us and Authorised by us to offer this Agreement to you. The Authorised Dealer is responsible for installing the System.
  </li>
  <li>Emergency Response - The procedures the police or armed response company or other nominated party carry out when the Alarm Receiving Centre informs them that a signal has been received from the system</li>
  <li>Extra Charges - The Extra Charges referred to in clauses 7.3, 7.4 and 7.5 below.</li>
  <li>Fixed Period - 12/24/36 months from the Start Date, unless continued thereafter in terms of clause 9.2 below.</li>
  <li>Guarantee - The Guarantee explained in clause 4 below.</li>
  <li>Instructions - Any instruction we issue to you from time to time, including any manual relating to the System.</li>
  <li>Keyholder - The third party you have chosen to hold the keys to the Premises and to go to the Premises if we advise them that the Alarm Receiving Centre has received a signal from the System.</li>
  <li>Normal Working Hours - 9am to 5pm, Mondays to Fridays, excluding public holidays.</li>
  <li>Premises - The building where the System is installed.</li>
  <li>Services - The service described below and chosen by you.
    The Services depend on the service level you have chosen in this Agreement.</li>
  <li>Specifications - The document (which forms part of this agreement ) setting out details of the System, including any amendments thereto and/or instructions we issue to you from time to lime.</li>
  <li>Start Date - For new systems, the date upon which the Authorised Dealer completes the installation of the System and you sign the contract where you have a System already at the Premises, the Start Date is the date on which the Authorised Dealer signs the contract.</li>
  <li>System - The equipment installed by us or the Authorised Dealer including wiring and also anything we install when we carry out repairs under this Agreement. Unless indicated otherwise in the relevant block on page 1 of this agreement, we own the system until fully paid for.</li>
  <li>We, Our, us - Sniper Security including where appropriate our agents and / or sub - contractors.</li>
  <li>You, your - The Customer with whom we enter into this Agreement.</li>
  <li>Work order - The document to be signed by you which confirms that the System has been satisfactory installed and that you have been instructed concerning its use.</li>
</ul>

<h1>Service Levels</h1>
The Maintenance Services Consist only of:
<ol>
  <li>Inspection and repair of the System upon your reasonable request during Normal Working Hours for the duration of this Agreement.</li>
  <li>Services and Technical charges are subjected to the following:
    <ol type="a">
      <li>Distance</li>
      <li>Safety of the area</li>
      <li>Labour intensity</li>
    </ol>
  </li>
</ol>

The Monitoring Service Consists only of:
<ol>
  <li>Monitoring of the system by telephone and or TX / Communicator / Radio.</li>
  <li>Notification to the Keyholder and police and if applicable your chosen armed response service provider (and also the fire authority and / or medical service provider if chosen by you and applicable) if an activation signal is received from the System and if no satisfactory explanation is given telephonically from the premises.
  </li>
</ol>

The Armed Response Services consist only of:
<ol>
  <li>1. Attendance by armed personnel at the Premises as quickly as operational circumstances may permit in response to activation signals received from the System if no satisfactory explanation is given telephonically from the Premises.</li>
  <li>2. Sniper Security Solutions is prohibited by regulation from guaranteeing a response time.</li>
  <li>3. Such further steps as may be reasonably necessary to safeguard the Premises, the contents thereof, you and your invitees.</li>
</ol>
<p>The services to be provided to you under this Agreement are determined by your choice or service level by ticking the appropriate box(es) on preceding pages on this Agreement.</p>

<h1>Important</h1>

<p>By your signature hereto, you acknowledge that you have read and understand all of the terms of this Agreement and agree to be bound by all of its terms. You should therefore read this Agreement carefully and ensure that you understand it before signing.<br>
The System is designed and the Services are intended, only to reduce, and not prevent, the risk of loss or damage at the Premises. We accordingly do not undertake, warrant or guarantee that the system or the services can or will prevent any such loss or damage and we will not in any circumstances be liable for any loss, injury, harm or damage howsoever arising from or in connection with the System and/or the Services. We undertake only to use our best endeavours to provide the Services you have chosen.<br>
After every alarm activation/emergency call, it is the client's responsibility to report to the premises to conduct an internal check with the Armed Response Officer. This is to rule out the possibility of an intrusion.<br>
You hereby indemnify us against liability for any loss or damage whatsoever arising to you or any other person or 3rd part at or near the Premises.<br>
Special alarm maintenance packages are subject to terms and conditions and you are required to service your alarm system and repair the faults prior to signing the contract. Special alarm maintenance packages do not cover outdoor sensors, point to point beams or strip beams.<br>
This agreement is subject to and conditional upon us checking and approving your credit rating and confirmation to our satisfaction that you own the Premises or that the owner has also become bound to this agreement.</p>

<!-- <div class="page-break"></div> -->

  <h1>Terms and conditions</h1>
  <h2>1. Your Obligations</h2>
  You are obliged, in addition to your other obligations contained in this Agreement to:
  <ul>
    <li>1.1 Operate the System according to the instructions and the terms of this Agreement.</li>

    <li>1.2 Use your best efforts to ensure that the Premises and the contents thereof are safe and
    without risk for us in doing what we are obliged to do under this Agreement.</li>

    <li>1.3 Warn us in writing about any risk and any hazardous materials at or near the Premises
    which at any time come to your attention via registered mail only.</li>

    <li>1.4 Give us access to the Premises so that we may provide the Services and allow us to
    disconnect and/or remove the System when this Agreement terminates.</li>

    <li>1.5 Be liable to us, and compensate us, for all and any liabilities, claims, losses, expenses or
    damages we may suffer caused by or arising from:</li>

    <li>1.5.1 Your failure to operate the System according to the instructions.</li>

    <li>1.5.2 The connection of the system to any equipment or device not supplied by us.</li>

    <li>1.5.3 The events referred to in clause 5.2.2 below.</li>

    <li>1.6 Advise us in writing immediately if anyone tampers with the System or any part thereof is
    damaged or stolen.

    <li>1.7 Pay your telephone, electricity and other bills timeously so that the System and the
    Services are not affected.</li>
  </ul>

  <h2>2. Specific Prohibitions</h2>
  <p>You are not entitled to and undertake not to:</p>
  <ul>
    <li>2.1 Interfere or tamper with the System or attempt to repair the System or allow any other
      party to do so.</li>
      <li>2.2 Cede, assign or in any other way transfer your rights and obligations under this
      Agreement</li>
      <li>2.3 In the event of your residential / business premises being sold, the alarm system will not
      form part of the sales agreement until fully paid for and remains the property of Sniper
      Security Solutions.</li>
  </ul>

  <h2>3. The Purpose of the System</h2>
  <ul>
    <li>3.1 This clause 3 is in addition to the exemption and indemnity of the Agreement and does
      not in any way deteriorate from those provisions.</li>
      <li>3.2 The system is designed to reduce the risk of loss or damage at the Premises so far as this can be done by the use of this type of equipment. We do not, however guarantee
      that the System cannot be removed, tampered or interfered with or made to stop working
      by you or by any other person. We are accordingly not liable to you for any loss or
      damage you or any other party may suffer howsoever arising from any such removal,
      tampering, interference or from stopping of working.</li>
      <li>3.3 Furthermore we do not undertake or guarantee to you that:</li>
      <li>3.3.1 Particular losses or injuries will be prevented by using the System or Services</li>
      <li>3.3.2 That the System will work continuously and without error, in particular where interruptions.
      or errors are due to a cause beyond our control.</li>
      <li>3.3.3 The System, like all mechanical and electronic devices can develop faults.</li>
      <li>3.4 We do not know the value of the Premises or Its contents and the purpose of this Agreement Is not to act as an insurer to you, your invitees, the Premises or the contents thereof.</li>
  </ul>


  <h2>4. Guarantee</h2>
  <ul>
      <li>4.1 We guarantees that we will repair the system free of charge for 12 months from the date of
    purchase.</li>

    <li>4.2 This guarantees does not apply to equipment installed at the Premises prior to you
    signing this Agreement or installed at any time by a third party.</li>

    <li>4.3 This Guarantee does not apply to faults caused by the following:</li>

    <li>4.3.1 Work carried out by any police, fire or other authorities or by any telecommunications agency or by any other party.</li>

    <li>4.3.2 The circumstances referred to in clause 7.4 below.</li>
  </ul>


  <h2>5. Our Liability to You</h2>
  <ul>
    <li>5.1 We will not be liable to you or any other party for any loss or damage which depends on us having special knowledge of your affairs which we would not normally know, even if the loss or damage is due to our fault.</li>

    <li>5.2. We are also not responsible or liable to you or any other party for any of the following:</li>

    <li>5.2.1 Loss due to the act, or failure to act or negligence of any other person including you, your servants and/or invitees, any person at or near the Premises, the Authorised Dealer, the provider of your telephone line or other communication technology any police fire or other authority or any other person. None of these are our agents for any purpose.</li>

    <li>5.2.2 Delays, interruptions or suspensions in providing the Services which are due to any
    other person (including you), thing or event which we could not reasonably be
    expected to prevent or losses arising thereof.</li>

    <li>5.3 Losses due to the fact that equipment not supplied by us is connected to the System.</li>

    <li>5.4 Losses resulting from:</li>

    <li>5.4.1 The police, fire or the other authority, or any medical service, or armed response service
    provider or any other person falling to act In accordance with Emergency Response.</li>

    <li>5.4.2 Any signals transmitted to the Alarm Receiving Centre being received by us for reasons
    beyond our reasonable control.</li>

    <li>5.4.3 Losses due to you failing to follow our recommendations in clause 6 below and/or the
    Instructions.</li>

    <li>5.4.4 Losses outside the purpose of the System contemplated in clause 3 above</li>

    <li>5.5 Our responsibility and obligations to you cease immediately when the Agreement is
    terminated or the Services are suspended under clause 9 below.</li>

    <li>5.6 The provisions of this clause 5 do not in any way deteriorate from the exemptions from
    liability contained elsewhere in this Agreement.</li>
  </ul>

  <h2>6. Our Recommendations To You</h2>
  <ul>
    <li>6.1 Because of the purpose of the System described in clause 3 above, the limits of the
    Guarantee and the exemptions from, and limitations of, our liability to you under this Agreement: we strongly recommend that you take separate Insurance to cover you, your invitees, the Premises and the contents thereof.</li>

    <li>6.2 We recommend that you use a dedicated telephone line for the system communications.</li>

    <li>6.3 We strongly recommend that you install an alarm TX / Communicator / Radio due to the
    increase in telephone lines being cut or sabotaged during a burglary / intrusion.</li>

    <li>6.4 TX / Communicators / Radios will always remain the property of Sniper Security Solutions.</li>
  </ul>

  <h2>7. What it Will Cost You</h2>
  <ul>
    <li>7.1 You are liable for the timeous payment of the charges pertaining to these Services.
      These charges include VAT and, if the rate of VAT changes during the course of this
      Agreement you will be liable to pay VAT at any changed rate.</li>

      <li>7.2 After the first year from the Start Date and the years following we are entitled to increase our charges in order to cover any increase in the cost of providing the services. Our annual 10% increase will take place automatically without notification.</li>

      <li>7.3 You are also responsible for the following charges:</li>

      <li>7.3.1 Installation and equipment charges for the System (where applicable).</li>

      <li>7.3.2 Taxes, fees or charges set by the police, fire or any other authority due to the installation or operation of the System and any false alarm assessments. This does not apply if a false alarm assessment arises from faults which are covered by the Guarantee.</li>

      <li>7.3.3 R300 ex vat per call out for such call outs as we in our sole discretion deem to be unnecessary
      (example false alarm activations and false panic activations without reporting them to the
      control room to cancel the armed response from responding to your emergency call).</li>

      <li>7.3.4 Any extra charge or charges set by the police, fire or other authorities, medical service
      providers, armed response service providers or by any telecommunications agency, the Authorised Dealer or any other party.</li>

      <li>7.4. You are also liable for charges at our rates for labour or materials current at the time when any of the following applies:</li>

      <li>7.4.1 Faults are caused by you or any other person, thing or event which we could not reasonably be expected to prevent.</li>

      <li>7.4.2 The specification or service level you have chosen provides there will be a charge or the
      labour and materials are not covered by the service level you have chosen</li>

      <li>7.4.3 You have asked us to visit the Premises outside Normal Working Hours.</li>

      <li>7.4.4 You request us to change the System or we are required to change it as a result of
      changes at or In the Premises or for any other reason.</li>

      <li>7.4.5 You breach any of your obligations contained or implied in this Agreement.</li>

      <li>7.4.6 You request assistance from us under the Guarantee but the Guarantee does not apply.</li>

      <li>7.4.7 Any replacements, repairs or modifications to the System are needed but are not covered by the Guarantee or by the Services, or are needed as a result of a change in a relevant standard or regulation governing the System.</li>

      <li>7.4.8 The System requires inspection, resetting, repairing or replacing in circumstances
      where:</li>

      <li>7.4.8.1 You, the Keyholder or some other party has failed to follow the instructions, has not locked, closed or secured a window, door or other protected point, has not used or
      adjusted other equipment or components property or has interfered with or tampered
      with the System.</li>

      <li>7.4.8.2 Your equipment or devices which the Authorised Dealer or we have not supplied have caused a false alarm or failure of the System.</li>

      <li>7.4.8.3 Your actions or failure, or those of anyone else other than us results in us needing to inspect, replace or repair the System or any other part thereof.</li>

      <li>7.4.8.4. Should you move to a new premises and would like the alarm system to be re-installed at your new premises, this will be at a rate subject to the layout of your property.</li>

      <li>7.4.8.5 Rodents, other animals or Insects cause damage to or activation of the system.</li>

      <li>7.4.8.6 There is a problem on the telephone line or connection or other communication link.</li>

      <li>7.4.8.7 Weather conditions cause damage to or activations of the System.</li>

      <li>7.5 Unless we agree in writing to the contrary, the service charge does not include any work
      involving redecorating, repairing, carpet laying, concealing cables, building or carpentry
      work. The service charge is established on the basis that we have full access to the
      areas where we carry out work.</li>


      <li>7.6 If this Agreement is terminated under clause 9 below, the following shall apply:</li>

      <li>7.6.1 You will be liable to us for the charges and any other money due to us not paid at the
      termination of the Agreement.</li>

      <li>7.6.2 Unless the Agreement is brought to an end under clause 9.3 below, you will be liable to
      us, at a reasonable estimate of our damages, in an amount equal to the yearly service
      charge which would be due until the end of the Fixed Period less an allowance of 20%.
      This allowance is offered because we would then not be obliged to monitor and maintain
      the System and because we will receive payment earlier than would otherwise have
      been the case.</li>

      <li>7.6.3 If you have already paid us more than the amount due under clauses 7.6.1 and 7.6.2
      above, we will refund any overpayment.</li>

      <li>7.6.4 We may also take further action against you if you are in breach of any of your
      obligations under this Agreement</li>
  </ul>

  <h2>8. Payments</h2>
  <ul>
    <li>8.1 You are obliged to pay the service charges provided for on page 1 and elsewhere in this
      Agreement monthly in advance by debit order. We will require payment in advance for the first
      month charges to allow for the debit order to be established.</li>

      <li>8.2 You are obliged to pay the extra charges contained in clauses 7.4 and 7.5 above within
      12hrs of the date of our request for payment.</li>

      <li>8.3 You are obliged to pay all other amounts provided for in this Agreement within 12hrs.
      of the date of our invoice or of our request for payment.</li>

      <li>8.4 In the event of any payment being overdue, we will be entitled to charge you interest
      from the date of our relevant invoice or request for payment until the date of actual
      payment, at the prime rate of interest charge from time to time by our bankers plus 10%.</li>

      <li>8.5 We are not obliged to, and do not accept post-dated cheques.</li>

      <li>8.6 In the event of any breach by you of your payment obligations, we shall be entitled to
      suspend the performance of our obligations to you and to disconnected and or remove
      the System.</li>

      <li>8.7 Sniper reserves the right to deduct service fees, technical fees, callout charges
      or any other outstanding payments from your debit order including admin
      charges without notification.</li>

      <li>8.8 Should your debit order transaction be returned unpaid, Sniper Security will automatically
      suspend services without notification and have your Rental Alarm System / TX
      Communicator / Radio removed all arrears plus penalties are paid. A removal and
      reinstallation fee will apply.</li>
  </ul>

  <h2>9. Terminating or Suspending the System or Security Services</h2>
  <ul>
      <li>9.1 Unless this Agreement terminates under clause 9.3 or 9.4 below, this Agreement will last
    for a fixed period of the contract of your choice.

    <li>9.2 We are entitled to terminate or suspend this agreement with immediate effect at our
    discretion. This contract will automatically renew itself until such time that the client
    indicates his intention to cancel in writing via registered post only. The Cancellation Notice Is 3 calendar months.</li>

    <li>9.3 You or We may terminate this agreement in writing immediately if:</li>

    <li>9.3.1 The Alarm Receiving Centre or the System is destroyed or badly damaged that we
    cannot reasonably provide the Services.</li>

    <li>9.3.2 We cannot arrange or keep the telecommunications or other communication facilities
    needed to transmit the signal between your Premises and the Alarm Receiving Centre.</li>

    <li>9.4 We may, without any prejudice to any other rights which we may have, either terminate
    this Agreement or suspend this Agreement for a period we consider appropriate if any of
    the following apply:</li>

    <li>9.4.1 You failed to make timeous payment of any amount due to us by you.</li>

    <li>9.4.2 You commit a breach of any of your obligations under this Agreement.</li>

    <li>9.4.3 If you die, application is made for sequestration, you enter into any kind of arrangement
    or scheme with your creditors or if a receiving order or administration order is made
    against you.</li>

    <li>9.4.4 If any legal proceedings are taken relating to the System or any part of the Premises.</li>

    <li>9.4.5 If you fail to follow any recommendations we make for repairing, replacing faulty or old
    parts of the System or for repairs to the Premises which we consider necessary for the
    system to work correctly, Or to prevent unnecessary false alarms.</li>

    <li>9.4.6 If you do not follow the instruction or if, for any other reason which is or ought to be
    within your control, there is an excessive (in our sole discretion) number of false alarms.</li>

    <li>9.4.7 If you change the Premises in such a way that we believe it is no longer viable to provide
    the Services.</li>

    <li>9.4.8 We may, without any prejudice to any other rights which we may have, terminate this
    agreement at our discretion.</li>

    <li>9.5 If we give you written notice of a suspension, such notice suspends what we are obliged
    to do under this Agreement and we have no obligation until the suspension is lifted by
    us. We will Inform you If we lift the suspension.</li>

    <li>9.6 At the termination of the Agreement we will stop providing the Services forthwith and we
    shall be entitled to remove the System if you have not completed your contractual
    period.</li>

    <li>9.7 You will be liable for the monthly payments and your debit order will remain active until
    such time that you allow Sniper to remove the rental radio transmitter and to disconnect
    your alarm system from communicating with our control room.</li>
  </ul>

  <h2>10. General</h2>
  <ul>
    <li>10.1 We are entitled to transfer all of our rights and obligations under this Agreement to
      another party and you hereby accept and approve any such transfer of rights and duties.
      In the event of any such transfer of our rights and obligations, all of our obligations to
      you under this Agreement shall automatically be discharged.</li>

      <li>10.2 We are at all times entitled to engage contractors or sub-contractors to carry out all or
      any of our obligations under this Agreement.</li>

      <li>10.3 If you enter into this Agreement together with any other person, you and such other
      person are both jointly liable and severally to us. If you are a tenant at the Premises, the
      Landlord shall become a party to this Agreement and the liability of you and the landlord
      to us shall be joint and several.</li>

      <li>10.4 We may pass on the information you have given to us under this Agreement to any
      authority and, except for security details, to any credit reference, debt collection or public
      telecommunications agency, or to any other organization for marketing purposes.</li>

      <li>10.5 Should you have taken a rent to own alarm package system , you are subject to a 36
      month contract.</li>

      <li>10.5.1 Rent to own alarm or special alarm packages remain the property of Sniper Security
      until fully paid for.</li>

      <li>10.6 Should you have taken the monitoring and response service only and provided your own
      alarm, this contract will be valid as per the contract of your choice only.</li>

      <li>10.7 We do not guarantee that we will be able to access your property during the course
      of our duties, nor will we be liable for damages during the course of a property inspection.</li>

      <li>10.7.1 This contract and the services rendered in terms hereof, will commence on the
      commencement date.</li>

      <li>10.7.2 You may only cancel the contract agreement if you have completed your contractual
      obligation period. You may not cancel your contract while you are within your contract
      cycle. Should you wish to cancel your contract agreement, this should be done 3 months:
      prior to the expiration date and done via registered post only. Failure to adhere to this
      clause your contract will renew itself automatically as per the contract of choice without
      notification.</li>

      <li>10.8 You choose as your domicilium citandi et executandi for all purposes under this
      Agreement, whether in respect of court process, notices or other documents or
      communications of whatsoever nature the address stated on page 1 of this document.</li>

      <li>10.9 You are not entitled to cede, delegate or otherwise transfer, your rights and obligations
      under this Agreement to any other party.</li>

      <li>10.10 If the court finds that any part of this Agreement is unenforceable but can be kept
      separate from the rest of the Agreement, this will not affect the remainder of this
      Agreement</li>

      <li>10.11 Should the company be sold the contract will be transferred to the new company and will
      not affect the remainder of the contract.</li>

      <li>10.12 Sniper Security has the right to remove your alarm system should you be in arrears with any payment, or breach the contract agreement until payment is made in full regardless of the alarm's ownership.</li>

      <li>10.13 All legal fees to be paid by the client should there be a breach of contract.</li>

      <li>10.14 Should you request courtesy checks exceeding 3 days, a minimum service fee will be
      deducted from the debit.</li>

  </ul>

  <h1>IMPORTANT</h1>
  <ul>
  <li>PREPAID TELEPHONE LINES CAUSE COMMUNICATION PROBLEMS WHEN ALARM SIGNALS ARE BEING TRANSMITTED VIA YOUR TELEPHONE LINE TO OUR BASE STATION.</li>

  <li>CHECK YOUR ALARM SYSTEM ON A WEEKLY BASIS.</li>

  <li>REPLACE YOUR ALARM BATTERY EVERY 12 MONTHS</li>

  <li>IT IS IMPERATIVE TO HAVE YOUR OUTDOOR MOTION SENSORS SERVICED QUARTERLY TO PREVENT INFESTATION WHICH CONTRIBUTES TO FALSE ACTIVATIONS.</li>

  <li>IT IS ADVISABLE TO REWIRE YOUR ALARM SYSTEM EVERY FOUR YEARS TO AVOID FALSE ALARMS DUE TO CABLE CORROSION OR DRY JOINTS IN THE SYSTEM.</li>

  <li>POWER FAILURES CAN CAUSE PROBLEMS WITH THE PROGRAMMING OF THE ALARM SYSTEM AND COMMUNICATION SIGNALS AS WELL AS CAUSE BATTERY DAMAGE.</li>

  <li>INCOMING TELEPHONE LINES “ONLY” ARE NOT TO BE USED FOR ALARM MONITORING. REPORT ANY TELEPHONE LINE PROBLEMS TO YOUR OFFICE AS SOON AS POSSIBLE.</li>

  <li>YOUR ALARM SYSTEM SHOULD BE SERVICED EVERY 6 MONTHS.</li>

  <li>IF YOU HAVE A PREPAID LINE WITH “NO AIRTIME”, NO ALARM SIGNALS WILL BE TRANSMITTED TO THE CONTROL ROOM.</li>

  <li>AFTER THE FIRST YEAR FROM THE START DATE AND YEARS FOLLOWING WE ARE ENTITLED TO INCREASE OUR CHARGES IN ORDER TO COVER ANY INCREASE IN THE COST OF PROVIDING THE SERVICES. OUR ANNUAL 10% INCREASE WILL TAKE PLACE AUTOMATICALLY WITHOUT NOTIFICATION.</li>

  <li>WE ARE NOT LIABLE FOR EXCESSIVE OR FAULTY TELEPHONE COMMUNICATION SIGNALS.</li>
  </ul>
</div>
