import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-action-panel",
  templateUrl: "./action-panel.component.html",
  styleUrls: ["./action-panel.component.css"],
})
export class ActionPanelComponent implements OnInit {
  @Input() titleStart: string;
  @Input() titleEnd: string;
  @Input() titleBreak: boolean;
  @Input() body: string;
  @Input() btnLink = "/forms/quote";

  constructor() {}

  ngOnInit(): void {}
}
