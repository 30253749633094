<div class="container-fluid">
   <!-- landing -->
   <app-panel
   [landing]='true'
   [titleStart]="'Business'"
   [titleEnd]="'Alarm Monitoring'"
   [titleBreak]='false'
   [subtitle]="'Our experts are on the job, so you don’t have to be.'"
   [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1.jpg?alt=media&token=3e0acb87-cc30-4f88-85f2-71cd2dd09119'|panelStyle)"
   >
 </app-panel>

 <div class="row page-row justify-content-center app-bg">
  <div class="col-10 text-center mt-5">
    <h1 class="text-center text-uppercase text-white">Artifical Intelligence
      <span class="accent-text">in action to protect your business</span></h1>
  </div>
  <div class="w-100"></div>
  <div class="col-md-7">
    <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FbusinessCCTV2.jpg?alt=media&token=9650f401-35ca-4721-b2d7-134ca536b218">
      <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FbusinessCCTV2.mp4?alt=media&token=a82b388b-ee24-4fac-8e73-890c5e18074b" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
  </div>

</div>

  <!-- 1 -->
  <app-panel
    titleStart="Our Monitoring controllers are ready to help"
    titleEnd="the moment they recieve an alarm activation."
    body="You are not at your premises 24/7 - but your alarm system is. You should expect a swift response that protects your staff, clients and property. In the case of an emergency, Sniper's alarm monitoring can help: when your <a class='link' routerLink='burlar-alarm-systems'>alarm system</a> ends an alert signal to our monitoring facility, professionally trained agents verify the alarm and immediately dispatch the tactical support services if necessary. With alarm monitoring, you no longer deal with emergencies alone. We work to understand your needs so that we can equip your business with the most  suitable <a class='link' href='commercial/alarm-system'>security system</a>. Our security consultants will work with you to establish the scope and complexity of the project, and then provide you with a clearly written estimate."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcall-center.jpg?alt=media&token=8678c15d-6f2d-451a-88ff-1d5a8e0ab2ab'
    |panelStyle)">
  </app-panel>

 <!-- 2 -->
 <app-panel
 [titleStart]='"alarm controllers will dispatch a tactical officer"'
 [titleEnd]='"when they verify a threat to your premises"'
 [alignment]='"end"'
 body="When our control room receives an alarm activation from your premises, our controllers will contact the individual identified as your designated point of contact. This will help us determine if an emergency is in progress, or if the alarm was accidentally tripped by you or a staff member. If the alarm is deemed an emergency, we immediately dispatch the tactical support services to your premises."
 [checkBoxPanel]="true"
 [checkBoxText]="'We constantly analyse false alarm activations as it negatively impacts on our emergency support resources. \
 False alarm activations affect the safety of all our clients we serve.'"
 [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Ftactical.JPG?alt=media&token=771f9194-c1d4-4db9-9a4b-17fa7a2226ca'|panelStyle:'end')"

>

  </app-panel>


  <!-- 3 -->
  <app-panel
  [titleStart]="'Sniper\'s'"
  [titleEnd]="'technical support service'"
  body="We have a technical department that can assist you with any alarm system issues that you experience. If you ever have any questions, our technical support staff is available from 9:00 AM to 4:00 PM, Monday to Friday AND Saturdays until 12 Midday. We are happy to assist if you have questions about your system, sensors, detectors, and/or monitoring service. We also have an ONLINE technical support service whereby you can book a technical call 24/7/365."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Ftechnical_support.jpg?alt=media&token=febf2ec0-a661-42d7-8bce-c5c9c7dc540b'|panelStyle)"
  >
  </app-panel>

  <!-- 4-->
  <app-faq-panel
    about="our alarm monitoring services"
    [faqs]='faq'
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Ffaq.jpg?alt=media&token=81fbba6e-d98d-42af-8c6c-70ec8a49a196'|panelStyle)">
  >
  </app-faq-panel>

</div>

