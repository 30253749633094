<div class="container-fluid">
  <app-panel
    [landing]='true'
    titleStart="Highly Skilled"
    titleEnd="Technical Support"
    body="<p><b>WE ARE PROUD OF OUR PROVEN TECHNICAL SUPPORT TRACK RECORD</b></p>
    <p>
      We provide a quality on-site technical support service to a wide range of businesses. We employ a team of highly skilled technicians that are fast, friendly and ready to offer you sound advice and technical support.   Our team boasts a wide array of skill sets, ranging from installations to fault finding. We maintain a high level of professionalism expected by our clients.
    </p>"
    [style]='("https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-com%2Ftechnician.jpg?alt=media&token=c3c96709-98a1-478f-96b7-d27f41cd148b"|panelStyle)'
  > </app-panel>

</div>
