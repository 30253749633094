import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-alarm-systems',
  templateUrl: './business-alarm-systems.component.html',
  styleUrls: ['./business-alarm-systems.component.css']
})
export class BusinessAlarmSystemsComponent implements OnInit {

  faq: {question: string; answer: string}[] = [];

  constructor() {
     this.faq = this.getFAQ();
   }

  ngOnInit(): void {
  }

  getFAQ() {
    return [
      {
        question: 'Will I receive email notifications when somebody arms or disarms my alarm system?',
        answer: `Yes you will receive an email alert. This feature is optional.`
      },
      {
        question: 'How loud is the alarm siren?',
        answer: `The alarm siren is designed to be loud enough to ward off intruders.\
        We can also install strobe lighting that works in conjunction with the alarm siren.`
      },
      {
        question: 'Can I arm and disarm the burglar alarm remotely?',
        answer: `Yes, but you will be required to purchase the App module, so that you can arm or disarm the alarm system.`
      },
      {
        question: 'Will the burglar alarm system still work in the event of a power outage?',
        answer: `The alarm system is equipped with a battery backup. \
        If you experience a power outage, the system will continue to operate for approximately up to 5 to 7 hours.`
      },
      {
        question: 'Can I test the functionality of the system?',
        answer: `Our technical department can put your alarm system into a test mode.\
        You can then trip / activate each alarm zone to test their functionality. \
        You can then request a status report to confirm your testing.`
      },

    ];
  }

}






























