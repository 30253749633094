export const environment = {
  production: true,
  dev: false,
  firebase: {
    apiKey: 'AIzaSyCHA4Utubdne5qej9aBJIPvVTNwDAKeKCU',
    authDomain: 'sniper-security-web.firebaseapp.com',
    databaseURL: 'https://sniper-security-web.firebaseio.com',
    projectId: 'sniper-security-web',
    storageBucket: 'sniper-security-web.appspot.com',
    messagingSenderId: '37095865222',
    appId: '1:37095865222:web:7dd9c958f1d7f1df314a07',
    measurementId: 'G-1L9ZZ0GWZ8'
  },

  app: {
    url: 'https://sniper-security-web.web.app',
    functionsURL: 'https://us-central1-sniper-security-web.cloudfunctions.net',
  }
};
