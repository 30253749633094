
<div class="container-fluid">

  <!-- 1 -->
  <app-panel
    [landing]='true'
    [titleStart]="'Cutting edge home security systems,'"
    [titleEnd]="'Backed by more than 25 years of experience'"
    body="Protect your home against intrusion by installing a Sniper Security system. We have perfected the art of security design to enhance your safety."
    mobileImage="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2F1m.jpg?alt=media&token=6f402733-c528-47e5-8c66-80983f3f4fc0"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2F1.jpg?alt=media&token=fb7731df-9b65-4250-adfa-c35cf47f3253'|panelStyle)"
  >

  </app-panel>

  <!-- 2 -->
 <app-review-box
  [customerReviews]="customerReviews"
  [title]="{start: 'Read customer reviews to learn how', end: 'sniper security is securing homes in your area'}"
  >
</app-review-box>


  <!-- 3 -->
  <app-offered-services serviceType='Home' [offered]='services'>
  </app-offered-services>

  <!-- 4 -->
  <app-panel
    [titleStart]="'Total Home Security'"
    [titleEnd]="'IS OUR GOAL'"
    body="Our team of highly skilled professionals can design and install a security system that complements your home's unique security needs. We take your safety seriously and recognize the value of a comprehensive technical and tactical support structure which instantly gives you and your family peace of mind."
    mobileImage='https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2F4m.jpg?alt=media&token=15783f48-a240-4d3c-87f0-ad4a3ebf6a0d'
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2F4.jpg?alt=media&token=8be23fb5-74c0-42d1-8df2-4dfa6c983dd8'|panelStyle)"
    >
  </app-panel>

  <!-- 5 -->
  <app-panel
    alignment="end"
    titleStart='Trust'
    titleEnd='is a big issue'
    body="When choosing a security service provider, trust and integrity is the cornerstone of your safety.
     40 percent of all home burglaries and home invasions are as a result of negligent employment vetting.
     We guarantee that our HR employment  architecture is built on solid trust."
     mobileImage='https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2F5m.jpg?alt=media&token=623ad1cc-6f0c-43c0-a1c6-1d608d38bbe4'
     [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2F5.jpg?alt=media&token=bbed1c31-b882-45ee-9d0b-96a7372b194e'|panelStyle)"
    >
  </app-panel>

  <!-- 6 -->
  <app-panel
    alignment="start"
    titleStart='The best security systems,'
    titleEnd='backed by the best guarantees.'
    body="Our industry leading home security systems come with a complete 12 months guarantee & 24-hour emergency repair service.
    If you ever need us, we'll be there."
    mobileImage='https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2F6m.jpg?alt=media&token=e0643727-8c62-4f47-b1e2-969ba5bf6b9e'
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2F6.jpg?alt=media&token=8609e1b9-de27-4376-b670-b23c28dfeca2'|panelStyle)"
  >
  </app-panel>

  <!-- 7 -->
  <app-action-panel
    titleStart="Security solutions custom designed"
    titleEnd="to suit your home needs."
    [titleBreak]="true"
    body="Our industry leading home security systems come with a complete 12 months guarantee & 24-hour emergency support service.<br>
      If you ever need us, we'll be there."
    [btnLink]="'/forms/quote'" >
  </app-action-panel>


</div>
