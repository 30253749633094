import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alarm-monitoring',
  templateUrl: './alarm-monitoring.component.html',
  styleUrls: ['./alarm-monitoring.component.css']
})
export class AlarmMonitoringComponent implements OnInit {

  faq: {question: string; answer: string}[] = [];

  constructor() {
     this.faq = this.getFAQ();
   }



  ngOnInit(): void {
  }

  getFAQ() {
    return [
      {
        question: 'What is the protocol when the alarm system is triggered?',
        answer: `Our typical protocol is to contact the designated point of contact. \
        If we determine there is a real threat, \
        we immediately dispatch the tactical support services to your premises.\
         If it is a false alarm activation, \
         we will cancel the tactical support services. \
         However, this process can be customized as per your\
         security needs. \
         You can also reach out to us at any time should you wish to make changes to your custom alarm response protocols.
        `
      },
      {
        question: 'How do you determine if an alarm is a false alarm?',
        answer: `Our standard protocol is to contact the individual on the keyholder list. \
        This person is most often the homeowner, \
        or someone else who is usually at the home who can determine if there is an emergency.  \
        We will inquire about the nature of the alarm, and from the information provided, \
        we will determine if the alarm activation is real or if it was accidentally triggered.`
      },
      {
        question: 'Will I be charged if emergency services are dispatched for a false alarm?',
        answer: `No, you will NOT be charged. \
        We will only charge you after multiple false alarm activations have taken place and you have ignored
         all our recommendations to address the matter. The benfit of Sniper's alarm monitoring service is that our technical support team
          will always make the neccesary recommendations to address false alarm activations.`
      },
      {
        question: 'Will Sniper train me how to use the system?',
        answer: `Sniper's technical experts will demonstrate the use of \
        the app so that you can get the most out of your home access \
        control and alarm systems. \
        A technician will teach you how to set up the system and \
        how to control the functions of your home security remotely.`
      },
      {
        question: 'Does the system still work if the power goes out?',
        answer: `Smart home devices are powered by battery backups
        and in the event of a power outage it will continue to work via the backup power supply.`
      },

    ];
  }

}
