<div class="row page-row review-bg align-items-center" [ngStyle]="bg">
  <div class="col-md-6">
      <div class="ms-lg-4 d-flex flex-column box app-bg-lighter shadow">
        <h3 class="text-uppercase">{{title.start}} <span class="accent-text">{{title.end}}</span></h3>

        <div class="d-flex flex-row align-items-start review-box w-100 pt-2">
          <span class="bi bi-quote accent-text me-2 display-5"></span>
          <p class="w-100">{{customerReviews[reviewIndex].message}}<br>
            <span class="accent-text mt-2 large-text"><b>{{customerReviews[reviewIndex].reviewer}}</b></span>
          </p>
        </div>

        <div class="mt-2">
          <span class="bi bi-caret-left-fill button" style="font-size: 1.5em; font-weight: lighter;" (click)="nextReview(false)"></span>
          <span class="bi bi-caret-right-fill button" style="font-size: 1.5em; font-weight: lighter;" (click)="nextReview(true)"></span>
        </div>

      </div>
  </div>
</div>
