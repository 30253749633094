import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gates',
  templateUrl: './gates.component.html',
  styleUrls: ['./gates.component.css']
})
export class GatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
