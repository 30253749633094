<div class="container-fluid">
  <app-panel
[landing]="true"
[accent]="'primary-text'"
titleStart="WHY CHOOSE"
titleEnd="SNIPER"
body="
<p>
<b class='primary-text'>Management Mantra.</b><br>
We live by a simple daily Mantra – Care, Speed Enthusiasm and Accountability.
</p>
<p>
  <b class='primary-text'>What You See Is What You Get.</b><br>
  We are constantly innovating and investing in our resources and this is evident in our brand. We are definitely not about smoke and mirrors. Our image is merely the tip of the iceberg.
</p>
<p>
  <b class='primary-text'>You Get What You Pay For.</b><br>
 Providing our clients with an exceptional service experience does not happen by accident.It happens by design.
 <br>We have a highly skilled team ready to meet your expectations. After all, quality service comes at a premium
</p>
<p>
  <b class='primary-text'>Get Results.</b><br>
  We have prevented many burglaries, which is extremely rewarding. But what our clients like most about us is our proactive communication and management style and the ability to achieve results.
</p>
<p>
  <b class='primary-text'>Uniqueness.</b><br>
  One of our favourite and most successful strength is honing in on each client’s unique security requirements. Every client has unique needs. We’re skilled at listening and tailoring a solution that meets your expectations and that best protects your premises and assets.
</p>
<p>
  <b class='primary-text'>Action Plan.</b><br>
  When it comes to your security, we believe that strategizing a ‘road map’ is a critical first step before spending any of your hard-earned money. We have found that clients that have a strategic security action plan are less likely to be burgled.
  </p>
<p><b class='primary-text'>Experience.</b><br>
   Our teams consist of seasoned experts with 10-25 years of experience.</p>
<p><b class='primary-text'>Attention To Detail.</b><br>
   We do it right from day one and do not cut corners.</p>
<p><b class='primary-text'>Technical Support.</b><br>
   Our clients have access to our 24/7 Online Technical support service. This keeps our technical support teams updated with all your technical related matters. We are committed to exceeding our client’s expectations.</p>
<p><b class='primary-text'>Strategic and Creative.</b><br>
   We take our time to gather information, listening attentively as clients tell us about their security related concerns. We then work tirelessly and put together a strategic security plan to help them manage and minimize their risk accordingly.</p>
Contact us now to experience a premium service.
"
[style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2FDSC01478.jpg?alt=media&token=f258eadb-e0af-45d8-84f2-a3706b7c6ae7'|panelStyle)"
>

</app-panel>
</div>
