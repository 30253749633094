<div class="container-fluid app-bg">
  <!-- 0 -->

  <app-panel
  titleStart="Access"
  titleEnd="Control."
  [titleBreak]='false'
  subtitle="We install specialist access control systems tailor made to your security needs."
  [landing]='true'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FAccess%20control%20787878.jpg?alt=media&token=82a9fa03-91ba-4de4-8272-ba17082e011d'
  |panelStyle: 'start': { 'background-color':'white', 'background-position':'right top 100px', 'background-size': 'contain'})">
  </app-panel>


  <!-- 1 -->
  <div class="row align-items-center justify-content-center">
    <div class="col-12 p-lg-2">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fthumbnails%2Fmobile-access-control.jpg?alt=media&token=e7b7965d-bbb2-4159-8038-debb506a4101">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fgate-acces-control-video.mp4?alt=media&token=cafbd851-7a82-400e-967d-a034812b6a1c" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>

  <!-- 2 -->
  <app-panel
  titleStart="Hard Wired"
  titleEnd="Gate Access Control"
  [titleBreak]="true"
  [alignment]='"end"'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fgate-access-control-keypad.jpg?alt=media&token=c0577a70-098c-43ef-896e-6243e34337cb'|panelStyle:'end')">
  </app-panel>

   <!-- 3 -->
  <div class="row align-items-center justify-content-center">
    <div class="col-12 p-lg-2">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fimage011.jpg?alt=media&token=7cc6413d-089f-446c-8f86-4c263950dbaa">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fhard-wired.mp4?alt=media&token=726c79b9-7577-453c-8360-b6c4c1f625aa" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>

</div>

