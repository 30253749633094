import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SecurityPackage } from "src/app/models/security-packages.model";
import { SecurityPackagesService } from "src/app/shared/services/security-packages.service";

@Component({
  selector: "app-tactical-security-packages",
  templateUrl: "./tactical-security-packages.component.html",
  styleUrls: ["./tactical-security-packages.component.css"],
})
export class TacticalSecurityPackagesComponent implements OnInit {
  packages: SecurityPackage[];
  showContractPrompt = false;
  packageVersion = "2";
  constructor(
    public securityPackages: SecurityPackagesService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.showContractPrompt = this.route.snapshot.params.id ? true : false;
    this.route.data.subscribe((data) => {
      this.packageVersion = data.version;
      this.packages = this.securityPackages.specialPackages();
    });
  }

  select(packageName: string) {
    if (packageName === "Pro Black") {
      this.router.navigate(["pro-black-application"]);
      return;
    }
    this.router.navigate(["/forms/contract", packageName], {
      queryParams: { packageVersion: this.packageVersion },
    });
  }
}
