<div class="container-fluid">
  <div class="row page-row align-items-center first">
    <div class="col-md-6">
        <div class="d-flex flex-column w-100 left-border-panel p-4 my-2 app-bg">
          <h1 class="primary-text">Sorry.</h1>
          <h2 class="primary-text">The page you are looking for cannot be found.</h2>
          <div>
            <p>Possible Reasons</p>
            <ul>
              <li>The address may have been typed incorrectly;</li>
              <li>It may be a broken or outdated link.</li>
            </ul>
          </div>
          <div class="d-flex flex-row align-items-center">
            <button role="button" class="button button-primary m-2 w-lg-50" routerLink="/">
              Home Page
            </button>
            <button role="button" class="button button-outline-primary m-2 w-lg-50" routerLink="/contact-us">
              Contact Us
            </button>

          </div>

        </div>
    </div>
  </div>
</div>
