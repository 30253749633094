<div class="container-fluid app-bg">
  <!-- landing -->
  <app-panel
    titleStart="AUTOMATIC LICENSE PLATE RECOGNITION"
    titleEnd="– LPR TECHNOLOGY"
    [landing]='true'
    body="Typical Applications for our National ANPR Solution:
    <ul>
      <li>Hotels, Hospitals, Airports, Valet Parking, Shopping Malls, Universities, Entertainment Centres, Toll Booths, Repo Companies, Police and more…</li>
      <li>Static Camera Points – A network of entry and exit cameras can provide true random monitoring of stolen, wanted or suspicious vehicles.</li>
      <li>ANPR can trigger a sequence of events. For example: a stolen or flagged vehicle can automatically trigger an alarm and notify a designated group.</li>
    <ul>"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Flpr.jpg?alt=media&token=d3541607-a829-4277-8d69-31d8c4943649'|panelStyle)">
  </app-panel>

</div>

