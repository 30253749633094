import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ip-intercom',
  templateUrl: './ip-intercom.component.html',
  styleUrls: ['./ip-intercom.component.css']
})
export class IpIntercomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
