import { Component, Input, OnInit } from '@angular/core';
import { BackgroundStyle } from '../../models/background-style.model';


@Component({
  selector: 'app-review-box',
  templateUrl: './review-box.component.html',
  styleUrls: ['./review-box.component.css']
})
export class ReviewBoxComponent implements OnInit {

  @Input() customerReviews: {message: string; reviewer: string}[] = [];
  @Input() title: {start: string; end: string} =
  {start: 'Read customer reviews to learn how ', end: 'sniper is providing security to homes and businesses in you area'};
  @Input()
  get image(){
    return this.bgImage;
  }

  set image(url: string){
    this.bgImage = url;
    this.bg = {'background-image': `url(${url})`};
  }

  // eslint-disable-next-line max-len
 bgImage = 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2Freviews.jpg?alt=media&token=b95ba029-cb1f-460e-9496-a836fb1c3040';


  // eslint-disable-next-line max-len
  bg: BackgroundStyle = {'background-image': `url(https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-res%2Freviews.jpg?alt=media&token=b95ba029-cb1f-460e-9496-a836fb1c3040)`};

  reviewIndex = 0;

  constructor() { }

  ngOnInit(): void {

  }


  /**
   * Changes the displayed review
   *
   * @param forward true if the 'forward' direction was selected
   *  false if the 'backward' direction selected
   */
  nextReview(forward: boolean){
    if (forward){
      this.reviewIndex = (this.reviewIndex + 1) >= this.customerReviews.length ? 0 : (this.reviewIndex + 1);
    }
    else{
      this.reviewIndex = (this.reviewIndex - 1) < 0 ? (this.customerReviews.length - 1) : (this.reviewIndex - 1);
    }
  }

}
