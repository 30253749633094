<div class="container-fluid">
  <div class="row page-row align-items-center first">
    <div class="col-md-6">
        <div class="d-flex flex-column w-100 left-border-panel p-4 my-2 app-bg large-text">
          <h1 class="display-4 accent-text">Payment Successful</h1>
          <p *ngIf="personaliseMessage">Hi {{name}},</p>
          <p>Your payment was successful.</p>
          <p>{{message}}
           </p>
          <a role="button" class="button button-outline-accent w-50 text-uppercase" routerLink="/">Go to home page</a>
        </div>
    </div>
  </div>
</div>
