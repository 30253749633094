<nav class="navbar navbar-dark app-bg-darker navbar-expand-lg p-0 fixed-top">
  <!-- Navbar content -->
  <a class="navbar-brand mx-auto mx-lg-0" routerLink="/home">
    <img
      src="assets/new-logo-w.png"
      class="logo"
      width="225"
      height="52.05"
      alt="Sniper Security Solutions"
      [ngClass]="screen.onMobile ? 'logo-small' : 'logo'"
    />
  </a>
  <button
    class="navbar-toggler me-1"
    type="button"
    aria-label="Toggle navigation"
    (click)="menuPressed.emit(true)"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <div appHover (hover)="onHoverChange($event)" *ngIf="!screen.onMobile">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-tabs">
        <li
          [ngbNavItem]="i + 1"
          *ngFor="let type of navTypes; let i = index"
          appHover
          (hover)="active = i + 1"
        >
          <a ngbNavLink [ngClass]="type.id === 'assist' ? 'fast-pulse' : ''">{{
            type.value
          }}</a>
          <ng-template ngbNavContent>
            <app-menu-panel [type]="type.id" *ngIf="type.id"></app-menu-panel>
          </ng-template>
        </li>

        <li ngbNavItem appHover (hover)="active = 0">
          <a ngbNavLink routerLink="/forms/contract">Sign Up</a>
        </li>
        <li ngbNavItem appHover (hover)="active = 0">
          <a ngbNavLink routerLink="/contact-us"> Contact Us </a>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="menu-body"></div>
    </div>
    <div class="ms-auto p-1">
      <a class="link" href="https://riskprofiler.co.za" target="_blank">
        <div
          class="d-flex flex-column align-items-center border rounded border-danger p-1 c-pulse"
        >
          <img src="assets/riskprofiler-logo.png" height="50px" alt="" />
          <div style="font-size: 0.7rem; text-decoration: none">
            Riskprofiler Certified
          </div>
        </div>
      </a>
    </div>
  </div>
</nav>
