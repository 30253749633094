<div class="container-fluid">
  <app-panel
    [titleStart]="'Choose'"
    [landing]="true"
    [titleBreak]="false"
    [titleEnd]="'The Professionals.'"
    body="<p>At Sniper Security, we may not be the largest security company in South Africa, but our reputation as the most trusted and ethical leader in the industry is unmatched. Our 28 years of experience have been built on a foundation of transparency, integrity, and accountability, with a management team that is obsessed with delivering exceptional customer experiences and exceeding expectations.</p>

<p>We don't just sell security products or services; we deliver value, peace of mind, and a commitment to doing what is right. Our expertise and passion for security are matched only by our dedication to building long-term relationships with our clients.</p>

<p>Choose the professionals who prioritize trust, honesty, and accountability. With Sniper, you can rest assured that your security needs are in capable and trustworthy hands. Experience the difference that trust, integrity, and a customer-centric approach makes.</p>
"
    [style]="'assets/images/1.jpg' | panelStyle"
  >
  </app-panel>

  <!-- 1 -->
  <app-panel
    [titleStart]="'Trust is our'"
    [titleEnd]="'currency'"
    body="<p>For nearly three decades, trust has been the cornerstone of our business. It's the glue that holds our relationships together, the spark that ignites our passion, and the driving force behind our success.</p>
<p>We have built our brand and reputation on a simple principle: trust is the only currency that matters.</p>
<p>We don't just talk trust - we live it. Every day, every interaction, every promise kept. Our consistency is our bond, our reliability is our strength, and our commitment is our signature.</p>
<p>Trust is not just a core value, it is the very essence of who we are. We are so committed to it that we are willing to stake our reputation on it, every time, every day.</p>"
    [style]="'assets/images/2.jpg' | panelStyle"
  >
  </app-panel>

  <app-panel
    [titleStart]="'We Try'"
    [titleEnd]="'Harder'"
    body="<p>Warning: We're not your average security company. We're a team of rebels who refuse to settle for mediocre. WE TRY HARDER to deliver protection that's not just premium, but revolutionary. Our clients expect the impossible, and we deliver.</p>

<p></p>

<p>We're obsessed with satisfaction that borders on addiction. Our customers' experiences are our drug of choice. We'll stop at nothing to blow their minds, push boundaries, and redefine the limits of service excellence.</p>

<p></p>

<p>If you're ready to join the revolution, if you're ready to experience security that's not just a service, but an obsession, then join us. But be warned: once you experience the Sniper difference, there's no going back.</p>"
    [style]="'assets/images/3.jpg' | panelStyle"
  >
  </app-panel>

  <app-panel
    [titleStart]="'Tactical'"
    [titleEnd]="'Support'"
    [alignItems]="'align-items-start'"
    [showButtons]="false"
    [style]="'assets/images/ranger.jpg' | panelStyle"
  >
  </app-panel>

  <app-panel
    titleStart="TECHNICAL SUPPORT"
    titleEnd="THAT SETS THE BAR"
    body="<p>We're not just technical support specialists - we're the game-changers. Our track record speaks for itself: we've consistently delivered top-tier support that's second to none.</p>

<p>Our team of rockstar technicians is the best in the business. They're fast, friendly, and ready to tackle even the toughest challenges. From installations to fault finding, our team's diverse skill sets ensure that no problem is too big or too small.</p>

<p>We don't just provide technical support - we set the standard. Our clients expect the highest level of professionalism, and we deliver. We're not just a technical support team - we're the ones who redefine what's possible.</p>"
    [style]="'assets/images/technician.jpg' | panelStyle"
  >
  </app-panel>

  <app-review-box
    [customerReviews]="reviews"
    [title]="{
      start: 'Learn how Sniper Security',
      end: 'is securing homes in your area'
    }"
  >
  </app-review-box>

  <!-- 3 -->
  <app-panel
    [titleStart]="'security'"
    [titleEnd]="'redefined'"
    [alignment]="'end'"
    [titleBreak]="false"
    body="<p>Tired of one-size-fits-all security solutions? Our experts will craft a bespoke security strategy that's as unique as your fingerprint. We'll then back it with:</p>

    <ul>
      <li>Lightning-fast response times</li>
      <li>Technical support that's always on call</li>
      <li>Proactive management that anticipates threats before they strike</li>
    </ul>

<p>We're not just security specialists - we're game-changers. Our solutions are designed to disrupt the status quo and give you the upper hand. Expect nothing but bold, daring, and uncompromising security that redefines the norm.</p>"
    [style]="'assets/images/security-specialist.jpg' | panelStyle : 'end'"
  >
  </app-panel>

  <!-- 4 -->
  <app-panel
    [alignment]="'start'"
    [titleStart]="'Uncompromising'"
    [titleEnd]="'Excellence'"
    [titleBreak]="false"
    body="<p>We don't just train our team members - we transform them into safety champions who care about your well-being. Over 2,000 hours of intense training ensures they're equipped to exceed your expectations and provide guidance that's second to none.</p>

<p>We take ownership of your security, because we take it personally. Our team members are empowered to make decisions, take action, and build relationships that matter. We don't just serve clients - we partner with them, as individuals.</p>

<p>Expect nothing but the highest level of personalized service from us. We're not just a security company &ndash; we are your trusted partners in protection.</p>"
    [style]="'assets/images/personalised-service.jpg' | panelStyle"
  >
  </app-panel>
</div>
