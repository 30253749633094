<div class="container-fluid app-bg">
  <!-- landing -->
  <app-panel
    titleStart="Live CCTV"
    titleEnd="On your mobile"
    [subtitle]="'A massive benefit to business owners'"
    [landing]='true'
    body="Business owners can now access their CCTV cameras via Snipers unique surveillance app 24/7 at the click of a button. This allows you to conduct virtual surveillance of your premises if the alarm goes off, or to conduct random virtual security checks. LIVE CCTV surveillance is an additional layer of security and gives business owners the comfort of not being required to come out to the premises in the event of an alarm activation. Our surveillance solution prevents you from a face-to-face encounter with criminal elements that could place you at risk. Our surveillance specialists will provide you with sound professional advice regarding the capabilities of our CCTV solution."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Flive-cctv-bus.jpg?alt=media&token=ef434396-a981-4158-a325-6387ab15a0d0'|panelStyle)">
  </app-panel>




</div>

