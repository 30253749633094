import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResidentialServicesRoutingModule } from './residential-services-routing.module';
import { ResidentialServicesComponent } from './residential-services.component';
import { SharedModule } from 'src/app/shared/shared.module';

import { AllResidentalServicesComponent } from './all-residential-services/all-residental-services.component';
import { AlarmMonitoringComponent } from './alarm-monitoring/alarm-monitoring.component';
import { BurglarAlarmSystemsComponent } from './burglar-alarm-systems/burglar-alarm-systems.component';
import { AccessControlComponent } from './access-control/access-control.component';
import { TacticalResponseComponent } from './tactical-response/tactical-response.component';
import { SecurityCamerasComponent } from './cctv/security-cameras.component';
import { ElectricFencingComponent } from './electric-fencing/electric-fencing.component';
import { IpIntercomComponent } from './ip-intercom/ip-intercom.component';
import { OutdoorSensorsComponent } from './outdoor-sensors/outdoor-sensors.component';
import { GatesComponent } from './gates/gates.component';
import { CyberguardCommunityComponent } from './cyberguard-community/cyberguard-community.component';
import { TechnicalSupportComponent } from './technical-support/technical-support.component';
import { AlarmAppComponent } from './alarm-app/alarm-app.component';
import { AccessAppComponent } from './access-app/access-app.component';



@NgModule({
  declarations: [
    ResidentialServicesComponent,
    AllResidentalServicesComponent,
    AlarmMonitoringComponent,
    BurglarAlarmSystemsComponent,
    AccessControlComponent,
    TacticalResponseComponent,
    SecurityCamerasComponent,
    ElectricFencingComponent,
    IpIntercomComponent,
    OutdoorSensorsComponent,
    GatesComponent,
    CyberguardCommunityComponent,
    TechnicalSupportComponent,
    AlarmAppComponent,
    AccessAppComponent

  ],
  imports: [
    CommonModule,
    ResidentialServicesRoutingModule,
    SharedModule
  ]
})
export class ResidentialServicesModule { }
