<div class="container-fluid app-bg">

  <!-- landing -->
  <app-panel
    [titleStart]="'Alarm Monitoring'"
    [titleEnd]="'& CCTV Integration'"
    [landing]="true"
    body="Sniper Security has one of the most advanced alarm and CCTV monitoring control rooms in the Western Cape.
    With our next generation live integrated camera mapping technology our control room now plays a proactive role in your safety,
    as they are now able to engage visually and audibly with intruders."
    mobileImage="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1m.jpg?alt=media&token=384d636e-306b-4961-bd4e-ec3de7567aba"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1.jpg?alt=media&token=3e0acb87-cc30-4f88-85f2-71cd2dd09119'|panelStyle)"
    >
  </app-panel>

  <div class="row page-row justify-content-center app-bg">
    <div class="col-lg-10">
      <h1 class="text-center display-5 my-lg-2 text-uppercase text-white">Artifical Intelligence
        <span class="accent-text">in action</span> to protect your home.
      </h1>
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fvlcsnap-2020-12-01-17h37m44s481.png?alt=media&token=78842e55-655f-448f-92fa-4a2db6d8494f">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FAcuSense%20Products%20for%20Residences.mp4?alt=media&token=b447ee81-931e-477b-8790-b1bb30c9ce28" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>

  <!-- 1 -->
  <app-panel
    [titleStart]="'Our Monitoring controllers are ready to help'"
    [titleEnd]="'the moment they recieve an alarm activation'"
    body="You are not at home 24/7 - but your alarm system is. You should expect a swift response that protects your home, loved ones and belongings in the case of an emergency. When your alarm system sends an alert signal to our control room, professionally trained controllers verify the alarm and immediately dispatch the tactical support services to your premises if necessary. With alarm monitoring, you no longer have to deal with emergencies alone."
    mobileImage="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F3m.jpg?alt=media&token=478d9802-8d39-4c97-8664-10dc847e34ce"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F3.jpg?alt=media&token=bff0807c-e4ef-4dee-87e2-ce6d94550418'|panelStyle)"
    >
  </app-panel>


  <!-- 2 -->
  <app-panel
    [titleStart]='"alarm controllers will dispatch a tactical officer"'
    [titleEnd]='"when they verify a threat to your home"'
    [alignment]='"end"'
    body="When our control room receives an alarm activation from your home, our controllers immediately dispatch our tactical support services to your home and then call the individual identified as your designated point of contact. This helps us determine if an emergency is in progress, or if the alarm was accidentally tripped by you or a family member."
    [checkBoxPanel]="true"
    [checkBoxText]="'We constantly analyse false alarm activations as it negatively impacts on our emergency support resources. False alarm activations affect the safety of all clients.'"
    mobileImage="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F4m.jpg?alt=media&token=2f4d9344-c1b7-4543-ac28-04e6cd7e2ba0"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F4.jpg?alt=media&token=778e83ea-7361-4cd8-b445-fa52c7b3f40b'|panelStyle:'end')"

  >

  </app-panel>


 <!-- 3 -->

  <app-panel
    [titleStart]="'Sniper\'s'"
    [titleEnd]="'technical support service'"
    body="We have a technical department that can assist you with any alarm system issues that you might experience. If you ever have any questions, our technical support staff is available from 9:00 AM to 5:00 PM, Monday to Friday AND Saturdays 9 to 1PM. We are happy to assist you if you have any questions about your system or monitoring service. We also have an ONLINE technical support service to ensure that you can log a tech support call 24/7."
    mobileImage="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F5m.jpg?alt=media&token=2a8097ec-487d-4ea7-9f20-84cc4c1d290d"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F5.jpg?alt=media&token=212cac65-70c9-49af-95c2-e8d4d7b72f8f'|panelStyle)"
    >
  </app-panel>

  <!-- 4-->
  <app-faq-panel
  about="our alarm monitoring services"
  [faqs]='faq'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F6.jpg?alt=media&token=94d633ef-2346-4d0a-83f4-f3efbba69cb1'|panelStyle)">
  >
  </app-faq-panel>

  <app-action-panel
    [titleStart]="'Get A'"
    [titleEnd]="'Quote'"
    body="Our security products are built with you and your home-in mind.
    Our security experts will consult, design and install a system that allows you to realize the
    benefits of a comprehensive home automation system."
    [btnLink]="'/forms/quote'"
    >
  </app-action-panel>

</div>
