<mat-sidenav-container class="side-nav app-bg">

  <mat-sidenav #sidenav [mode]="'over'" [fixedInViewport]="true" class="app-bg-darker">
    <app-side-nav (closeNav)="sidenav.close()"></app-side-nav>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-navbar (menuPressed)="sidenav.toggle()"></app-navbar>
    <div class="main-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
<app-footer></app-footer>
