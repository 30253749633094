import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-terms',
  templateUrl: './contract-terms.component.html',
  styleUrls: ['./contract-terms.component.css']
})
export class ContractTermsComponent implements OnInit {


  /**
   * If true, component is added as 400px box with scrolling
   */
  @Input() box = false;
  constructor() { }

  ngOnInit(): void {
  }

}
