<div class="container-fluid">
  <app-panel
    [titleStart]="'RECRUITMENT AND'"
    [titleEnd]="'BEST PRACTICE CONTROLS'"
    [subtitle]="'UNRIVALED EXPERTISE MEETS UNCOMPROMISING STANDARDS'"
    body="<p>In an industry where trust is everything, Sniper Security raises the bar with a transformative recruitment process that redefines excellence. Our unwavering commitment to excellence is fueled by a ground breaking partnership with the University of Cape Town's Criminology Department, ensuring our expertise is unparalleled.</p>
<p>Our zero-tolerance policy towards negligence and corruption is more than just a stance - it's a promise to our clients. We leave no stone unturned in our rigorous selection process, guaranteeing only the most trustworthy and reliable candidates join our elite team.</p>
<p>As South Africa's leading security company, we pride ourselves on our innovative approach to safety. Our staff undergo cutting-edge background screening, are Riskprofiler certified, and continuously tracked to ensure unwavering safety standards. Our operational systems are designed to exceed service delivery and safety expectations.</p>
<p>With Sniper Security, trust is more than just a word - it's a promise backed by unbeatable expertise, innovative technology, and a relentless commitment to excellence. Your safety is in expert hands.</p>"
    [style]="
      'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fabout%2Frecruitment.jpg?alt=media&token=9278dfd4-d069-46ce-92ef-7aafaf93a620'
        | panelStyle
    "
  >
  </app-panel>
</div>
