<div class="container-fluid">
  <!-- 1 -->
  <app-panel
    [titleStart]="'Cyber Guard'"
    [titleEnd]="'Community Surveillance'"
    [landing]="true"
    body="We know that a physical guarding solution is quite costly and unfortunately some of the guards eventually become complacent. Our advanced surveillance technology enables communities to play an active role in community safety."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcyberguard_man.png?alt=media&token=c35c26b3-c0d3-4d80-8245-8801e17dbe60'|
    panelStyle:'start': { 'background-position': 'top right 200px', 'background-size': 'contain'})"
    >
  </app-panel>

  <!-- 2 -->
  <app-panel
    [titleStart]="'LIVE'"
    [titleEnd]="'STREET SURVEILLANCE TECHNOLOGY'"
    [titleBreak]='false'
    body="The Cyber Guard solution enables our control room to conduct more frequent visual checks in your community and enables them to engage audibly with suspects engaged in suspicious activity."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcontrol-room-logo.jpg?alt=media&token=39436fc8-efe4-4864-9d96-c03cd4a31a95'|panelStyle)"
    >
  </app-panel>

  <!-- 3 -->
  <app-panel
    [titleStart]="'Cyber Guard'"
    [titleEnd]="'REAL-TIME CCTV SURVEILLANCE'"
    [alignment]="'end'"
    body="We strategically install our advanced analytical surveillance Cyber Guard Camera Systems in your community and enable the residents to monitor the streets from their mobile phones from the comfort of their homes or anywhere in the world."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FHikvision%20CCTV%20Surveillance%201.jpg?alt=media&token=259f5b55-502c-40c2-af36-803946ba5d3d'|panelStyle:'end')"
    >
  </app-panel>

  <!-- 4 -->
  <app-panel
    [titleStart]="'Deep Learning'"
    [titleEnd]="'Technology'"
    [titleBreak]='false'
    body="Our deep learning technology plays an important role in community safety. Our team will design a surveillance solution that will proactively keep your community safe and informed of loitering 24/7."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FdeepLearning.jpg?alt=media&token=71b87195-c64f-4a31-97a3-9f9b7ca50ff9'|panelStyle)"
    >
  </app-panel>

  <app-panel
  [titleStart]="'Video Surveillance'"
  [titleEnd]="'Architecture'"
  [titleBreak]='false'
  body="We map, design and create a solution that best suits your community. Our Cyber Guard solution is installed in many communities and has proven to be a proactive crime deterrent."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fdesign.jpg?alt=media&token=fb832010-7499-4aca-9dc4-544b0f862650'|panelStyle)"
  >
</app-panel>

<div class="row page-row justify-content-center app-bg">
  <div class="col-10 text-center mt-5">
    <h1 class="text-center text-uppercase text-white mb-4">Cyber Guard<br><span class="accent-text">in Action</span></h1>
  </div>
  <div class="w-100"></div>
  <div class="col-md-8 mb-2" *ngFor="let video of videos">
    <h3 class="text-center text-uppercase text-white">{{video.title}}</h3>
    <video class="page-video mb-4 p-2" controls type="video/mp4" [src]="video.url">
      Your browser does not support HTML5 video.
    </video>
  </div>

</div>

  <!-- 6 -->
  <div class="row page-row p-2 align-items-center justify-content-center app-bg">
    <div class="col-md-7 justify-content-center text-center">
      <h2 class="text-uppercase accent-text display-4">contact us <span class="text-white">today</span></h2>
      <p class="large-text">We are committed to offer a professional, responsive, value-added video surveillance service with a Sniper touch. We are a locally owned company
        based in the Southern Suburbs. We work with communities to develop proactive CCTV surveillance solutions.
      </p>
      <a routerLink="/forms/quote" class="btn app-bg-danger text-uppercase" role="button">Request a quote</a>
    </div>
  </div>

</div>
