import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emergency-guarding',
  templateUrl: './emergency-guarding.component.html',
  styleUrls: ['./emergency-guarding.component.css']
})
export class EmergencyGuardingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
