<div class="container-fluid py-2">
  <div class="row">
    <div class="col-12 text-center">
      <h1 class="text-white text-uppercase my-2 display-5"><span class="accent-text">Click and Choose</span > A Security Package</h1>
        <p *ngIf="showContractPrompt">Please select a security package before proceeding.</p>
    </div>
    <div class="col-12 parent">
        <div class="child" *ngFor="let package of packages">
          <div class="card pkg text-center zoom" [ngStyle]="package|bgImage" (click)="select(package.title)">
            <img [src]="package.header">
            <div class="card-body d-flex flex-column">
              <p class="display-7"><b>{{package.cost}}</b></p>
              <p>(Residential)</p>
              <div class="text-start mb-2">
                <p *ngFor="let benefit of package.benefits" [innerHTML]="benefit"></p>
              </div>
              <div>
                <img [src]="package.footer" alt="">
              </div>
              <p *ngIf="package.footerText" class="footerText">{{package.footerText}}</p>
              <!-- <a class="btn btn-light btn-lg btn-block text-capitalize button mt-auto" role="button"><h4 style="text-decoration: underline;" [ngStyle]= "{'text-decoration-color': package.color}">{{package.cost}}</h4></a> -->
            </div>

          </div>

        </div>
      </div>
  </div>
</div>
