import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { OffersService } from 'src/app/shared/services/offered-services.service';
import { Router } from '@angular/router';
import { ScreenService } from 'src/app/shared/services/screen.service';
import { MatAccordion } from '@angular/material/expansion';
import { OfferedService } from 'src/app/shared/models/offered-service.model';
import { NavService } from '../nav.service';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {


  @ViewChild('acc') accordion: MatAccordion;

  @Output() closeNav = new EventEmitter<any>();

  year = new Date().getFullYear();


  residential: OfferedService[] = [];
  commercial: OfferedService[] = [];
  assist: OfferedService[] = [];
  about: OfferedService[] = [];

  constructor(public services: OffersService, public router: Router, screenService: ScreenService, nav: NavService) {

      screenService.navigation.subscribe(event => {
      this.closeNav.emit(event);
    });



  }

  ngOnInit(): void {
    this.residential = this.services.residential;
    this.commercial = this.services.commercial;
    this.assist = this.services.assist;
    this.about = this.services.about;
  }


}
