/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cctv-systems',
  templateUrl: './cctv-systems.component.html',
  styleUrls: ['./cctv-systems.component.css']
})
export class CctvSystemsComponent implements OnInit {

  serviceCards: {title: string; message: string; image: string}[] = [];

  constructor() { }

  ngOnInit(): void {
    this.setUpCards();
  }

  //#region Service Cards
  private setUpCards(){
    this.serviceCards.push(
      {
        title: 'LPR- License Plate Recognition',
        message: `LPR systems can compare license plates to a database housing vehicles of interest.
         It can also be used to identify vehicles of interest to local security companies or neighbourhood watches,
         such as stolen vehicles or those suspected of being involved in  a crime`,
        image: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FHikvision%20LPR.jpg?alt=media&token=62c32483-b9e5-4162-84a7-fb0890cf90ed',
      },
      {
        title: 'CCTV Audio Recording',
        message: `The Hikvision IP network camera is the perfect surveillance technology \
        to record all audio conversations within the monitored area.
        It is perfect for environments that require that extra edge of security.`,
        image: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FHikvision%20Audio%20Camera.jpg?alt=media&token=6278503e-1887-4d04-ac54-bde795328ffe',
      },

      {
        title: 'PTZ Cameras - Pan Tilt Zoom',
        message: `A pan-tilt-zoom (PTZ) camera works by moving the camera in different directions \
        to get a whole picture of the surveillance area
        and zooming in for further details of security events. with Hikvision's PTX cameras, you can rapidly respond to security events,
        and leave no blind spot unnoticed. It is the perfect solution for high security risk areas.`,
        image: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FHikvision%20PTZ%202.jpg?alt=media&token=7cbb7897-1399-4740-8ec8-5e46660cd831',

      },

    );
  }

  //#endregion

}
