import { Pipe, PipeTransform } from '@angular/core';
import { BackgroundStyle } from '../../models/background-style.model';


/*
 * Creates a full image background style given an image
 * Takes other background properties as arguments.
*/
@Pipe({
  name: 'panelStyle'
})
export class BackgroundPipe implements PipeTransform {

  constructor(){

  }
  transform(image?: string, align?: 'start'|'end', customStyle?:
  BackgroundStyle): BackgroundStyle {
    return this.createStyle(image, align, customStyle);
  }



  /**
   *
   * @param img the background image to use
   * @param align alignment of the overlay. must be start or end.
   * @param customStyle custom properties for a background image
   */
   createStyle(img?: string, align?: 'start'|'end', customStyle?:
    BackgroundStyle){
    // Set to empty string if no image provided (background color will be used when image is not found);
    img = img ? img : '';

    customStyle = customStyle ? customStyle: {} ;
    const style = {
      'background-image': customStyle['background-image'] || `linear-gradient(90deg, #000000cc, transparent), url(${img})`,
      'background-size': customStyle['background-size'] ? `cover, ${customStyle['background-size']}` : 'cover',
      'background-position': customStyle['background-position'] ?
      `center, ${customStyle['background-position']}` : 'center,center top',
      'background-repeat': customStyle['background-repeat'] ||'no-repeat',
      'background-color': customStyle['background-color'] || ' var(--bg-color)'
     };
    if (align && align === 'end'){
      style['background-image'] = `linear-gradient(90deg, transparent,  #000000cc), url(${img})`;
    }
    return style;
  }


}
