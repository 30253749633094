import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavService } from 'src/app/navbar/nav.service';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { DatabaseService } from 'src/app/shared/services/database.service';

@Component({
  selector: 'app-general-enquiry',
  templateUrl: './general-enquiry.component.html',
  styleUrls: ['./general-enquiry.component.css']
})
export class GeneralEnquiryComponent implements OnInit {

  enquiryForm: UntypedFormGroup;
  loading = false;

  enquiryTypes = [
    'ARMED RESPONSE',
    'CONTROL ROOM',
    'TECHNICAL',
    'ACCOUNTS',
    'SALES',
    'GUARDING',
    'GENERAL ENQUIRY',
    'MANAGEMENT',
    'COMPLAINT',
    'COMPLIMENT',
    ];

  constructor(public fb: UntypedFormBuilder,  public db: DatabaseService,
    public modal: NgbModal, public router: Router, public nav: NavService) { }

  ngOnInit(): void {
    this.enquiryForm = this.fb.group(
      {
        name: ['',Validators.required],
        surname: ['', Validators.required],
        businessName: ['', Validators.required],
        email: new UntypedFormControl('',[Validators.required, Validators.email]),
        contactNumber: new UntypedFormControl('',[Validators.required, Validators.minLength(6)]),
        subject: ['', Validators.required],
        message: ['', Validators.required],
        type: ['', Validators.required]
      }
    );

  }


  async submit(){
    this.loading = true;

    const formValue = this.enquiryForm.value;
    this.db.saveEnquiryForm(formValue).then( () => {
      this.showDialog('Submission Complete',
      '<p>Your form has been submitted successfully. </p>\
      <p>Thank you for your enquiry.<br>\
      We will respond as soon as possible.<br>\
      If you do not hear from us within 48 hours, kindly give us a call at \
      021 697 0573 as your enquiry may not have reached us.').result.then(() => this.router.navigate(['home']));
    }
    ).catch((error)=> {
      console.log(error);
      this.showDialog('Submission Error',
      'There was an error submitting your form. Please try again later or email <a class="link accent-text"\
      href="mailto:info@snipersecurity.co.za">info@snipersecurity.co.za</a>')
      .result.then(() => this.router.navigate(['home']));
    }).finally(()=> this.loading = false);

  }

  showDialog(title?: string, body?: string){
    this.modal.dismissAll();
    const message = {
      title,
      body,
    };
    const modalRef = this.modal.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.buttons = {ok: 'Continue'};

    modalRef.componentInstance.actionRequired = false;

    return modalRef;
  }

  assist(){
    this.nav.select();
  }

}
