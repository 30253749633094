import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/services/storage.service';
import { AlertModalComponent } from 'src/app/shared/components/alert-modal/alert-modal.component';
import { DatabaseService } from 'src/app/shared/services/database.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {

  careerForm: UntypedFormGroup;
  cv: File;
  id: File;
  loading = false;
  constructor(public fb: UntypedFormBuilder, public storage: StorageService, public db: DatabaseService,
    public modal: NgbModal, public router: Router) { }

  ngOnInit(): void {
    this.careerForm = this.fb.group(
      {
        name: ['',Validators.required],
        surname: ['', Validators.required],
        email: new UntypedFormControl('',[Validators.required, Validators.email]),
        contactNumber: new UntypedFormControl('',[Validators.required, Validators.minLength(6)]),
      }
    );

  }

  fileSelect(event, attachment: 'cv'|'id'){
    const file = event.target.files[0];
    if(!this.storage.verifyFileSize(file,5)){
      window.alert(`The file you have selected is too large (${5} MB). File size should not be larger than ${5} MB`);
      return;
    }
    if(attachment === 'cv') {this.cv = file;}
    else { this.id = file;}
  }

  async submit(){
    this.loading = true;
    const cvFileName = this.storage.getRandomName('.pdf');
    const cvUrl = await this.storage.upload(this.cv, `careers/${cvFileName}`);
    const idFileName= this.storage.getRandomName(this.storage.getExtension(this.id.name));
    const idUrl = await this.storage.upload(this.id, `careers/${idFileName}`);

    const formValue = {...this.careerForm.value, cv: {name: this.cv.name, url: cvUrl}, id:  {name: this.id.name, url: idUrl}};
    this.db.saveCareerForm(formValue).then( () => {
      this.showDialog('Submission Complete',
      '<p>Your form has been submitted successfully. </p>\
      <p>Thank you for your interest in a career with Sniper Security Solutions.\
      You will hear from us as soon as there is a suitable vacancy.</p>').result.then(() => this.router.navigate(['home']));
    }
    ).catch((error)=> {
      console.log(error);
      this.showDialog('Submission Error',
      'There was an error submitting your form. Please try again later or email <a class="link accent-text"\
      href="mailto:info@snipersecurity.co.za">info@snipersecurity.co.za</a>')
      .result.then(() => this.router.navigate(['home']));
    }).finally(()=> this.loading = false);

  }

  showDialog(title?: string, body?: string){
    this.modal.dismissAll();
    const message = {
      title,
      body,
    };
    const modalRef = this.modal.open(AlertModalComponent, { centered: true });
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.buttons = {ok: 'Continue'};

    modalRef.componentInstance.actionRequired = false;

    return modalRef;
  }

}
