import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionCollection, SubscriptionManager } from 'src/app/models/subscription-manager';

@Component({
  selector: 'app-accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.css']
})
export class AcceptComponent implements OnInit, OnDestroy {

  formName: string;
  name: string;
  personaliseMessage = false;
  subscriptions: SubscriptionCollection = {};
  message ='One of our consultants will be in touch with you as soon as possible.';

  constructor(public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.subscriptions.routeParams = this.route.queryParamMap.subscribe(params => {
      this.formName = params.get('form');
      if(this.formName.toLowerCase().includes('special')){
        this.message = 'Our technical department will call you shortly to arrange the installation date.';
      }
      this.name = params.get('name');
      if(this.formName && this.name){
        this.personaliseMessage = true;
      }
      else { this.personaliseMessage = false;}
    });
  }

  ngOnDestroy(): void {
    SubscriptionManager.unsubscribe(this.subscriptions);
  }

}
