<div class="container-fluid app-bg">
  <!-- 0 -->
  <app-panel
  titleStart="outdoor sensor"
  titleEnd="protection"
  [titleBreak]='false'
  subtitle="Early warning detection minimises your risk."
  body="Outdoor sensors are a critical part of your safety barrier and must be implemented in the architecture of your perimeter protection which will instantly give you peace of mind."
  [landing]='true'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FOutdoor%20Sensor.png?alt=media&token=59494190-11f5-4564-895f-8928a2506e49'|panelStyle)">
  </app-panel>


   <!-- 3 -->
   <div class="row page-row justify-content-center app-bg">
    <div class="col-10 text-center mt-5">
      <h1 class="text-center text-uppercase text-white">check out how our outdoor sensors can help<br><span class="accent-text">protect your property</span></h1>
    </div>
    <div class="w-100"></div>
    <div class="col-md-8">
      <video class="page-video m-2" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvlcsnap-2020-12-02-15h01m21s215.png?alt=media&token=625213ed-94a4-4676-93aa-7f733e34a7c7">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FMS-12FE%20-%20180%C2%B0%20Detection%20(Instructional%20Video).mp4?alt=media&token=333e37c9-8d81-4ecf-b25f-958fb9beb287" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
    <div class="col-md-8">
      <video class="page-video m-2" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fsensor_v_2.png?alt=media&token=3226b058-cf26-4725-a0df-2bf369219c86">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FNV780M%20Series.mp4?alt=media&token=f8f3ceac-140f-4733-8d9d-c175152e02f4" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
    <div class="col-md-8 m-2">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvlcsnap-2020-12-02-15h02m58s707.png?alt=media&token=3c1d7c7c-7e64-4ed0-9168-1c1092e8f835">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FLet%20Them%20Run%20Wild.mp4?alt=media&token=7d9a7edd-28d7-457c-9552-f6d8b644f4ab" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
    <div class="col-md-8">
      <video class="page-video m-2" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvlcsnap-2020-12-02-15h02m08s297.png?alt=media&token=8a434d7a-fbcc-4e1a-8dd7-a956db6418e8">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FQX%20Infinity%20Series%20-%20Wide%20Angle%20Outdoor%20Detector.mp4?alt=media&token=271bcfde-0fa6-44e9-a045-8e5cdd87b3f3" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>



</div>

