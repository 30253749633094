/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-luxury-armoured-vehicles',
  templateUrl: './luxury-armoured-vehicles.component.html',
  styleUrls: ['./luxury-armoured-vehicles.component.css']
})
export class LuxuryArmouredVehiclesComponent implements OnInit {

  images = [
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2FBMW1.jpg?alt=media&token=ca19710c-53ec-4a33-a2c6-7cbcae5765ce',
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2FBMW2.jpg?alt=media&token=f27cc1f5-991d-47f5-bf3a-80dab787dd1c',
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2FBMW3.jpg?alt=media&token=d55c01d5-98eb-4e36-bcbd-f492bac16288',
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2FBMW4.jpg?alt=media&token=d902165a-f80e-4566-aa7f-379d8fc5bbb1',
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2FBMW5.jpg?alt=media&token=ed0184d0-52aa-43c9-b742-2e0aae81d402',
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2FBMW6.jpg?alt=media&token=b25f6998-fdc8-4a38-9fb1-feb87ad1c7bf',
    'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2FBMW7.jpg?alt=media&token=8fbfb434-aeda-4226-8d42-f6e3fb00df79'
] as string[];

  constructor() { }

  ngOnInit(): void {
  }

}
