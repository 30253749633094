/* eslint-disable max-len */
import { Component, Input, OnInit } from "@angular/core";
import { OfferedService } from "../../models/offered-service.model";
import { OffersService } from "../../services/offered-services.service";

@Component({
  selector: "app-menu-panel",
  templateUrl: "./menu-panel.component.html",
  styleUrls: ["./menu-panel.component.css"],
})
export class MenuPanelComponent implements OnInit {
  @Input() type:
    | "home"
    | "business"
    | "about"
    | "assist"
    | "switch"
    | "tactical";

  title: string;
  lead: string;
  box: { title: string; image: string; body: string; btnText: string; btnLink };
  services: OfferedService[];
  public services1 = [];
  public services2 = [];
  public services3 = [];
  constructor(private offersService: OffersService) {}

  ngOnInit(): void {
    this.setUp();
  }

  setUp() {
    switch (this.type) {
      case "home":
        this.services = this.offersService.residential;

        this.title = "Residential Services";
        this.lead =
          "We have solutions to support your home security needs from all angles. Choose from:";
        this.box = {
          title: "SUPPORTING OUR COMMUNITIES SINCE 1996",
          body: "Sniper Security has been serving local communities since 1996. We have a dedicated team of professionals ready to attend to all your security needs. Let us talk about your safety and how we can help you secure your home, belongings and family.",
          btnLink: "/forms/quote",
          btnText: "Let us help you secure your home",
          image:
            "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fmenu%2Fres.jpg?alt=media&token=aa2c7e77-e02e-446d-bee6-48c2e3750117",
        };
        break;
      case "business":
        this.services = this.offersService.commercial;
        this.title = "Commercial Services";
        this.lead =
          "We have solutions to support your business security needs from all angles. Choose from:";
        this.box = {
          title: "Expert Solutions",
          body: "Sniper Security has been helping businesses design security solutions that protect and minimise their risk since 1996. Our team of highly trained professionals are dedicated to help you manage, minimise and address all aspects of your risk. Let's talk and see how we can work together.",
          btnLink: "/forms/quote",
          btnText: "Let us help you secure your business",
          image:
            "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fmenu%2Fcomm.jpg?alt=media&token=4c70b0d6-df63-41f5-ad7d-ba7ccf61338e",
        };
        break;
      case "about":
        this.services = this.offersService.about;
        this.title = "About Us";
        break;
      case "assist":
        this.services = this.offersService.assist;
        this.title = "How can we assist you?";
        break;
      case "switch":
        this.services = this.offersService.switch;
        this.title = "Switch Service Providers";
        break;
      case "tactical":
        this.services = this.offersService.tactical;
        this.title = "Tactical Security Packages";
        break;
    }
    this.getServices();
  }
  getServices() {
    const list = this.services;
    let interval = Math.ceil(list.length / 3);
    if (this.type === "assist") {
      this.services3 = list.slice(list.length - 3);
      interval = Math.ceil((list.length - 3) / 2);
      this.services1 = list.slice(0, interval);
      this.services2 = list.slice(interval, list.length - 3);
      return;
    }
    this.services1 = list.slice(0, interval);
    this.services2 = list.slice(interval, interval * 2);
    this.services3 = list.slice(interval * 2, list.length);
  }
}
