<div class="container-fluid">
  <!-- 0 -->

  <!-- 1 -->
  <app-panel
  titleStart="Mobile Gate Access Control"
  titleEnd="from the palm of your hand."
  [landing]="true"
  body="Sniper's easy to use home automation mobile application gives you the ability to manage your home no matter where you are.
   You can unlock your gate if your kids have forgotten the keys, open your driveway's gate,
    and even manage additional devices from the app at a push of a button.
     Smart Home technology lets you stay connected to your home when you are on the go.
   Now you don't have to wait or worry about how your home functions when you're away."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fphone-cafe.jpg?alt=media&token=7ec999b6-25ae-4b68-9726-ee3973104869'|panelStyle)"
></app-panel>

   <!-- 3 -->
  <div class="row align-items-center justify-content-center">
    <div class="col-md-10">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fthumbnails%2Fmobile-access-control.jpg?alt=media&token=e7b7965d-bbb2-4159-8038-debb506a4101">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fgate-acces-control-video.mp4?alt=media&token=cafbd851-7a82-400e-967d-a034812b6a1c" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>

  <!-- 4 -->
  <app-panel
  titleStart="Hard Wired"
  titleEnd="Gate Access Control"
  [titleBreak]="true"
  [alignment]='"end"'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fgate-access-control-keypad.jpg?alt=media&token=c0577a70-098c-43ef-896e-6243e34337cb'|panelStyle:'end')">
  </app-panel>

   <!-- 5 -->
  <div class="row align-items-center justify-content-center">
    <div class="col-md-10">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fthumbnails%2Fgate-access-control.jpg?alt=media&token=70dc4ef9-cd7f-42ae-ab89-780a060da4bb">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fhard-wired.mp4?alt=media&token=726c79b9-7577-453c-8360-b6c4c1f625aa" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>




  <!-- 6-->
  <app-faq-panel
    about="our access control app"
    [faqs]='faq'
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fold-lady.jpg?alt=media&token=9a0f4e01-ba4b-4c94-9261-ca624d7aaa53'|panelStyle)">
  >

  </app-faq-panel>


  <!-- 4 -->

  <app-action-panel
  titleStart="Contact"
  titleEnd="Us"
  [titleBreak]="false"
  body=" Our security products are built with you-and your family-in mind. Sniper's expert security technicians will consult with you and then design and install a system that allows you to realize the benefits of our access control services."
 [btnLink]="'/forms/quote'"
 >

</app-action-panel>



</div>
