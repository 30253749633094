<div class="container-fluid">
  <!-- landing -->
  <app-panel
   [landing]='true'
   [titleStart]="'Business'"
   [titleEnd]="'Burglar Alarm Systems'"
   [titleBreak]='false'
   [subtitle]="'Protect your business from intruders, while having 24/7 monitoring access.'"
   [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fwarehouse%2Bkeypad.jpg?alt=media&token=7a1737f3-69e6-43e8-9985-00d4d33366d2'|panelStyle)"
   >
 </app-panel>


  <!-- 2 -->
  <app-panel
    [titleStart]="'Burglar Alarm Technology'"
    [titleEnd]="'that lets you monitor your business from anywhere'"
    [alignment]="'end'"
    body="Sniper Security’s business security solutions offer total control at your fingertips. You can remotely arm and disarm the burglar alarm system right from your smartphone, whether you are at work, at home, or away on holiday. Every business requires unique solutions, and our security consultants will design and configure a security system that is perfect for your business."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcctv-phone-view.jpg?alt=media&token=1482b375-5b5e-421a-a03f-946b1f7e9bab'
    |panelStyle:'end': {'background-position':'left','background-size' :'contain'})"
  >
  </app-panel>


  <!-- 3 -->
  <app-panel
    [titleStart]="'WITH ALARM MONITORING, YOUR SECURITY SYSTEM AND OUR STAFF'"
    [titleEnd]="'WORK TOGETHER TO STOP INTRUDERS'"
    body="When an alarm is triggered, a high-pitched siren will sound. While this may be enough to ward off a burglary in progress, ensuring your security requires an extra layer of protection.  When your burglar alarm triggers, it sends an alert to agents stationed at our UL Certified alarm monitoring centre. The agents then follow the emergency dispatch protocol set up at the time of system installation, which includes dispatching law enforcement and notifying the point of contact at your business."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcall-center.jpg?alt=media&token=8678c15d-6f2d-451a-88ff-1d5a8e0ab2ab'
    |panelStyle)"
  >
  </app-panel>

  <!-- 4 -->
  <app-panel
    [titleStart]="'SECURITY SYSTEMS AND CUSTOMER SERVICE'"
    [titleEnd]="'THAT ALLOW YOU TO FOCUS ON YOUR BUSINESS'"
    [alignment]="'end'"
    body="At Sniper Security, we provide an unmatched customer experience through reliable, high tech security solutions and 24/7 monitoring support. Our advanced burglar alarm technology is designed with business safety in mind —not only is our equipment reliable and easy to use, but it is backed by skilled monitoring agents that respond immediately when your alarm goes off, dispatching emergency authorities to ensure you are safe."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcall%20-centre-female.jpg?alt=media&token=72eb109d-4192-47aa-a3fb-d08fb17ef34d'
    |panelStyle:'end': { 'background-color': 'white', 'background-position': 'left', 'background-size':'contain'})"
  >
  <!-- TODO  Change/modify picture-->
  </app-panel>

  <!-- 5 -->
  <app-panel
    [titleStart]="'Customised alarm notifications'"
    [titleEnd]="'at your fingertips'"
    body="We offer a suite of connected technology alerts and monitoring services that provide the ultimate in business security. With Sniper, you can equip your business with:
    <ul>
      <li>Communication failure alerts</li>
      <li>Opening and closing alerts</li>
      <li>Fail to close alerts</li>
      <li>Power failure alerts</li>
      <li>Low battery alerts</li>
      <li>AC failure alerts</li>
      <li>Panic alerts</li>
    </ul>
    Each layer of security brings your business one step closer to comprehensive safety."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Femail_notification_alerts.jpg?alt=media&token=16911dd7-e5d6-41c5-a541-6f0351d984c1'
    |panelStyle:'start': {'background-position': 'bottom'})"
  >
  </app-panel>


  <!-- 6-->
  <app-faq-panel
  about="our business burglar alarms"
  [faqs]='faq'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fold-man-phone.jpg?alt=media&token=1c8fd163-7d8e-44a0-b1fa-1d7a64e12fd9'|panelStyle)">
  >
  </app-faq-panel>

</div>
