import {Component, OnInit } from '@angular/core';
import { OffersService } from 'src/app/shared/services/offered-services.service';
import { OfferedService } from 'src/app/shared/models/offered-service.model';

@Component({
  selector: 'app-residental-services',
  templateUrl: './all-residental-services.component.html',
  styleUrls: ['./all-residental-services.component.css']
})
export class AllResidentalServicesComponent implements OnInit {

  customerReviews: {message: string; reviewer: string}[] = [];
  reviewIndex = 0;

  services: OfferedService[] = [];



  constructor(private offersService: OffersService) { }

  ngOnInit(): void {
    this.getCustomerReviews();
    this.services = this.offersService.residential;
  }

  //#region  Reviews
  getCustomerReviews(){
    this.customerReviews.push(
      {
        message: 'The installers were professional and answered all the questions I had. I highly recommend Sniper Security.',
        reviewer: 'Matt - Sandown Rd, Rondebosch'
      },
      {
        message: 'Great service, wonderful representatives, excellent response time - \
        kind, professional and efficient. Everything a customer could want!',
        reviewer: 'Brian - Glastonbury Drive, Bishops Court'
      },
      {
        message: '\nI\'m all about service and these guys delivered.',
        reviewer: 'Shahied - Burwood Rd, Crawford'
      },
      {
        message: 'I\'m extremely finicky about who enters my home and maintains my security. \
        Sniper\'s security processes gave me instant peace of mind.',
        reviewer: 'Camila - Geneva Drive, Camps Bay'
      },
      {
        message: 'Their service was excellent and efficient, I will definitely continue to recommend them.',
        reviewer: 'Shamil - Paradise Drive, Newlands'
      },
      {
        reviewer: 'Peter - High Level Road, Atlantic Seaboard, Cape Town',
        message: 'I love the fact that they are on time for their appointments.'},

      { reviewer: 'Storm - Bantry Bay, Cape Town',
       message: 'It is always a pleasure working with them. They know exactly what they are doing.'},

      { reviewer: 'Nigel - Bel Ombre, Cape Town',
       message: 'I like the fact that the owner is accessible when I have a specific query or challenge. \
       My family is priceless and my number one priority.'},

      { reviewer: 'Michelle - Claremont, Cape Town',
       message: 'I chose Sniper because my kids are alone at home with my domestic worker \
       and I wanted a security company that could response in under 7 minutes if they required help. \
       I like the fact they the company really cares about my safety.'},

      { reviewer: 'Barry -  Nettleton Road, Clifton, Cape Town',
       message: 'I have been using Sniper for the past 7 years for my CCTV maintenance and satisfied thus far.'},

      { reviewer: 'Dave - Ocean View Drive, Green Point, Cape Town',
       message: 'I like the fact that they offer an Online Technical support and payment service.'},

      { reviewer: 'Pascal – Lincoln Drive, Kirstenbosch',
       message: 'These guys have never let me down since 2010. Sometimes I do get a bit annoyed with their protocols, \
       but then again that’s why I prefer working with them because they are thorough.'},

      { reviewer: 'Franklin – Chukker Road, Kenwyn',
       message: 'I like the company because they are extremely innovative and service driven.'},

      { reviewer: 'Bradly - Irwinton Road, Sea Point, Cape Town',
      message: ' Fast and friendly service. No hassles. I recommend these guys without a doubt.'},

      { reviewer: 'Jeff & Chandre - Steenburg Estate, Cape Town ',
       message: 'We stay in a gated community and prefer dealing with a company that is transparent. \
       I like the fact that Sniper is vocal about corruption in the industry.'},


      { reviewer: 'Sheraaz – Beresford Road, University Estate',
       message: 'I use Sniper Security for my electric fencing and alarm maintenance. \
       I also use them for my armed response and alarm monitoring. They are jacked up when it comes to service.'},
      { reviewer: 'Paul – Rugley Road, Vredehoek, Cape Town',
       message: 'Great service and always very professional.'}
    );
  }

  /**
   * changes the displayed review
   *
   * @param forward true if the 'forward' direction was selected
   *  false if the 'backward' direction selected
   */
  nextReview(forward: boolean){
    if (forward){
      this.reviewIndex = (this.reviewIndex + 1) >= this.customerReviews.length ? 0 : (this.reviewIndex + 1);
    }
    else{
      this.reviewIndex = (this.reviewIndex - 1) < 0 ? (this.customerReviews.length - 1) : (this.reviewIndex - 1);
    }


  }
  //#endregion

}
