<div class="container-fluid">
  <div class="row page-row align-items-center first">
    <div class="col-md-12">
        <div class="d-flex flex-column w-100 left-border-panel p-4 my-2 app-bg">
          <h1 class="text-uppercase red-text">Payment Declined</h1>
          <p *ngIf="personaliseMessage">Hi {{name}},</p>
          <p>Your <span style="text-transform: capitalize;">{{formName}}</span> payment was declined.</p>
          <p>Please contact us if you require assistance.</p>
          <a role="button" class="button button-outline-primary w-50 text-uppercase" routerLink="/">Go to home page</a>
        </div>
    </div>
  </div>
</div>
