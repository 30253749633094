<div class="container-fluid">

  <!-- Landing -->
  <app-panel
    [landing]='true'
    [titleStart]="'Business'"
    [titleBreak]='false'
    [titleEnd]="'security systems'"
    subtitle="Perfect for large and small businesses alike."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FGolf%207%20b.jpg?alt=media&token=b83eb080-1369-4f29-8435-424d59d47c7f'
    |panelStyle)">
  </app-panel>
  <!-- body="Protect your business against intrusion by installing a Sniper Security system." -->

  <!-- 1 -->
  <app-review-box
   [customerReviews]="customerReviews"
   [image]="'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fmeeting.jpg?alt=media&token=59a94232-7aeb-47f9-97a3-513a53cc2d8e'"
   [title]="{start: 'Real businesses like yours are talking about', end: 'sniper\'s commercial security!'}"
   ></app-review-box>

  <!-- 2 -->
  <div class="row">
    <app-offered-services [serviceType]="'Commercial'" [offered]="services">
    </app-offered-services>
  </div>

  <!-- 3 -->
  <app-panel
    [alignment]="'end'"
    titleStart="We're here to equip you with the best"
    titleEnd="security technology and comprehensive support."
    body="We offer the combination of industry leading <a class='link' href='/commercial/burglar-alarm-systems'>commercial alarm system</a> packages and
    expert support required for total business security.
     Our sales representative can work with you to determine the right alarm system for you,
     and our expert technicians will install the system and train you on how to use it. From there our highly trained control room staff
     will remain on the clock 24/7 to ensure that your business is safe and secure."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fproducts.jpg?alt=media&token=6ccb1c3c-2563-4121-af77-16de631e656a'
    |panelStyle:'end')">
  </app-panel>

  <!-- 4 -->
  <app-panel
    titleStart="Sniper Security's professionals"
    titleEnd="work tirelessly to meet your business needs."
    body="Your business is unique, and we know that you've worked dilligently to make it a success.
    That's why our security consultants will work tirelessly to understand your needs and design a system that meets
    your goals, budget and timeline.
    Our 24/7 <a class='link' href='/commercial/alarm-monitoring'>alarm monitoring</a> team is always standing by,
    ready to dispatch tactical emergency services to your business if they recieve an alarm alert.
    With business security solution's from Sniper,
    you can rest assured that your business, your employees and your valuable property are safe and secure."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F5.jpg?alt=media&token=212cac65-70c9-49af-95c2-e8d4d7b72f8f'
    |panelStyle)">
  </app-panel>

  <!-- 5 -->
  <app-panel
    titleStart="We're proud of our"
    titleEnd="proven customer service track record."
    [titleBreak]='true'
    alignment="end"
    body="Sniper Security has worked with businesses throughout the Western Cape to create more secure work environments.
    Over the last 25 years, we've revolutionized our sales, system design, and installation processes to deliver world class customer support service.
    We strive to get the job done on time and on budget, and offer continued technical and equipment support whenever you call on us."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fall-com%2Ftechnician.jpg?alt=media&token=c3c96709-98a1-478f-96b7-d27f41cd148b'
    |panelStyle:'end')">
  </app-panel>

   <!-- 6 -->
  <app-panel
    titleStart="Our security systems are"
    titleEnd="integrated and scalable."
    body="Our <a class='link' href='/commercial/burglar-alarm-systems'>security systems</a> are designed to meet the needs of both small businesses and large companies with multiple locations.
    We can quickly scale our security solutions for clients with more complex requirements,
    while still maintaining system integrity and performance.
    If your business is already equipped with a security system, we can determine if it works with our technology,
    and if so, how to best integrate it for maximum performance."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fdesign.jpg?alt=media&token=fb832010-7499-4aca-9dc4-544b0f862650'
    |panelStyle)">
  </app-panel>


</div>

