<div class="container-fluid">
  <div class="row page-row justify-content-center align-items-center app-bg">
    <div class="col-12">
      <div
        class="d-flex flex-column container-fluid p-lg-4 text-white large-text"
      >
        <h1 class="text-uppercase display-3">
          It is personal <span class="accent-text">to all of us.</span>
        </h1>
        <div class="img-container">
          <img
            class="py-1"
            src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2Fit's_personal.jpg?alt=media&token=c2cb6c57-e9a2-493d-bfd4-01fc6739adc8"
            (load)="imageLoaded = true"
          />
          <div>
            <div class="caption" *ngIf="!screenService.onMobile && imageLoaded">
              <p>
                <span class="primary-text"
                  >Managing Director, Ridwaan Mathews,</span
                >
                with Tactical Security Officers
              </p>
            </div>
          </div>
          <div class="caption" *ngIf="screenService.onMobile && imageLoaded">
            <p>
              Managing Director, Ridwaan Mathews, with Tactical Security
              Officers
            </p>
          </div>
        </div>
        <p class="lead">
          <br />In a world where uncertainty lurks, we stand as your reliable
          guardians. Our team of experts develops innovative, proactive security
          solutions that adapt to your unique needs, providing peace of mind.
        </p>
        <p class="fw-bold">Our mission is built on three pillars of trust:</p>
        <ul>
          <li>
            <strong>Service Excellence:</strong> Delivering exceptional results,
            every time.
          </li>
          <li>
            <strong>Time Management:</strong> Respecting your time, always.
          </li>
          <li>
            <strong>Proactive Management Support:</strong> Anticipating and
            addressing potential threats.
          </li>
        </ul>
        <p>
          We understand that trust is earned through actions, not words. That's
          why we are committed to transparency, accountability, and continuous
          improvement.
        </p>
        <p>
          We don't just provide security services - we build long-lasting
          relationships founded on trust, transparency, and reliability. Join
          us, and experience the comfort of knowing you're protected by a team
          that genuinely cares.
        </p>
        <p>
          <b>
            Ridwaan Mathews<br />
            <span style="font-size: smaller">Managing Director</span>
          </b>
        </p>
      </div>
    </div>
  </div>
</div>
