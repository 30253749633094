<div class="container-fluid">
  <!-- landing -->
  <app-panel
    titleStart="CCTV"
    titleEnd="Systems"
    [titleBreak]='false'
    [landing]='true'
    subtitle="Our series of CCTV solutions is the ultimate combination of advanced technology and expert people."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FHikvision%2010.jpg?alt=media&token=f6942180-3323-4b05-b56c-d2ecf53de9d7'|panelStyle)">
  </app-panel>


  <!-- 2 -->
  <div class="row page-row my-3 justify-content-center app-bg">
    <div class="col-md-12 text-center mt-5">
      <h1 class="text-center text-uppercase">Security Solutions for every business</h1>
    </div>
    <div class="w-100"></div>
    <div class="col-md-4 my-2" *ngFor="let serviceCard of serviceCards">
      <div class="card app-bg-lighter shadow text-center m-2 service-card zoom">
        <img [src]="serviceCard.image" class="card-img-top card-image" alt="..." >
        <div class="card-body">
          <h3 class="card-title mb-2">{{serviceCard.title}}</h3>
          <p class="card-text">{{serviceCard.message}}</p>
        </div>
      </div>
    </div>
  </div>


  <!-- 3 -->
  <div class="row page-row my-3 justify-content-center second">
    <div class="col-12 text-center mt-5">
      <h1 class="text-center text-uppercase text-white">CHECK OUT HOW OUR CCTV SOLUTIONS CAN HELP PROTECT YOUR BUSINESS</h1>
    </div>
    <div class="w-100"></div>
    <div class="col-md-6">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FbusinessCCTV1.jpg?alt=media&token=00709dfd-8c57-4c84-968f-81490855c880">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FbusinessCCTV1.mp4?alt=media&token=18f48d54-bb74-47b7-ba41-b158013948d3" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
    <div class="col-md-6">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FbusinessCCTV2.jpg?alt=media&token=9650f401-35ca-4721-b2d7-134ca536b218">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2FbusinessCCTV2.mp4?alt=media&token=a82b388b-ee24-4fac-8e73-890c5e18074b" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>

  <app-panel
    titleStart="Protect your business with an"
    titleEnd="intelligent cctv surveillance system"
    [titleBreak]='false'
    [alignment]="'end'"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcctv-warehouse.jpg?alt=media&token=aca9360f-d1af-4cfd-b41d-921a6a51411b'|panelStyle:'end')">
  </app-panel>

  <div class="row page-row">
    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fres%2Fcctv-devices-view.jpg?alt=media&token=21e1b81a-c15f-4838-a4da-e510d4f35b71">
  </div>

  <div class="row page-row">
    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcctv-ad.jpeg?alt=media&token=b4b31d54-3848-4aa6-9276-8395b8eed352">
  </div>


</div>

