import { Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase/compat/app';


@Pipe({
  name: 'formAnswer'
})
export class FormAnswerPipe implements PipeTransform {

  transform(value: unknown, isDate?: boolean, ...args: unknown[]): unknown {
    return this.parseAnswer(value, isDate);
  }

  parseAnswer(value: any, isDateQuestion?: boolean) {
    if (typeof(value) === 'boolean'){
      return value ? 'Yes/Agreed' : 'No/Disagreed';
    }

    if (Array.isArray(value)){
      return value.map(val => this.parseAnswer(val)).join(', ');
    }
    if (value instanceof firebase.firestore.Timestamp){
      return this.formatDate(value.toDate());
    }
    if (value instanceof Date){
      return this.formatDate(value);
    }
    if (!isNaN(Date.parse(value as string)) && isDateQuestion) {
      return this.formatDate(new Date(Date.parse(value as string)));
    }

    return value;
  }

  formatDate(date: Date) {
    return date.toLocaleDateString('en-ZA', {timeZone: 'Africa/Johannesburg'});
  };


}
