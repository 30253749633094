import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { map } from 'rxjs/operators';
import { Answer } from 'src/app/models/answer';
import { FormResponse } from 'src/app/models/form-response.model';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat/app';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

    //"submissions" is a firestore Document! This pattern is used to enable collectionGroup querying
    constructor(public firestore: AngularFirestore, public http: HttpClient,
      public functions: AngularFireFunctions, public storage: StorageService) { }

    async saveForm(collection: string, formResponse: FormResponse){
      formResponse._status = 'unresolved';
      return this.generateID().then(async (formID) => {
        console.log(formID);
        await this.firestore.collection<FormResponse>(`forms/submissions/${collection}`).
        doc(formID).set(formResponse);
        return formID;
      });
    }

    getForm(collection: string,id: string){
      return this.firestore.collection(`forms/submissions/${collection}`).doc<FormResponse>(id).valueChanges();
    }

    updateForm(id: string,collection: string, updatedFields: {[key: string]: any}, newAnswers: Answer[]){
      return this.firestore.collection(`forms/submissions/${collection}`).doc(id)
      .update({...updatedFields, answers: firebase.firestore.FieldValue.arrayUnion(...newAnswers)});
    }

    saveToLocal(collection: string, formResponse: FormResponse){
      const val = formResponse as any;
      delete val.answers;
      delete val.id;
      delete val.date;
      localStorage.setItem(collection, JSON.stringify(val));
    }

    getFromLocal(collection: string){
      return JSON.parse(localStorage.getItem(collection)) as FormResponse;
    }


    saveCareerForm(careerForm: any){
      return this.firestore.collection('careers').add(careerForm);
    }

    saveEnquiryForm(careerForm: any){
      return this.firestore.collection('enquiries').add(careerForm);
    }


    saveRatingForm(form: any){
      return this.firestore.collection('ratings').add(form);
    }



    async generateID(){
      return await this.http.post(environment.app.functionsURL+'/formID',null).toPromise().then(data => {
        const d = data as {id: string};
        return d.id;
      });

    }

    getFormAsPDF(url: string, name: string) {
      //TODO modify name if required
      //name.toLowerCase().replace(' ','-');
      const params = new HttpParams().appendAll({url, name});
      return this.http.get(environment.app.functionsURL+'/pdf',{params, responseType: 'blob'})
      .pipe(
        // catchError((err) => {console.log({err}) ;return of();}),
        map(response => this.bufferToFile(response, name))
      );
    }

    bufferToFile(pdfBuffer: Blob, name: string){
      if(!name.endsWith('.pdf')){
        name += '.pdf';
      }
      return new File([pdfBuffer], name, {type: 'application/pdf'});
    }



}
