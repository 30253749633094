<div class="container-fluid">
  <app-panel [titleStart]='"SETTING HIGH STANDARDS OF"' [titleEnd]="'COMPLIANCE AND BEHAVIOUR '" [landing]='true' [titleBreak]='false'
  body="<p class='primary-text'><b>Using a non-compliant security service provider compromises your safety and will land you in hot water, as you are engaging with an unregistered individual allowing them into your home or business that has not been criminally cleared. <a class='primary-text underline cursor-pointer' href='https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/PSIRA%20Consumer%20Liability.pdf?alt=media&token=7666dba4-d2f8-4bcd-8a50-68dce92013f9' target='_blank' >Click here to learn more.</a></b></p>
    <p>Our Business Integrity framework ensures that how we do business is fully aligned with our values and applicable to the laws and regulations.</p>
    <p>The Philosophy consists of three pillars:
      <ul>
        <li>Prevention – we seek to embed a culture of integrity at all levels. </li>
        <li>Accountability – we take responsibility for our actions and do not make excuses. </li>
        <li>Care – we continually strive to improve our service offering</li>
      </ul>
    </p>
    <p>Our Code of Business Principles is at the heart of our Business Integrity framework. They help us put our values of integrity, compliance and accountability into practice. They play a critical role in ensuring your safety.</p>
    <h1 class='accent-text'>Code of Business Principles</h1>
    <p>Our Code of Business Principles  is a simple ethical statement of how we should operate. We do not shy away from the fact that not all security companies are concerned about your safety. We are extremely vocal about security negligence and corruption within the industry and publish this externally and expect all others who work with us to set themselves equally high principles and standards of compliance.
    </p>
    <h1 class='accent-text'>Sniper's Independent Research with UCT's Criminology Department </h1>
    <p>Our independent research confirms that 50% of home and business burglaries are as a result  of negligent recruitment practices including employee negligence. At Sniper we have a zero tolerance for such behaviour and are working tirelessly to address these challenges on a daily basis. We are registered members of <a class='link' href='https://riskprofiler.co.za' target='_blank'>www.riskprofiler.co.za</a>.
    </p>
    <h4 class='primary-text'>Message from the Managing Director - Ridwaan Mathews</h4>
      <p>Behaving with integrity is non-negotiable and will help in creating an industry that is principled.</p>"
    [style]='("https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2FDSC01516.jpg?alt=media&token=5c5e35c1-4357-4b66-b9ba-324d8bdb3c56"|panelStyle)'
  >
  </app-panel>

</div>
