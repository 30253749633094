<div class="container-fluid app-bg">
  <app-panel
  [landing]='true'
  [col]="'col-lg-5 pl-5'"
  titleStart="PERSONAL"
  titleEnd="Protection"
  subtitle="PROFESSIONAL PERSONAL PROTECTION"
  alignment='end'
  body="If you’re a <b>VIP, executive, celebrity, artist, politician, banker</b> or just looking for personal protection and are looking to hire a bodyguard, Sniper offers the expertise you can trust. Some of the world’s rich and famous trust our bodyguards to keep them safe. <br><br>
  Furthermore, we are trained experts in private security, being able to provide staff for movie set security, private vip transportation, private security for executives and celebrities and even personal concierge services which require discreet operations by trained professionals"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fcommon%2Fpersonalised_protection_black.jpg?alt=media&token=27afd227-9f7c-49c0-819a-4be3fb5c5de5'|panelStyle:'end': { 'background-color':'black', 'background-position' : 'center bottom 20px'})">
</app-panel>
</div>

