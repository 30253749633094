<div class="container-fluid app-bg">
  <app-panel
  titleStart="Supporting"
  titleEnd="Local Talent"
  subtitle="Managing Director - Ridwaan Mathews & Jaden Chamberlain"
  [showButtons]="false"
  body="<p><span class='text-bold'>{{title}}</span><br>
    <br><br>We are committed to supporting previously disadvantaged individuals who are passionate about their sport and show true commitment at excelling in what they do.
  </p>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2FBaseball%20-%20Copy.JPG?alt=media&token=83640c13-04ac-4c51-9f9e-ac718cbdcbffhttps://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2FBaseball%20-%20Copy.JPG?alt=media&token=83640c13-04ac-4c51-9f9e-ac718cbdcbff'|panelStyle)"
> </app-panel>

  <app-panel
  titleStart="Back to school"
  titleEnd="project"
  [showButtons]="false"
  body="<p><span class='text-bold'>{{title}}</span><br>
    <br><br>At Sniper we are passionate about early learning development and empowering individuals from impoverished communities. We believe that our learners have a unique advantage as their mental and fighting spirits are tested early in life. We mentor these students to help them become successful and provide them with valuable skills that can be transferred to any business.<br>
    Please contact us should you wish to participate and contribute to this amazing transformation journey.
  </p>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fabout%2Fback_to_school_project.jpg?alt=media&token=6693bb9d-5bad-4de6-998a-914922d26290'|panelStyle)"
>
  </app-panel>

  <!-- Internship -->
  <app-panel
  titleStart="Internship"
  titleEnd="Program"
  [alignment]="'end'"
  [showButtons]="false"
  body='<p><span class="text-bold">{{title}}</span><br>
    <br>Sniper Security is proud to be a partner of the College of Cape Internship Programme.
    The aim of the programme is for Sniper to transfer critical skills to the young graduates to get them job ready for the real world.
    We are passionate about the programme and take great pride in developing these young graduates in participating in the South African Economy.<br><br>
    <b>"IT’S TIME TO ASK YOURSELF – WHAT IMPACT DO YOU HAVE IN YOUR COMMUNITY"</b>
  </p>'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fabout%2Finternship-programme.jpg?alt=media&token=10f65257-5432-465d-bb27-9c1b3f64ab51'|panelStyle:'end')"
>
  </app-panel>

  <!-- mba -->

  <app-panel
  titleStart="Street"
  titleEnd="MBA Programme"
  [showButtons]="false"
  body="<p><span class='text-bold'>{{title}}</span><br>
    <br>As a previously disadvantaged Entrepreneur without any form of business support, I identified a massive need for a support programme that assists up and coming Entrepreneurs with the daily business and mental challenges.<br>
    The Street MBA programme is a support system to grow Entrepreneurs and to give them advice on all aspects of business, which includes Sales, Marketing, Finance, personal and spiritual development.<br>
    The aim of the programme is to foster a culture amongst Entrepreneurs to “Pay It Forward”.
    As the Managing Director of Sniper Security, I am passionate about nurturing and developing talented Entrepreneurs. Early entrepreneurial intervention has the ability to impact positively on one’s career path, therefore minimizing the possibility of failure.
    The program works with Entrepreneurs of all backgrounds and ages across the Western Cape.<br>
    It is imperative to note that I do not supply or arrange any form of funding. This is merely a skills transfer and mental support programme.
    This is purely a motivational and skills transfer programme.
    Should you wish to participate or contribute to this programme, kindly email me on md@snipersecurity.co.za
    My details are as follows : Ridwaan Mathews Managing Director Sniper Security.
  </p>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fabout%2Fstreet-mba.jpg?alt=media&token=dda0ee1c-cb99-4783-82fb-aab86eb4ccc7'|panelStyle:'start': {'background-color' : 'black', 'background-size' : 'contain'})"
>
  </app-panel>


  <!-- oxygen  -->
  <app-panel
  titleStart="Project"
  titleEnd="Oxygen Machine"
  [alignment]="'end'"
  [showButtons]="false"
  body="<p><span class='text-bold'>{{title}}</span><br>
    <br>Sniper Security heard about the plight of a young courageous entrepreneur an decided to donate a much needed Oxygen Machine
    to local resident Ismail as part of our “pay it forward campaign”. There were times that he was unable to breathe at night while sleeping.
    We chose to donate the oxygen machine to him as he is a real fighter and extremely optimistic about life. He is a true inspiration to us all.<br><br>
    Mr Ismail runs his own small business fast food takeaways on the weekends and on certain dates operates food stalls across
    the Cape Town area as well. He operates from his home at 41 Thornton Road Athlone.
    If at any stage you find yourself hungry and feeling for some chicken wings or some spicy chicken tikka,
    please support this amazing young up and coming entrepreneur.
    You can place your food order on whatsapp or contact him directly on 0849044684 and collect your order at his home.
    Mr Ismail also does business via Gumtree, buying and selling things. Congratulations Ismail, you are truly an amazing individual.
  </p>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fabout%2Fo2machine.jpg?alt=media&token=0a76ce58-0046-4b65-8c1e-bee984f3e12c'|panelStyle:'end')"
>
  </app-panel>

  <!-- empower -->
  <app-panel
    titleStart="Empowering Women - "
    titleEnd="Project Sanitary Pads"
    [showButtons]="false"
    body="<p><span class='text-bold'>{{title}}</span><br>
      <br>As a business owner, Sniper Security decided to support Psychologist Lynne Cloete with the Sanitary Pads Project. Our aim is to assist young girls in the uncomfortable dilemma of not having access to basic sanitary pads. We felt the need to get involved to reduce the massive absenteeism and school dropout rate among young girls in previously disadvantaged areas.We are passionate about raising awareness around women’s health.<br><br>
      We have one of the most advanced and thorough 24hr armed response companies in the Western Cape. We have a dedicated alarm signal analysist that analyses each and every alarm activation signal and makes the necessary recommendation to improve your safety. We are proud to be the only Armed Response Company in South Africa that is a research partner to UCTs Criminology department in Cape Town. We do not simply provide a security service, we enhance your lifestyle giving you peace of mind.
    </p>"
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fabout%2Fempower.jpg?alt=media&token=4a54ec74-e8a9-4757-94ed-baa6562a13e7'|panelStyle)"
  >

  </app-panel>

<!-- music -->
  <app-panel
    titleStart="music"
    titleEnd="changing lives project"
    [alignment]='"end"'
    [showButtons]="false"
    body="<span class='text-bold'>{{title}}</span><br>
    <br>Sniper Security has partnered with Portia Primary and the Young Guiding Stars Sacred String Band and has donated a Piano and a set of drums to the project. As part of the fight against crime we have created a partnership with these organisations to play a more active role in early youth development.<br><br>
    Sniper Security is passionate about nurturing and developing talented youngster’s and assisting them in their career paths. Early youth development has the ability to impact positively on one’s career path therefore minimizing the possibility of them ending up in gangs or doing drugs. The program works with learners from disadvantaged backgrounds from all areas across the Western Cape.
    The music project is an early intervention program to avoid learners and individuals from ending up in gangs or doing drugs. The project is a great stepping stone of networking, therefore giving talented individuals a fighting chance in life.
    The Principal of Portia Primary is committed to the Music Changing Lives Project and has given us his full support.<br><br>
    The aim of the project is to share my journey of Entrepreneurship, life and work skills with the youth and to enable them to Dream BIG and to achieve success at an early age, irrespective of their circumstances or where they come from.
    We have started a music donation programme and urge the community to invest or donate and instrument to the project which will empower the youth and give them an opportunity to succeed in life.
    Currently the band requires the following instruments Saxophones, Clarinets, Euphonium.
    Any other instruments will gladly be appreciated.<br><br>
    As the Managing Director of Sniper Security I see a critical need for the youth in these disadvantaged areas to have direct contact with role models to inspire them to dream BIG. The project is merely one of our company initiatives to minimize crime in South Africa.
    Should you wish to participate or contribute to this life Changing Project, kindly contact Ridwaan Mathews Managing Director of Sniper Security on 021 697 0573."
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fabout%2Fmusic-changing-lives.jpg?alt=media&token=edbd4ef8-dedb-460b-a98a-5b6c92d039b6'|panelStyle:'end')"
  >

  </app-panel>
</div>
