<div class="container-fluid app-bg">
  <!-- 0 -->
  <app-panel
  titleStart="Gate & Garage Door"
  titleEnd="automation, repairs & maintenance."
  body="We do gate motor maintenance, repairs, security gate brackets, alarm triggers and custom designed solutions to protect your gate from being derailed or lifted."
  [landing]='true'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fgate.jpg?alt=media&token=b34e2029-abce-49ac-bfde-129b6ccf4775'|
  panelStyle:'start':{'background-position': 'bottom','background-size':'cover'})">
  </app-panel>
</div>

