import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outdoor-sensors',
  templateUrl: './outdoor-sensors.component.html',
  styleUrls: ['./outdoor-sensors.component.css']
})
export class OutdoorSensorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
