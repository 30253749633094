/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  reviews = [
    {
      message: 'The installers were professional and answered all the questions I had. I highly recommend Sniper Security.',
      reviewer: 'Matt - Sandown Rd, Rondebosch'
    },
    {
      message: 'Great service, wonderful representatives, excellent response time - \
      kind, professional and efficient. Everything a customer could want!',
      reviewer: 'Brian - Glastonbury Drive, Bishops Court'
    },
    {
      message: '\nI\'m all about service and these guys delivered.',
      reviewer: 'Shahied - Burwood Rd, Crawford'
    },
    {
      message: 'I\'m extremely finicky about who enters my home and maintains my security. \
      Sniper\'s security processes gave me instant peace of mind.',
      reviewer: 'Camila - Geneva Drive, Camps Bay'
    },
    {
      message: 'Their service was excellent and efficient, I will definitely continue to recommend them.',
      reviewer: 'Shamil - Paradise Drive, Newlands'
    },
    {
      reviewer: 'Peter - High Level Road, Atlantic Seaboard, Cape Town',
      message: 'I love the fact that they are on time for their appointments.'},

    { reviewer: 'Storm - Bantry Bay, Cape Town',
     message: 'It is always a pleasure working with them. They know exactly what they are doing.'},

    { reviewer: 'Nigel - Bel Ombre, Cape Town',
     message: 'I like the fact that the owner is accessible when I have a specific query or challenge. \
     My family is priceless and my number one priority.'},

    { reviewer: 'Michelle - Claremont, Cape Town',
     message: 'I chose Sniper because my kids are alone at home with my domestic worker \
     and I wanted a security company that could response in under 7 minutes if they required help. \
     I like the fact they the company really cares about my safety.'},

    { reviewer: 'Barry -  Nettleton Road, Clifton, Cape Town',
     message: 'I have been using Sniper for the past 7 years for my CCTV maintenance and satisfied thus far.'},

    { reviewer: 'Dave - Ocean View Drive, Green Point, Cape Town',
     message: 'I like the fact that they offer an Online Technical support and payment service.'},

    { reviewer: 'Pascal – Lincoln Drive, Kirstenbosch',
     message: 'These guys have never let me down since 2010. Sometimes I do get a bit annoyed with their protocols, \
     but then again that’s why I prefer working with them because they are thorough.'},

    { reviewer: 'Franklin – Chukker Road, Kenwyn',
     message: 'I like the company because they are extremely innovative and service driven.'},

    { reviewer: 'Bradly - Irwinton Road, Sea Point, Cape Town',
    message: ' Fast and friendly service. No hassles. I recommend these guys without a doubt.'},

    { reviewer: 'Jeff & Chandre - Steenburg Estate, Cape Town ',
     message: 'We stay in a gated community and prefer dealing with a company that is transparent. \
     I like the fact that Sniper is vocal about corruption in the industry.'},


    { reviewer: 'Sheraaz – Beresford Road, University Estate',
     message: 'I use Sniper Security for my electric fencing and alarm maintenance. \
     I also use them for my armed response and alarm monitoring. They are jacked up when it comes to service.'},
    { reviewer: 'Paul – Rugley Road, Vredehoek, Cape Town',
     message: 'Great service and always very professional.'},
     {
      message: 'I was extremely pleased by the level of professionalism \
      from Sniper from day one to the completion of my access control system',
      reviewer: 'Derreck, Building Manager - Montague Gardens'
    },
    {
      message: 'I was very impressed with the technical department as they kept me updated \
      with my installation progress every step of the way',
      reviewer: 'Cathleen, Medical Operations Manager - Northern Suburbs'
    },

    {
      message: 'It is nice to work with a company that returns your calls.',
      reviewer: 'Rashied, Forecourt Manager – Kenilworth',
    },
    {
      message: 'I am very service orientated and not easily pleased. Sniper really impressed me and exceeded all my expectations.',
      reviewer: 'Bobby, Owner Managed Business – Mowbray'
    },

    {
      reviewer: 'Angie, Body Corporate Trustee – Rosmead',
      message: 'We had several intrusions and trespassers at our complex before Sniper \
      installed a surveillance camera solution. Nowadays I sleep in peace.',
    },

  ];

  constructor() { }

  ngOnInit(): void {
  }





}
