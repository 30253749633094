import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccessAppComponent } from "./access-app/access-app.component";
import { AccessControlComponent } from "./access-control/access-control.component";
import { AlarmAppComponent } from "./alarm-app/alarm-app.component";
import { AlarmMonitoringComponent } from "./alarm-monitoring/alarm-monitoring.component";
import { AllResidentalServicesComponent } from "./all-residential-services/all-residental-services.component";
import { BurglarAlarmSystemsComponent } from "./burglar-alarm-systems/burglar-alarm-systems.component";
import { SecurityCamerasComponent } from "./cctv/security-cameras.component";
import { ElectricFencingComponent } from "./electric-fencing/electric-fencing.component";
import { GatesComponent } from "./gates/gates.component";
import { IpIntercomComponent } from "./ip-intercom/ip-intercom.component";
import { OutdoorSensorsComponent } from "./outdoor-sensors/outdoor-sensors.component";
import { TacticalResponseComponent } from "./tactical-response/tactical-response.component";
import { TechnicalSupportComponent } from "./technical-support/technical-support.component";

const routes: Routes = [
  {
    path: "residential",
    children: [
      { path: "alarm-monitoring", component: AlarmMonitoringComponent },
      {
        path: "burglar-alarm-systems",
        component: BurglarAlarmSystemsComponent,
      },
      { path: "alarm-app", component: AlarmAppComponent },
      { path: "access-app", component: AccessAppComponent },
      { path: "access-control", component: AccessControlComponent },
      { path: "security-cameras", component: SecurityCamerasComponent },
      { path: "tactical-response", component: TacticalResponseComponent },
      { path: "electric-fencing", component: ElectricFencingComponent },
      { path: "ip-intercom", component: IpIntercomComponent },
      { path: "outdoor-sensors", component: OutdoorSensorsComponent },
      { path: "gates", component: GatesComponent },
      { path: "technical-support", component: TechnicalSupportComponent },
      { path: "", component: AllResidentalServicesComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResidentialServicesRoutingModule {}
