import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionCollection, SubscriptionManager } from 'src/app/models/subscription-manager';

@Component({
  selector: 'app-decline',
  templateUrl: './decline.component.html',
  styleUrls: ['./decline.component.css']
})
export class DeclineComponent implements OnInit, OnDestroy {

  formName: string;
  name: string;
  personaliseMessage = false;
  subscriptions: SubscriptionCollection = {};

  constructor(public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.subscriptions.routeParams = this.route.queryParamMap.subscribe(params => {
      this.formName = params.get('form');
      this.name = params.get('name');
      if(this.formName && this.name){
        this.personaliseMessage = true;
      }
      else { this.personaliseMessage = false;}
    });
  }

  ngOnDestroy(): void {
    SubscriptionManager.unsubscribe(this.subscriptions);
  }

}
