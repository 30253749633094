<div class="w-100 d-flex flex-column app-bg-darker text-light s-nav">
  <div class="d-flex flex-row align-items-center justify-content-between w-100">
    <img src="assets/new-logo-w.png" width="180px" />
    <span class="close-button" (click)="closeNav.emit()">
      <span>&times;</span>
    </span>
  </div>

  <mat-accordion
    #acc="matAccordion"
    displayMode="flat"
    class="app-bg-darker text-light m-2"
  >
    <mat-expansion-panel class="app-bg-darker text-light">
      <mat-expansion-panel-header>
        <b>Residential Services</b>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p class="my-2" *ngFor="let service of residential">
          <a
            class="link"
            routerLink="{{ service.link }}"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            &middot; {{ service.title }}</a
          >
        </p>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="app-bg-darker text-light">
      <mat-expansion-panel-header>
        <b>Commercial Services</b>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p class="my-2" *ngFor="let service of commercial">
          <a class="link" routerLink="{{ service.link }}">
            &middot; {{ service.title }}</a
          >
        </p>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="app-bg-darker text-light">
      <mat-expansion-panel-header>
        <b class="primary-text">How Can We Assist You?</b>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p class="my-2" *ngFor="let service of assist">
          <a
            class="link"
            routerLink="{{ service.link }}"
            [ngClass]="service.linkClass || ''"
          >
            &middot; {{ service.title }}</a
          >
        </p>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel class="app-bg-darker text-light">
      <mat-expansion-panel-header>
        <b>About Us</b>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <p class="my-2" *ngFor="let service of about">
          <a class="link" routerLink="{{ service.link }}">
            &middot; {{ service.title }}</a
          >
        </p>
      </ng-template>
    </mat-expansion-panel>

    <!--
    <mat-expansion-panel class="app-bg-darker text-light" [hideToggle]="true">
      <mat-expansion-panel-header>
          <b routerLink="/tactical-security-packages">Security Packages</b>
      </mat-expansion-panel-header>
    </mat-expansion-panel> -->

    <!-- <mat-expansion-panel class="app-bg-darker text-light" [hideToggle]="true">
      <mat-expansion-panel-header>
          <b routerLink="/forms/switch-service-providers">Switch Providers</b>
      </mat-expansion-panel-header>
    </mat-expansion-panel> -->

    <mat-expansion-panel class="app-bg-darker text-light" [hideToggle]="true">
      <mat-expansion-panel-header>
        <b routerLink="/contact-us">Contact Us</b>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>

  <div
    class="container-fluid justify-content-center text-center app-bg-darker mt-auto text-light footer"
  >
    <p class="fw-bold">Connect With Us</p>
    <a
      class="link m-1"
      href="https://www.facebook.com/pages/category/Business-Service/Sniper-Security-1699362866760697/"
    >
      <i class="bi-facebook social-icon"></i>
    </a>
    <a
      class="link m-1"
      href="https://www.instagram.com/snipersecuritysolutions"
    >
      <i class="bi-instagram social-icon"></i>
    </a>
    <p class="small-text">
      <a class="link contact" href="tel:+27216970573">
        <i class="bi bi-telephone-fill p-1"></i>
        021 697 0573 </a
      ><br />
      <a class="link contact" href="mailto:info@snipersecurity.co.za">
        <i class="bi bi-envelope-fill-closed p-1"></i>
        info@snipersecurity.co.za
      </a>
    </p>
    <a
      class="link mb-2 mx-auto d-flex justify-content-center"
      href="https://riskprofiler.co.za"
      target="_blank"
    >
      <div
        style="width: fit-content"
        class="d-flex flex-column align-items-center border border-2 rounded border-danger p-1"
      >
        <img src="assets/riskprofiler-logo.png" height="50px" alt="" />
        <div style="font-size: 0.7rem; text-decoration: none">
          Riskprofiler Certified
        </div>
      </div>
    </a>
    <p class="copyright">
      &copy; {{ year }} Sniper Security Solutions. All rights reserved.
    </p>
  </div>
</div>
