import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-alarm-cctv-integration',
  templateUrl: './business-alarm-cctv-integration.component.html',
  styleUrls: ['./business-alarm-cctv-integration.component.css']
})
export class BusinessAlarmCctvIntegrationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
