<div class="row page-row align-items-center mb-4" [ngClass]="{'justify-content-end':(alignment && alignment === 'end')}"
[ngStyle]="screenService.onMobile ? mobileStyle : panelStyle">
  <div class="col-md-7">
    <div class="d-flex flex-column w-100 p-4 left-border-panel text-white">
      <h1 class="text-uppercase display-5">
        Frequently asked questions about<br>
        <span class="accent-text">{{about}}</span>
      </h1>

      <div *ngFor="let q of faqs; let i = index" class="my-2">
        <p class="question header-font large-text" [ngClass]="i===selected ? 'selected':''" (click)="onQuestionClick(i)">
          {{q.question}}
        </p>
        <p class="text-white answer" *ngIf='i===selected'>{{q.answer}}</p>
      </div>

    </div>
  </div>
</div>
