<div class="container-fluid">
  <!-- landing -->
  <app-panel
  titleStart="Alarm Monitoring"
  titleEnd="& CCTV Integration"
  [titleBreak]='false'
  subtitle="Protecting you and your business"
  [landing]='true'
  body="Say goodbye to false alarm activations, as our controllers are now able to view and investigate your premises in real time."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1.jpg?alt=media&token=3e0acb87-cc30-4f88-85f2-71cd2dd09119'|panelStyle)">
  </app-panel>



  <!-- 1 -->
  <div class="row page-row align-items-center first">
  </div>

  <!-- 2 -->
  <app-panel
  titleStart="criminals are no match for"
  titleEnd="our surveillance technology"
  [alignment]="'end'"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Falarm-cctv-2.jpg?alt=media&token=9483b908-79cb-4da7-90a6-72d4face9d25'|panelStyle:'end')">
  </app-panel>



</div>

