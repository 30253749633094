import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-access-control',
  templateUrl: './business-access-control.component.html',
  styleUrls: ['./business-access-control.component.css']
})
export class BusinessAccessControlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
