<div class="container-fluid">
  <!-- 1 -->
  <app-panel
    [titleStart]="'Luxury'"
    [titleEnd]="'Armoured Vehicles'"
    [landing]="true"
    body="<p>Specialising in ground transportation, Sniper combines key knowledge in executive protection to provide a premium protection service.<br>
      Catering perfectly for the high-end market, Sniper specialises in providing smallish groups travelling in convoy with the personalised service they require.
    </p>
    <p>We can provide you with armoured vehicles, close protection officers (CPO’s) as well as co-coordinators to fulfil any job criteria. Our highly trained security officers have the key local knowledge and experience to ensure discreet privacy and safety which includes support from our 24 hour control room.</p>
    <p>Please complete the link below and one of our close protection specialists will call you back.</p>
    "
    [button]='{text: "Request a Quote",link: "/armoured-vehicles-request"}'

    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2Farmoured-vehicle.jpeg?alt=media&token=c1b8756c-67f9-4a17-9417-3a11db39c4ed'|panelStyle:'start': {'background-position': 'center, center top 10px'})"
    >
  </app-panel>

  <div>
    <img src='https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2Farmoured-vehicle-3.jpeg?alt=media&token=9ed67c5a-4e5d-4ded-b15b-e9e4060da667' class="w-100">
  </div>

  <div class="text-light p-4">
    <h1 class="text-center display-5"><span class="primary-text">Armour</span> Protection Levels</h1>
    <div class="row">
      <div class="col-md-6">
        <img class="w-100" src='https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2Fb4-armour.gif?alt=media&token=584ad344-e96d-44f1-a124-cf673bcffbb2'>
        <p class="header-font display-7 text-center">Level B4 Armour</p>
      </div>
      <div class="col-md-6">
        <img class="w-100" src='https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2Fb6-armour.gif?alt=media&token=9081d354-8dc9-4ef4-b555-f542bf9bea8c'>
        <p class="header-font display-7 text-center">Level B6 Armour</p>
      </div>
    </div>
  </div>

<div class="d-flex w-100 align-items-center justify-content-center page-row">
  <div class="w-lg-75">
  <h1 class="text-center display-5">BMW 730d<span class="primary-text"> B4</span><span> ARMOURED VEHICLE</span></h1>
    <ngb-carousel #carousel [interval]="3000" [pauseOnHover]="true">
      <ng-template ngbSlide *ngFor="let img of images; index as i">
        <div class="carousel-image">
          <img [src]="img">
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>

<div class="d-flex flex-column w-100 align-items-center p-2">
  <video  controls class="w-lg-75">
    <source  src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fassist%2Fbmw_audio.mp4?alt=media&token=e5efd0b1-986c-4f66-b7d5-4c1f6bd435e5">
  </video>
</div>




