
<div class="container-fluid">
  <!-- 0 -->
  <app-panel
  titleStart="Tactical Response"
  titleEnd="Protecting you and your business"
  [landing]='true'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2Fsniper_golf_web.jpg?alt=media&token=bad76211-143e-4373-a4bc-031ea9dd3237'|panelStyle)">
  </app-panel>



  <!-- 1 -->
  <app-panel
  titleStart="Your safety"
  titleEnd="is non-negotiable"
  [titleBreak]='false'
  body="We are more than just a security company. <b>WE CARE.</b><br>
  At Sniper we have a simple philosophy that consists of an interactive management style that is priceless. We treat every business as unique and will build a team around you to deliver innovative solutions and advice that you can trust. What really sets us apart is that we are not afraid to question the ‘norm’, and to find innovative solutions to protect your business."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2FDSC01492_web.jpg?alt=media&token=469212a1-c76e-4053-9230-bfde6d55f862'|panelStyle)">
  </app-panel>


  <!-- 2 -->
  <app-panel
  titleStart="Our tactical response"
  titleEnd="services include:"
  [titleBreak]='false'
  [alignment]="'end'"
  body="<ul>
    <li>24-Hour Control Room that is fully equipped with the latest technology.</li>
    <li>A Control room that engages audibly with suspects and experienced staff who will coordinate and dispatch Tactical Support teams to your premises with ease.</li>
    <li>Highly trained and well-equipped tactical security officers.</li>
    <li>GPS tracking in every tactical response vehicle.</li>
  </ul>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2FDSC01119_web.jpg?alt=media&token=9abbe33e-4663-422d-a616-add170dba74d'|panelStyle:'end')">
  </app-panel>


  <!-- 3 -->
  <app-panel
  titleStart="Our state-of-the-art control room"
  titleEnd="is ready to assist you with all your emergency calls"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1.jpg?alt=media&token=3e0acb87-cc30-4f88-85f2-71cd2dd09119'|panelStyle)">
  </app-panel>


  <!-- 4 -->
  <app-panel
  titleStart="trained  tactical officers"
  titleEnd="ready to respond when it matters most"
  [alignment]="'end'"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2Ftactical_support_web.jpg?alt=media&token=79201814-8a1c-40ca-8610-4251f242a668'|panelStyle:'end')">
  </app-panel>


  <!-- 5 -->

<app-action-panel
  titleStart="Contact"
  titleEnd="Us"
  [titleBreak]="false"
  body=" Our security products are built with you-and your premises-in mind. Sniper's expert security technicians will consult with you
 and then design and install a system that allows you to realize the benefits of our tactical support service."
 [btnLink]="'/forms/quote'"
 >

</app-action-panel>



</div>
