<div class="container-fluid">
  <!-- 0 -->

  <app-panel
  [titleStart]="'Control your alarm system'"
  [titleEnd]="'from your mobile phone'"
  [landing]="true"
  body="Redefine how you manage your home when you are there and how you manage it when you are away."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FAlarm%20Arm%20Status%202.png?alt=media&token=8f3592d9-cf41-400e-ba1c-a4fb64bea088'|
  panelStyle:'start':{ 'background-color': 'white', 'background-position': 'right top 60px', 'background-size': 'contain'})"
  >

  </app-panel>

  <!-- 1 -->
  <div class="row page-row first align-items-center justify-content-center app-bg">
    <div class="col-12">
      <div class="m-2 d-flex flex-column align-items-center text-center">
        <h2 class="text-uppercase display-5">Monitor your home from anywhere, <br><span class="accent-text">at any time.</span></h2>
        <p>Sniper's Alarm app enables you to take control of your alarm system. The Alarm app is easy to use and control. You can remotely arm and disarm the system conveniently from your phone, no matter where you are. We know you do not want an alert notification every time your child or pet walks past a motion sensor, so our technical department can easily adjust the system settings to make the sensors less sensitive while still maintaining its proper function in case a real emergency occurs. With a burglar alarm app from Sniper, you will be able to monitor your home remotely.
        </p>
        <img class="animation" src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fapp.gif?alt=media&token=3790ffa8-4516-4dea-bca3-bd64b2eecd27">

      </div>
    </div>
  </div>





  <!-- 2-->
  <app-faq-panel
    about="our alarm system app"
    [faqs]='faq'
    [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fold-lady.jpg?alt=media&token=9a0f4e01-ba4b-4c94-9261-ca624d7aaa53'|panelStyle)">
  >

  </app-faq-panel>


  <!-- 3 -->
  <app-action-panel
  titleStart="Contact"
  titleEnd="Us"
  [titleBreak]="false"
  body=" Our security products are built with you-and your family-in mind. Sniper's expert security technicians will consult with you and then design and install a system that allows you to realize the benefits of our services."
 [btnLink]="'/forms/quote'"
 >

</app-action-panel>



</div>
