import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { FileAttachment } from "../models/FileAttachment";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  attachments: { [key: string]: File[] } = {};

  constructor(private fireStorage: AngularFireStorage) {}

  pushAttachment(files: File[], id: string) {
    this.attachments[id] = files;
  }

  async uploadAttachments(key: string): Promise<FileAttachment[]> {
    const files = this.attachments[key] ?? [];
    const attachments: FileAttachment[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.substring(file.name.lastIndexOf("."));
      const filePath = `forms/attachments/${Math.random()
        .toString(36)
        .substring(2)}${extension}`;
      console.log({ filePath });
      const url = await this.upload(file, filePath);
      attachments.push({ url, name: file.name, key });
    }
    return attachments;
  }

  /**
   * @param file The file to be uploaded
   * @param fullPath The storage path to store the file
   * @returns The url of where the file was placed or null if the file was not uploaded
   */
  async upload(file: File, fullPath: string) {
    // create a reference to the storage bucket location
    const ref = this.fireStorage.ref(`${fullPath}`);

    // the put method creates an AngularFireUploadTask
    await ref.put(file);
    return (await ref.getDownloadURL().toPromise()) as string;
  }

  /**
   * @param file The file to be uploaded
   * @param fullPath The storage location or 'parent directory' to store the file
   * @returns A promise with the upload task for this operation
   */
  uploadTask(file: File, fullPath: string) {
    // the put method creates an AngularFireUploadTask
    // create a reference to the storage bucket location
    const ref = this.fireStorage.ref(fullPath);
    return ref.put(file);
  }

  /**
   * Delete the file stored at the given url
   *
   * @param url URL of the file
   */
  deleteFile(url: string) {
    return this.fireStorage.storage.refFromURL(url).delete();
  }

  /**
   * Get a reference to a file in storage
   *
   * @param path the path of the file
   * @returns the reference to the file in storage
   */
  getRef(path) {
    return this.fireStorage.ref(path);
  }

  getDownloadURL(collection: string, formID: string) {
    return this.fireStorage
      .ref(`forms/submissions/${collection}/${formID}.pdf`)
      .getDownloadURL();
  }

  //#region Helpers

  /**
   * Verify the size of a file and notify the user if the file is larger thanthe size argument
   *
   * @param file the file that requires size verfication
   * @param maxSize the maximum allowed size in MB
   * @returns true if the file size is less than maxSize
   */
  verifyFileSize(file: File, maxSize: number) {
    return this.verifyMultipleFileSize([file], maxSize)
  }

  /**
   * Verify the size of a list of files
   *
   * @param files the list of files that require size verfication
   * @param maxSize the maximum allowed size in MB
   * @param each if `true` check that each file is <= `maxSize` otherwise check that the total of all files is <= `maxSize`
   * @returns true if the file size is less than maxSize
   */
  verifyMultipleFileSize(files: File[], maxSize: number, each = false) {
    if (each) {
      return files.some((file) => !this.verifyFileSize(file, maxSize));
    }

    const fileSize = files
      .map((file) => file.size)
      .reduce((previous, current) => previous + current,0);
    return fileSize <= this.mbToBytes(maxSize);
  }

  private mbToBytes(megabytes: number){
    return megabytes * 1048576
  }

  getRandomName(extension: string) {
    return `${Math.random().toString(36).substring(2)}${extension}`;
  }

  getExtension(fileName: string) {
    return fileName.substring(fileName.lastIndexOf("."));
  }
  private _deleteFromPath(filePath: string) {
    return this.fireStorage.ref(filePath).delete();
  }
  //#endregion
}
