/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { OfferedService } from "../models/offered-service.model";

@Injectable({
  providedIn: "root",
})
export class OffersService {
  residential: OfferedService[] = [];
  commercial: OfferedService[] = [];
  assist: OfferedService[] = [];
  about: OfferedService[] = [];
  switch: OfferedService[] = [
    { title: "Switch Providers", link: "/forms/switch-service-providers" },
  ];
  tactical: OfferedService[] = [
    { title: "Security Packages", link: "tactical-security-packages" },
  ];

  /**
   * The main sections of the website
   */
  public sections: { id: string; value: string }[] = [];

  constructor() {
    this.setResidentialServices();
    this.setCommercialServices();
    this.setAssistServices();
    this.setAboutServices();
    this.setSections();
  }

  private setResidentialServices() {
    this.residential = [
      {
        title: "All Residential Services",
        link: "/residential",
      },

      {
        title: "Alarm Monitoring and CCTV Integration",
        message:
          "24/7/365 monitoring centre staffed with highly trained and passionate alarm controllers ready to assist you with all emergencies.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1.jpg?alt=media&token=3e0acb87-cc30-4f88-85f2-71cd2dd09119",
        link: "/residential/alarm-monitoring",
      },
      {
        title: "Burglar Alarm Systems",
        message:
          "We design, install and maintain the latest burglar alarm technology.",
        image: "assets/residental_services/access-control.jpg",
        link: "/residential/burglar-alarm-systems",
      },
      {
        title: "Access Control",
        message:
          "We install specialist access control systems tailor made to your security needs.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FAccess%20control%20787878.jpg?alt=media&token=82a9fa03-91ba-4de4-8272-ba17082e011d",
        link: "/residential/access-control",
      },
      {
        title: "Alarm System App",
        message:
          "We design, install and maintain the latest access technology to empower our clients to have total control over their alarm systems.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fphone-menu.jpg?alt=media&token=6b905309-095f-4725-a47a-d2872a7ffb70",
        link: "/residential/alarm-app",
      },
      {
        title: "Access Control App",
        message:
          "We design, install and maintain the latest access technology to empower our clients to have total control over their access control systems.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fphone-cafe.jpg?alt=media&token=7ec999b6-25ae-4b68-9726-ee3973104869",
        link: "/residential/access-app",
      },
      {
        title: "CCTV",
        message:
          "We design, install and maintain CCTV sytsems tailor made to your environment. Every home is unique and has specific requirements.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcctv.jpg?alt=media&token=08b544b4-d5e2-4aa1-b4f4-ce0f04e1f2f2",
        link: "/residential/security-cameras",
      },
      {
        title: "Tactical Response",
        message:
          "We have dedicated tactical officers ready to respond to your emergency calls. Our tactical officers are fully kitted and equipped with ladders to access your home.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2Ftactical_support_web.jpg?alt=media&token=79201814-8a1c-40ca-8610-4251f242a668",
        link: "/residential/tactical-response",
      },
      {
        title: "Technical Support",
        message:
          "We boast a full technical support service department, available to install or maintain your home security systems.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Finstallation.jpg?alt=media&token=4684b23f-db30-4b60-96cc-83618cea1943",
        link: "/residential/technical-support",
      },

      {
        title: "Electric Fencing",
        message: "We design, install and maintain electric fencing.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Felectric-fence.jpg?alt=media&token=035cad1f-a2dc-4ec5-8ef9-32b839a249a4",
        link: "/residential/electric-fencing",
      },
      {
        title: "Outdoor Motion Sensors",
        message:
          "We design, install and maintain outdoor motion sensors tailor made to your environment. Installing the incorrect outdoor sensors can be costly and  jeopardize your security.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FOutdoor%20Sensor.png?alt=media&token=59494190-11f5-4564-895f-8928a2506e49",
        link: "/residential/outdoor-sensors",
      },
      {
        title: "IP Intercom Systems",
        message: `Getting full control over an entrance, whether in a single home,
        a block of flats or a business is simple and stylish with the next generation of video intercom. `,
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fip-intercom.jpeg?alt=media&token=eb11dd6d-3116-46a1-8928-cf7446dc9bf2",
        link: "/residential/ip-intercom",
      },
      {
        title: "Gate and Garage Door Automation Repairs and Maintenance",
        message: "",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fgate.jpg?alt=media&token=b34e2029-abce-49ac-bfde-129b6ccf4775",
        link: "/residential/gates",
      },
      {
        title: "Cyber Guard Community Surveillance",
        message: "",
        link: "/cyberguard-community",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FHikvision%20CCTV%20Surveillance%201.jpg?alt=media&token=259f5b55-502c-40c2-af36-803946ba5d3d",
      },
      {
        title: "Emergency Guarding",
        message: "",
        link: "/emergency-guarding",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Femergency_guarding.jpg?alt=media&token=ce93bfc4-fd5e-422d-ad68-12a3c4c403ae",
      },

      {
        title: "Personalised Protection",
        message: "Professional Private Protection",
        link: "/personal-protection",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fcommon%2Fpersonalised_protection._thumb.jpg?alt=media&token=aae059fd-2fe1-4438-9455-d45c3dccf19e",
      },
    ];
  }

  private setCommercialServices() {
    this.commercial = [
      {
        title: "All Commercial Services",
        link: "/commercial",
      },
      {
        title: "Burglar Alarm Systems",
        message:
          "We design, install and maintain the latest burglar alarm technology.",
        image: "assets/security_dial.jpg",
        link: "/commercial/burglar-alarm-systems",
      },
      {
        title: "Tactical Response",
        message:
          "We have dedicated tactical officers ready to respond to your emrgency calls. Our tactical officers are fully kitted out and equipped with ladders to access your home.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2Ftactical_support_web.jpg?alt=media&token=79201814-8a1c-40ca-8610-4251f242a668",
        link: "/commercial/tactical-response",
      },

      {
        title: "Alarm Monitoring",
        message:
          "24/7/365 monitoring centre staffed with highly trained and passionate alarm controllers ready to assist you with all emergencies.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1.jpg?alt=media&token=3e0acb87-cc30-4f88-85f2-71cd2dd09119",
        link: "/commercial/alarm-monitoring",
      },
      {
        title: "Technical Support",
        message:
          "We boast a full technical support service department, available to install or maintain your busniess security systems.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Finstallation.jpg?alt=media&token=4684b23f-db30-4b60-96cc-83618cea1943",
        link: "/commercial/technical-support",
      },

      {
        title: "Cyber Guarding",
        message: "",
        link: "/cyberguard-guarding",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcyberguard-card.jpg?alt=media&token=21938db1-f848-407b-8f9e-70e0eeb9ff9b",
      },
      {
        title: "License Plate Recognition",
        message: "",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Flpr.jpg?alt=media&token=d3541607-a829-4277-8d69-31d8c4943649",
        link: "/commercial/lpr",
      },
      {
        title: "CCTV On Your Mobile",
        message: "",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Flive-cctv-bus.jpg?alt=media&token=ef434396-a981-4158-a325-6387ab15a0d0",
        link: "/commercial/live-cctv-mobile",
      },

      {
        title: "CCTV Systems",
        message:
          "We design, install and maintain CCTV sytsems tailor made to your environment. Every business is unique and has specific requirements.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcctv.jpg?alt=media&token=08b544b4-d5e2-4aa1-b4f4-ce0f04e1f2f2",
        link: "/commercial/cctv-systems",
      },
      {
        title: "Access Control",
        message:
          "We design, install and maintain the latest access technology to empower our clients to have total control over their access control systems.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FAccess%20control%20787878.jpg?alt=media&token=82a9fa03-91ba-4de4-8272-ba17082e011d",
        link: "/commercial/access-control",
      },
      {
        title: "Video Intercom Systems",
        message: `Getting full control over an entrance, whether in a single home,
        a block of flats or a business is simple and stylish with the next generation of video intercom. `,
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fip-intercom.jpeg?alt=media&token=eb11dd6d-3116-46a1-8928-cf7446dc9bf2",
        link: "/commercial/video-intercom",
      },
      {
        title: "Emergency Guarding",
        message: "",
        link: "/emergency-guarding",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fabout%2Femergency-guarding.jpg?alt=media&token=10fbc23c-9089-4f53-9020-f6f02b780062",
      },

      {
        title: "Electrified Fencing",
        message: "Your first line of defence.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2FelectricFence.jpg?alt=media&token=7c4d9b54-f6bc-4b29-9a21-a9b12f85fcd8",
        link: "/commercial/electrified-fencing",
      },

      {
        title: "Personalised Protection",
        message: "Professional Private Protection",
        link: "/personal-protection",
        image:
          "https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fcommon%2Fpersonalised_protection._thumb.jpg?alt=media&token=aae059fd-2fe1-4438-9455-d45c3dccf19e",
      },
    ];
  }

  private setAssistServices() {
    this.assist = [
      { title: "I need a quote", link: "/forms/quote" },
      { title: "Technical Booking", link: "/forms/technical-booking" },
      {
        title: "Switch Service Providers",
        link: "/forms/switch-service-providers",
      },
      // { title: "Security Packages", link: "tactical-security-packages" },
      { title: "Emergency Guarding", link: "/emergency-guarding" },
      { title: "I Am Going on holiday", link: "/forms/holiday" },
      { title: "Special Patrols", link: "/forms/special-patrols", linkClass: ["text-success", "fw-bold"] },
      {
        title: "Private Technician Assistance",
        link: "/forms/private-tech-form",
      },
      { title: "Luxury Armoured Vehicles", link: "/luxury-armoured-vehicles" },
      { title: "Career Opportunities", link: "/careers" },
      { title: "General Enquiries", link: "/general-enquiries" },

      {
        title: "Contract Form",
        link: "/forms/contract",
        linkClass: "primary-text",
      },
      {
        title: "Alarm Link Up Protocol",
        link: "/forms/alarm-link-up",
        linkClass: "primary-text",
      },
      {
        title: "Important Protocols",
        link: "/forms/important-company-protocols",
        linkClass: "primary-text",
      },
    ];
  }

  private setAboutServices() {
    // TODOD finish implementing these forms
    this.about = [
      { title: "About Us", link: "/about" },
      { title: "Why Choose Us", link: "/why-choose-us" },
      { title: "Areas We Cover", link: "/areas" },
      { title: "Compliance", link: "/compliance" },
      { title: "Policies & Procedures", link: "/policies" },
      { title: "Recruitment Policy", link: "/recruitment-policy" },
      { title: "Intern Crew", link: "/interns" },
      { title: "Social", link: "/social" },
      { title: "UCT MBA Business Analysis Programme", link: "/uct-mba" },
      { title: "Rondebosch Police Partnership", link: "/police-partnership" },
      { title: "Rate Our Service", link: "/rate-our-service" },
    ];
  }

  private setSections() {
    this.sections.push({ id: "home", value: "Residential" });
    this.sections.push({ id: "business", value: "Commercial" });
    this.sections.push({ id: "assist", value: "How Can We Assist You?" });
    this.sections.push({ id: "about", value: "About Sniper" });
  }
}
