<div class="container-fluid app-bg-darker footer">
  <nav class="w-100 p-2">
  <mat-accordion #acc="matAccordion" displayMode='flat' multi class="app-bg-darker">
    <div class="row justify-content-around">
      <div class="col-lg-3">
        <mat-expansion-panel class="app-bg-darker" (opened)="toggleAccordion(true)" (closed)="toggleAccordion(false)">
          <mat-expansion-panel-header>
            <span class="section-header">Residential Services</span>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <p class="my-lg-2 my-1" *ngFor="let service of residential">
              <a class="link" routerLink="{{service.link}}">{{service.title}}</a>
            </p>
          </ng-template>
        </mat-expansion-panel>
      </div>

      <div class="col-lg-3">
        <mat-expansion-panel class="app-bg-darker" (opened)="toggleAccordion(true)" (closed)="toggleAccordion(false)">
          <mat-expansion-panel-header>
            <span class="section-header">Commercial Services</span>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <p class="my-lg-2 my-1" *ngFor="let service of commercial">
              <a class="link" routerLink="{{service.link}}">{{service.title}}</a>
            </p>
          </ng-template>
        </mat-expansion-panel>
      </div>

      <div class="col-lg-3">
        <mat-expansion-panel class="app-bg-darker" (opened)="toggleAccordion(true)" (closed)="toggleAccordion(false)">
          <mat-expansion-panel-header>
            <span class="section-header">How Can We Assist You?</span>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <p class="my-lg-2 my-1" *ngFor="let service of assist">
              <a class="link" routerLink="{{service.link}}">{{service.title}}</a>
            </p>
          </ng-template>
        </mat-expansion-panel>
      </div>

      <div class="col-lg-3">
        <mat-expansion-panel class="app-bg-darker" (opened)="toggleAccordion(true)" (closed)="toggleAccordion(false)">
          <mat-expansion-panel-header>
            <span class="section-header">About Us</span>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <p class="my-lg-2 my-1" *ngFor="let service of about">
              <a class="link" routerLink="{{service.link}}">{{service.title}}</a>
            </p>
          </ng-template>
        </mat-expansion-panel>

      </div>
    </div>
    <div class="row">
      <div class="col-12 container-fluid justify-content-center text-center">
      <hr>
      <p class="section-header">Connect With Us</p>
      <a class="link m-2" href="https://www.facebook.com/pages/category/Business-Service/Sniper-Security-1699362866760697/">
        <i class="bi bi-facebook social-icon" ></i>
      </a>
      <a class="link m-2" href="https://www.instagram.com/snipersecuritysolutions">
        <i class="bi bi-instagram social-icon" ></i>
      </a>
      <p class="small-text">
        <a class="link contact" href="tel:+27216970573">
          <i class="bi bi-telephone-fill p-2"></i>
          021 697 0573
        </a><br>
        <a class="link contact" href="mailto:info@snipersecurity.co.za">
          <i class="bi bi-envelope-fill p-2"></i>info@snipersecurity.co.za
        </a>

      </p>
     </div>
    </div>
  </mat-accordion>
  </nav>
  <p class="copyright text-center"> &copy; {{date}} Sniper Security Solutions. All rights reserved.</p>
</div>
