import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-electric-fencing',
  templateUrl: './electric-fencing.component.html',
  styleUrls: ['./electric-fencing.component.css']
})
export class ElectricFencingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
