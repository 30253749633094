/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cyberguard-guarding',
  templateUrl: './cyberguard-guarding.component.html',
  styleUrls: ['./cyberguard-guarding.component.css']
})
export class CyberguardGuardingComponent implements OnInit {
  videos: {title: string; url: string; poster?: string}[] = [];
  constructor() { }

  ngOnInit(): void {
    this.getVideos();
  }

  getVideos(){
    this.videos.push(
      {
        title: 'IF THERE IS ANY MOVEMENT WE WILL DETECT AND MONITOR IT',
        url: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/cg%2Fmotion.mp4?alt=media&token=62547de8-34c3-457e-8c18-08d3a4d06900'
      },

      {
        title: 'CYBER GUARD MONITORING SUSPICIOUS BEHAVIOUR',
        url: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/cg%2Fsuspicious-behaviour.mp4?alt=media&token=ed3e1b93-a649-4ce2-baf2-36a3f811b386'
      },
      {
        title: 'CYBER GUARD MONITORING A SUSPICIOUS LOITERER',
        url: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/cg%2FsuspiciousLoiterer.mp4?alt=media&token=0cc214d9-24fe-4362-967a-414906c1e461'
      },
      {
        title: 'CYBER GUARD MONITORING THE HOMELESS',
        url: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/cg%2FmonitoringTheHomeless.mp4?alt=media&token=3e1a58ae-a9c9-470b-9d79-a2d8596e2982'
      },
      {
        title: 'CCTV CONTROL ROOM',
        url: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/videos%2Fcontrol_room.mp4?alt=media&token=a2190e28-69bf-47a6-ab6d-40d393bda218',
        poster: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1.jpg?alt=media&token=3e0acb87-cc30-4f88-85f2-71cd2dd09119'
      },
      {
        title: 'CYBER GUARD STREET CCTV SURVEILLANCE DAYTIME',
        url: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/cg%2FsurveillanceDaytime.mp4?alt=media&token=c7f3ed83-670f-4d19-9d78-2b6da6a53dd2'
      },
      {
        title: 'CYBER GUARD STREET CCTV SURVEILLANCE NIGHT TIME',
        url: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/cg%2FsurveillanceNightTime.mp4?alt=media&token=07689468-0a6c-4f9c-9a10-87af3d5e7420'
      },
      {
        title: 'CYBER GUARD STREET CCTV VAGRANT MONITORING',
        url: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/cg%2FvagrantMonitoring.mp4?alt=media&token=abe24193-f52e-4ddb-9d0d-75d4f4bdfdb2'
      },
      {
        title: 'CYBER GUARD KEEPING A WATCHFUL EYE OVER A SNIPER TACTICAL OFFICER WHILE INVESTIGATING A SUSPICIOUS VEHICLE',
        url: 'https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/cg%2FcyberGuardSniperOfficer.mp4?alt=media&token=a4c2dc75-6efa-4036-bc5e-9c294e82534a'
      },
    );
  }

}
