import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appHover]'
})
export class HoverDirective {

  @Output() hover = new EventEmitter<boolean>();
  constructor() { }

  @HostListener('mouseenter') onMouseEnter() {
    this.hover.emit(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hover.emit(false);
  }

}
