import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-interns",
  templateUrl: "./interns.component.html",
  styleUrls: ["./interns.component.css"],
})
export class InternsComponent implements OnInit {
  interns: { name: string; title?: string; comment: string, picture: string, alignment?: "start"|"end", intro?: string  }[] = [
    {
      name: "Mu’aath Jattiem",
      title: 'Accounts Administrator',
      comment:
        "\"As an intern I was thrown into the deep end in the very early stages of my internship which opened up untapped potential within me.\
    I’ve learnt to be more outspoken but tactful at the same time. The drive to persevere and realize that not every situation requires an immediate and reckless response but rather a more methodical response to a situation. Being a leader doesn’t mean carrying the weight of the business on your shoulders all the time, rather, delegating tasks and responsibilities to your team members. Delegating also shows that you trust your team and it teaches them responsibility.\"",
    picture: 'assets/interns/muaath.jpg',
    intro: '"As an intern I was thrown into the deep end in the very early stages of my internship which opened up untapped potential within me."'

    },
    {
      name: "Lela Fosi",
      title: 'Accounts Intern',
      comment:
        "\"I have learnt that time and diary management is very important.<br> At first I didn't know what to write in my diary, but now I can’t work without it.<br>\My manager has taught me how to work on different accounting software, such as XERO, Patriot and Billdozer.\
        <br>I have also learnt how to do remittance slips. At first I was terrified of calling clients, but now I am more confident.\"",
        picture: 'assets/interns/lela.jpg',
        intro: '"I have learnt that time and diary management is very important.<br> At first I didn`t know what to write in my diary, but now I can’t work without it."'

    },
    {
      name: "Lindsay May",
      title: 'Technical Administration Intern',
      comment:
        "\"I’ve learnt to be confident and not to be scared of taking on new tasks. Everyday I feel that I am growing. Communication plays a key role in the business world. It teaches you how to interact with clients and colleagues. Good communication also improves ones writing skills which is a good asset in the business world. Lastly, I’ve learnt the basics of how all the departments work.\"",
        picture: 'assets/interns/lindsay.jpg',
        intro: '"I’ve learnt to be confident and not to be scared of taking on new tasks. Everyday I feel that I am growing. Communication plays a key role in the business world."',
        alignment: 'end'
    },


  ];

  programDetails = "The aim of the Sniper internship programme is to get the young learners job ready and to equip them with the necessary foundation and work ethic culture.<br>\
  Sniper plays a critical role in the development of the learners and nurtures critical skills such as teamwork, communications and paying attention to detail.\
  We expose the learners to the work environment, expectations and performance within the various departments.<br>\
  We focus, enhance and expand the learners' knowledge of a particular department.<br>\
  We expose the learners’ to professional role models and mentors who will provide them with necessary support and inculcate a culture of service delivery and accountability.<br>"

  coaching = "<p>We like to ensure that our interns grow in both their professional and personal lives.</p>\
  <p>We provide the interns with quality learning experiences that have a massive impact on their growth development.</p>\
  <p>Traditionally, the focus of internships was purely focused on technical skills transfer. However, we help learners become well rounded individuals by facilitating casual workshops to develop their soft skills.</p>\
  <p>We use a more holistic and caring approach to intern development, working to improve their soft skills and character development.</p>\
  <p>Tactfulness is important. An intern who becomes aware of gaps in their skills and aptitudes may find such a realization to be temporarily humbling. With self-reflection and a considerate analysis by a mentor, the intern will eventually increase their competence and enjoy a feeling of increased self-improvement. Group reflection sessions helps interns develop a continuous improvement mindset.</p>"
  constructor() {}

  ngOnInit(): void {}
}
