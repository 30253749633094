<div class="container-fluid">

  <!-- 0 -->
  <app-panel
  titleStart="Tactical Response"
  titleEnd="Protecting you and your family"
  [landing]='true'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fcouple%2Bdog.jpg?alt=media&token=e5308051-2ded-47bf-bf14-dc3172fd8781'|panelStyle)">
  </app-panel>



  <!-- 1 -->
  <app-panel
  titleStart="You get"
  titleEnd="what you pay for."
  [titleBreak]='false'
  body="At Sniper security our main priority and focus is the safety of our clients.  This simple philosophy consists of highly trained tactical support teams, coupled with an interactive management style. When it comes to your safety ,make sure you choose a tactical response company with a reputable track record and who are extremely meticulous about systems and processes."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2FDSC01222.jpg?alt=media&token=6f056fb5-ca71-45f1-aee7-073c63102b09'|panelStyle)">
  </app-panel>


  <!-- 2 -->
  <app-panel
  titleStart="Our tactical response"
  titleEnd="services include:"
  [titleBreak]='false'
  [alignment]="'end'"
  body="<ul>
    <li>24-Hour Control Room that is fully equipped with the latest technology. </li>
    <li>A Control room with monitoring staff that engages audibly with suspects and are able to coordinate and dispatch Tactical Support teams to your premises with ease.</li>
    <li>Highly trained and well-equipped tactical security officers.</li>
    <li>GPS tracking in every tactical response vehicle.</li>
  </ul>"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2FDSC01310_web.jpg?alt=media&token=ba9bd636-0b82-48e4-88a8-c18e8819ba66'|panelStyle:'end')">
  </app-panel>


  <!-- 3 -->
  <app-panel
  titleStart="Our state-of-the-art control room"
  titleEnd="is ready to assist you with all your emergency calls."
  body="It is of critical importance that our controllers have a visual of the perimeter of your home."
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/pages%2Fa-m%2F1.jpg?alt=media&token=3e0acb87-cc30-4f88-85f2-71cd2dd09119'|panelStyle)">
  </app-panel>


  <!-- 4 -->
  <app-panel
  titleStart="trained  tactical officers"
  titleEnd="ready to respond when it matters most."
  [alignment]="'end'"
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fnight_shoot%2Ftactical_support_web.jpg?alt=media&token=79201814-8a1c-40ca-8610-4251f242a668'|panelStyle:'end': {'background-position' : 'top 10px'})">
  </app-panel>
  <!-- body="When you join the Sniper family, you instantly get protected by the biggest local fleet
  patrolling your neighbourhood. Our dedicated team of tactical officers are a force to be
  reckoned with. Our officers are qualified, trained and familiar with your area." -->

  <!-- 5 -->
  <app-action-panel
  titleStart="Contact"
  titleEnd="Us"
  [titleBreak]="false"
  body=" Our security products are built with you-and your premises-in mind. Sniper's expert security technicians will consult with you
 and then design and install a system that allows you to realize the benefits of our tactical support service."
 [btnLink]="'/forms/quote'"
 >
 </app-action-panel>

</div>
