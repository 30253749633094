import { Component, OnInit } from '@angular/core';
import { FAQ } from 'src/app/shared/models/faq.model';

@Component({
  selector: 'app-access-app',
  templateUrl: './access-app.component.html',
  styleUrls: ['./access-app.component.css']
})
export class AccessAppComponent implements OnInit {

  faq: FAQ[] = [];

  constructor() { }

  ngOnInit(): void {
    this.faq = this.getFAQ();
  }

  getFAQ() {
    return [
      {
        question: 'Will Sniper train me how to use the system?',
        answer: `Sniper's technical experts will demonstrate how to use the app so that you can get the most \
        out of your home access control and alarm systems. \
        A technician will teach you how to set up the system and how to control the functions of your home security remotely.`

      },
      {
        question: 'Does the system still work if the power goes out?',
        answer: `Smart home devices are powered by battery backups, and in the event of a power outage, will still work.`
      },


    ];
  }

}
