import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css']
})
export class AlertModalComponent implements OnInit {

  @Input() message: {header?: string; title: string; body: string};
  @Input() buttons: {ok: string; cancel: string} =  {ok: 'Ok', cancel: ''};
  @Input() actionRequired: false;

  constructor(public activeModal: NgbActiveModal) { }



  ngOnInit(): void {
  }

  close(confirmed: boolean){
    this.activeModal.close(confirmed);
  }

}
