<div class="container-fluid app-bg">
  <!-- 0 -->
  <app-panel
  titleStart="Video"
  titleEnd="Intercom Systems."
  body="Getting full control over an entrance,
  whether in a single home, a block of flats or a business is simple and stylish with the next generation of video intercoms.
  The Hikvision IP intercom system supports app access and when the indoor station receives a call,
   the call data can be sent to the app. The user can answer a call on the app at any time, from anywhere in the world.
   When a visitor is welcome, the user can press a button on the app to remotely open the door.
  The system is also able to store a visitor log from anyone who visits your premises."
  [landing]='true'
  [style]="('https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fip-intercom.jpeg?alt=media&token=eb11dd6d-3116-46a1-8928-cf7446dc9bf2'|panelStyle)">
  </app-panel>


   <!-- 3 -->
   <div class="row page-row py-3 justify-content-center app-bg">
    <div class="col-10 text-center mt-5">
      <h1 class="text-center text-uppercase accent-text">check out how our intercom systems can help<br><span class="text-white">protect your property</span></h1>
    </div>
    <div class="w-100"></div>
    <div class="col-md-7">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fip1.jpg?alt=media&token=a0fbead6-2def-4caa-b37e-bdce12fec66b">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fip-intercom-1.mp4?alt=media&token=9fa56999-d7c0-4df6-9be8-fc8e2471f283" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
    <div class="col-md-7">
      <video class="page-video" controls poster="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fip2.jpg?alt=media&token=9badada6-8d98-4e11-8930-93651d0b3eeb">
        <source src="https://firebasestorage.googleapis.com/v0/b/sniper-security-web.appspot.com/o/assets%2Fvideos%2Fip-intercom-2.mp4?alt=media&token=24c13504-4594-4b6f-ba6b-ec73144cf5c1" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
    </div>
  </div>



</div>

